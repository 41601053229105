import { ApplicationConfig } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';

import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { NgModel } from '@angular/forms';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { isAuthenticatedInterceptor } from './interceptor/is-authenticated/is-authenticated.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    // provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([isAuthenticatedInterceptor]),
    ),
    provideRouter(routes),
    NgModel,
    RouterModule,
  ],
};
