import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DownIconComponent } from './down-icon.component';

@Component({
  selector: 'app-mini-dropdown',
  standalone: true,
  imports: [CommonModule, DownIconComponent, RouterLink],
  template: `
  <div class="dropdown">
    <button id="dropdown-button" (click)="dropdown = !dropdown"
    class="hover:text-black text-[#545B64] bg-white border border-[#545B64] focus:outline-none text-sm mx-1 mr-4 w-24 h-7 text-center justify-center items-center dark:bg-gray-800 inline-flex "
    type="button">
    Ações
    <app-down-icon></app-down-icon>
    </button>

    <div
    [ngClass]="{
      'hidden': !dropdown, 'block': dropdown,
      'lg:left-[8rem] xl:left-[10.5rem] 3xl:left-[23.9rem]': !align
    }"
 class="z-10 border border-[#CED2D4] w-30 bg-white dark:bg-gray-700 dark:divide-gray-600 absolute {{align}}" >

      <ul class="py-0.5 text-sm dark:text-gray-200 flex flex-col items-center justify-center relative "
      aria-labelledby="dropdown-button">

        @for (item of items; track $index) {
          <li class="text-[{{item.color}}] dark:text-[{{item.darkColor || ''}}]"
           (click)="item.action()">
          <span class="block px-9 font-normal cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600" [routerLink]="item.href">{{ item.name }}</span>
          </li>
        }

      </ul>
    </div>
  </div>
`,
})
export class MiniDropdownComponent {
  @Input({ required: true }) public items!: Itens[];
  @Input() public align!: string;
  public dropdownButtonAction = false;
  public dropdown = false;
}

interface Itens {
  name: string;
  action: () => void;
  href?: string[];
  color: string;
  darkColor?: string;
}
