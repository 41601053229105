import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../../../shared/components/spinner.component';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UseTokenService } from '../../../core/services/auth/use-token.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent {
  constructor(
    private router: Router,
    private auth: AuthService,
    private token: UseTokenService,
  ) {}

  protected loginInvalid = false;
  protected spinner = false;
  message: string | null = null;
  ngOnInit() {
    this.token.removeToken();
  }

  public ngLogin(event: Event, username: string, password: string) {
    event.preventDefault();
    this.spinner = true;

    this.auth.login(username, password).subscribe({
      next: () => {
        if (!this.token.isAuthenticate()) this.loginInvalid = true;
        this.spinner = false;
      },
      error: (err) => {
        console.error('Erro de login:', err);
        this.loginInvalid = true;
        this.spinner = false;
        this.message = err.error.message;
      },
    });
  }

  ngForgout(): void {
    this.router.navigate(['/auth/reset-password']);
  }
}
