import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-caption-table',
  standalone: true,
  imports: [],
  templateUrl: './caption-table.component.html',
})
export class CaptionTableComponent {
  constructor(private route: Router) {}
  private urlSegmentQuery =
    new URLSearchParams(window.location.search).get('return') || '';

  public goBack() {
    this.route.navigate(['/home', this.urlSegmentQuery]);
  }
}
