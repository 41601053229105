import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  CaptionTableComponent,
  TableContainerComponent,
  TheadTableComponent,
  TheadTrTableComponent,
  TheadThTableComponent,
  TbodyTdTableComponent,
  TbodyTrTableComponent,
  TbodyThTableComponent,
  TheadTdTableComponent,
  TbodyTableComponent,
} from '../../../../../shared/components/table/table';
import { PageTitleComponent } from '../../../../../shared/components/pages/page-title/page-title.component';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { LoadIconComponent } from '../../../../../shared/components/load-icon.component';
import { filter, Subscription, switchMap, tap, timer } from 'rxjs';
import { TriggeringService } from '../../../../../core/services/triggering/triggering.service';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  AgentKey,
  AgentType,
} from '../../../../../core/types/agents.interface';
import { HistoryShoot } from '../../../../../core/types/triggering.interface';
import { SuccessIconComponent } from '../../../../../shared/components/success-icon.component';

@Component({
  selector: 'app-status-triggering',
  standalone: true,
  templateUrl: './status-triggering.component.html',
  imports: [
    CommonModule,
    PageTitleComponent,
    CaptionTableComponent,
    TableContainerComponent,
    TheadTableComponent,
    TheadTrTableComponent,
    TheadTdTableComponent,
    TheadThTableComponent,
    TbodyTdTableComponent,
    TbodyTrTableComponent,
    TbodyTableComponent,
    LoadIconComponent,
    SuccessIconComponent,
    TbodyThTableComponent,
    NgIf,
  ],
})
export class StatusTriggeringComponent {
  constructor(
    private data: DataService,
    private triggering: TriggeringService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  public items!: HistoryShoot[] | null;
  private subscription!: Subscription;
  private params$!: Subscription;

  ngOnInit() {
    this.data.changeData(
      'Disparos',
      'Automatize seu trabalho disparando em massa qualquer mensagem para seus clientes.',
      true,
    );

    this.params$ = this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.queryParams['key']) {
          this.cleanComponent();
          this.getItems();
          return;
        }
      }
    });

    this.getItems();
  }

  ngOnDestroy() {
    this.data.changeData('', '', false);
    this.subscription.unsubscribe();
    this.params$.unsubscribe();
  }

  private getItems() {
    this.subscription = timer(0, 10000)
      .pipe(
        switchMap(() =>
          this.triggering.getStatusForAllTriggering(this.getAgentKey()),
        ),
        tap((data) => {
          this.items = data.sort((a, b) => {
            return (
              new Date(b._creation).getTime() - new Date(a._creation).getTime()
            );
          });
        }),
      )
      .subscribe();
  }

  private getAgentKey(): AgentKey {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key') as AgentKey | null;

    if (!key || (key !== 'WA' && key !== 'TEL')) {
      // this.route.navigate(['/home']);
    }

    return key as AgentKey;
  }

  private cleanComponent() {
    this.items = null;
    this.subscription.unsubscribe();
    this.cd.detectChanges();
  }
}
