<tr
  [ngClass]="{
    'bg-[#0073bb42] dark:bg-[#18345a] hover:bg-[#0073bb42] border-[#0073BB]':
      getStyle(),
    'hover:bg-[#cfdcdd] dark:bg-gray-800 dark:border-gray-700': !getStyle()
  }"
  class="flex justify-evenly gap-x-8 border-b border hover:transition-all text-[#16191F] hover:bg-[#cfdcdd] bg-white "
>
  <ng-content selector="app-tbody-td-table"></ng-content>
  <ng-content></ng-content>
</tr>
