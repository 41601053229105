<div class="shadow-xl mb-10 pb-2">
    <h2 class="text-center text-2xl font-bold mb-2 text-[#1885cd]">whatsapp conectado</h2>
    <div class="w-screen flex justify-center items-center ">
        <img [src]="integrationInfo?.adInfo?.profile || '/assets/imagens/icons/agent.jpg'" alt="Profile Picture" class="w-40 h-40 rounded-full object-cover mr-5">
        <div>
            <h2 class="text-2xl font-semibold  text-[#1885cd]">{{integrationInfo?.adInfo?.name}}</h2>
           
            <p class="text-gray-600">{{integrationInfo?.adInfo?.number}}</p>
            <p class="text-xl text-green-500">conectado</p>
        </div>
        
    </div>
   
    <div class="flex w-full justify-center my-2">
        <button (click)="disconnect()" class="w-[100px] bg-red-400 m-auto transition-all duration-300 p-2 text-white rounded-xl hover:bg-red-700">desconectar</button>

    </div>
</div>
