<section class="w-screen mt-4 flex flex-col items-center justify-center">
  <div class="ml-52 w-3/4">
    <div>
      <h1 class="text-2xl ml-14">Associar regras</h1>
    </div>
    <div
      class="flex justify-center items-center shadow-sm border border-[#d2dae3]"
    >
      <div class="flex flex-col items-center justify-center w-full">
        <div
          class="w-full border border-[#ECEEEE] bg-[#f2f3f3] shadow py-3.5 flex flex-row"
        >
          <div class="ml-8">
            <h1 class="text-2xl font-bold">Regras</h1>
            <p>
              Associe regras para os usuários listados de acordo com suas
              respectivas atividades
            </p>
          </div>
          <div class="ml-auto mr-3">
            <button
              class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] w-40 h-8 text-white font-bold rounded focus:outline-none focus:shadow-outline"
              (click)="associateGroupForUser()"
            >
              Salvar Alterações
            </button>
          </div>
        </div>
        <ol
          class="grid grid-cols-4 3xl:grid-cols-6 mt-4 gap10 space-x-10 justify-between"
        >
          @for (user of users; track user.id) {
          <li class="mb-12 border border-[#f2f3f3] shadow shadow-gray-400">
            <div class="flex flex-col items-center">
              <p
                class="mb-4 font-bold text-lg border-b-2 w-full text-center py-3"
              >
                {{ $index }} - {{ user.name }}
              </p>
              <div>
                <ul class="flex flex-col">
                  @for (group of groups; track group.id) {
                  <li class="mx-2 mb-4">
                    <div
                      class="flex flex-row items-center gap-1 text-base font-medium"
                    >
                      <input
                        type="checkbox"
                        (change)="selectGroup(group, user)"
                      />
                      <p>{{ group.name }}</p>
                    </div>
                  </li>
                  } @empty {
                  <li class="mx-2 mb-4">Nenhum grupo de regras existente</li>

                  }
                </ul>
              </div>
            </div>
          </li>
          }
        </ol>
      </div>
    </div>
  </div>
</section>
