import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { MessageService } from '../../../../../core/services/message/message.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { FormsModule } from '@angular/forms';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { TriggeringComponent } from './components/triggering/triggering.component';
import { ShootMediaBody } from '../../../../../core/types/agents.interface';
import { Router } from '@angular/router';
import { defer, EMPTY, forkJoin, shareReplay } from 'rxjs';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { TriggeringService } from '../../../../../core/services/triggering/triggering.service';
@Component({
  selector: 'app-shoot-imediate',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MiniDropdownComponent,
    TriggeringComponent,
    SchedulingComponent,
  ],
  templateUrl: './shoot-imediate.component.html',
})
export class ShootImediateComponent {
  constructor(
    private data: DataService,
    private message: MessageService,
    private triggeringService: TriggeringService,
    private event: EventsService,
    private router: Router
  ) {}

  @ViewChild('inputFile')
  private labelForActivateInputFile!: ElementRef<HTMLDivElement>;

  @ViewChild(TriggeringComponent)
  private triggeringComponent!: TriggeringComponent;

  @ViewChild(SchedulingComponent)
  private scheduling!: SchedulingComponent;

  private bufferForImageSelectedByUser!: File | null;
  protected previewImageMessage?: string;
  protected messageInTextArea = '';
  protected alertInputNameIsEmpty = false;

  protected items = [
    {
      name: 'Selecionar imagem',
      action: () => this.selectPersonalizedImageForItem(),
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Remover imagem',
      action: () => {
        this.previewImageMessage = undefined;
        this.bufferForImageSelectedByUser = null;
      },
      color: '#000',
      darkColor: '#fff',
    },
  ];

  ngOnInit(): void {
    this.data.changeData(
      'Disparos',
      'Automatize seu trabalho disparando em massa qualquer mensagem para seus clientes.',
      true
    );
  }

  private selectPersonalizedImageForItem() {
    this.labelForActivateInputFile.nativeElement.click();
  }

  @HostListener('window:paste', ['$event'])
  public async pasteClipboard(event: ClipboardEvent) {
    const items = event.clipboardData?.items;

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.kind === 'file' && item.type.indexOf('image') !== -1) {
          const image = item.getAsFile();
          if (image) {
            this.bufferForImageSelectedByUser = image;
            const arrayBuffer = await image.arrayBuffer();
            let binary = '';
            const bytes = new Uint8Array(arrayBuffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }

            this.previewImageMessage = `data:${image.type};base64,${window.btoa(
              binary
            )}`;
          }
        }
      }
    }
  }

  private checkDateTime() {
    if (this.scheduling.getDate && this.scheduling.getDate <= new Date()) {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Selecione uma data e hora válida, posterior à atual',
        event: 'error',
      });
      return false;
    }
    return true;
  }
  private createMessageByPlatform(msg: ShootMediaBody, platform: 'WA' | 'TEL') {
    if (
      !this.triggeringComponent[
        `${platform.toLowerCase()}Selected` as 'waSelected' | 'telSelected'
      ]
    )
      return EMPTY;

    const formData = new FormData();
    formData.append('key', platform);

    if (msg.chatId) {
      formData.append('chatId', msg.chatId);
    }

    formData.append('type', msg.type);
    formData.append('text', msg.text);
    formData.append('file', msg.file, msg.file.name);

    if (this.scheduling.secure[platform.toLowerCase() as 'wa' | 'tel']) {
      return this.triggeringService.sendMessage(formData);
    }

    return this.triggeringService.shootMessageMediaForMarkedChats(formData);
  }

  private sendPlatformMessage(platform: 'WA' | 'TEL') {
    const isSecure =
      this.scheduling.secure[platform.toLowerCase() as 'wa' | 'tel'];

    const text = this.messageInTextArea;
    const file = this.bufferForImageSelectedByUser;
    const type = 'image';
    const dateFromStart = this.scheduling.getDate?.toString();

    if (isSecure) {
      this.triggeringComponent.markedChats[platform].map((chatId) =>
        this.createMessageByPlatform(
          {
            text,
            file,
            type,
            _date_from_start: dateFromStart,
            chatId,
          } as ShootMediaBody,
          platform
        ).subscribe((res) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: res.message,
            event: 'success',
          });
        })
      );

      this.router.navigate(['/home', 'status-triggering'], {
        queryParams: { key: platform, return: 'message-imediate' },
      });

      return;
    }

    this.createMessageByPlatform(
      { text, file, type, _date_from_start: dateFromStart } as ShootMediaBody,
      platform
    ).subscribe((res) => {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: res.message,
        event: 'success',
      });
    });
  }

  private sendMessagesForBothPlatforms() {
    const text = this.messageInTextArea;
    const file = this.bufferForImageSelectedByUser;
    const type = 'image';
    const dateFromStart = this.scheduling.getDate?.toString();

    const waMessage$ = defer(() =>
      this.createMessageByPlatform(
        { text, file, type, _date_from_start: dateFromStart } as ShootMediaBody,
        'WA'
      )
    );

    const telMessage$ = defer(() =>
      this.createMessageByPlatform(
        { text, file, type, _date_from_start: dateFromStart } as ShootMediaBody,
        'TEL'
      )
    );

    forkJoin([telMessage$, waMessage$]).subscribe(([tel, wa]) => {
      if (
        this.triggeringComponent.waSelected &&
        this.triggeringComponent.telSelected
      ) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/home', 'status-triggering'], {
            queryParams: { key: 'WA', return: 'message-imediate' },
          })
        );
        window.open(url, '_blank');

        this.router.navigate(['/home', 'status-triggering'], {
          queryParams: { key: 'TEL', return: 'message-imediate' },
        });
      }
    });
  }

  public createMessage() {
    if (!this.checkDateTime()) {
      console.log('data inválida');
      return;
    }

    if (
      this.triggeringComponent.waSelected &&
      this.triggeringComponent.telSelected
    ) {
      this.sendMessagesForBothPlatforms();
      return;
    }

    if (this.triggeringComponent.waSelected) {
      this.sendPlatformMessage('WA');
      return;
    }

    if (this.triggeringComponent.telSelected) {
      this.sendPlatformMessage('TEL');
    }
  }

  public async changeFileForImage(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target?.files) {
      const image = target.files[0];
      if (!image.type.includes('image')) {
        this.previewImageMessage =
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQURNElvcizn19Vd-aVgHGVNQzxt5xent174Q&s';
        return;
      }

      this.bufferForImageSelectedByUser = image;

      const arrayBuffer = await image.arrayBuffer();
      let binary = '';
      const bytes = new Uint8Array(arrayBuffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      this.previewImageMessage = `data:${image.type};base64,${window.btoa(
        binary
      )}`;
    }
  }
}
