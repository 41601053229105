import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tbody-tr-table',
  standalone: true,
  imports: [NgClass],
  templateUrl: './tbody-tr-table.component.html',
})
export class TbodyTrTableComponent {
  @Input() public classControl?: string;

  protected getStyle() {
    return this.classControl && this.classControl === 'selected';
  }
}