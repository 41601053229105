import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-icon',
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15.9364 6.48475L13.518 4.06462L14.7689 2.81287C15.1858 2.39575 15.853 2.39575 16.1865 2.81287L17.1873 3.81437C17.6042 4.23162 17.6042 4.89925 17.1873 5.23312L15.9364 6.48475ZM8.93162 13.4945L6.51325 11.0744L13.101 4.5655L15.5194 6.98537L8.93162 13.4945ZM4.59538 15.4972L6.09637 11.5751L8.51475 13.9951L4.59538 15.4972ZM16.9159 16.3317C17.2496 16.3317 17.4999 16.582 17.4999 16.9158C17.4999 17.2496 17.2496 17.5 16.9159 17.5H3.08412C2.7185 17.5 2.5 17.2496 2.5 16.9158C2.5 16.582 2.75025 16.3317 3.08412 16.3317H16.9159Z" fill="#545B64"/>
    </svg>
  `,
})
export class EditIconComponent {}
