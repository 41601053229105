import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Pages {
  cardComponent: boolean;
  botComponent: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  private dataCardName = new BehaviorSubject<string>('');
  private dataCardDescription = new BehaviorSubject<string>('');
  private cardVisible = new BehaviorSubject<boolean>(false);

  public changeData(name: string, description: string, cardVisible: boolean) {
    this.dataCardName.next(name);
    this.dataCardDescription.next(description);
    this.cardVisible.next(cardVisible);
  }

  public isDarkTheme(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const colorTheme = localStorage.getItem('color-theme');
      if (colorTheme) return colorTheme === 'dark';

      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  }

  public routeData(): {
    name: BehaviorSubject<string>;
    description: BehaviorSubject<string>;
    cardVisible: BehaviorSubject<boolean>;
  } {
    return {
      name: this.dataCardName,
      description: this.dataCardDescription,
      cardVisible: this.cardVisible,
    };
  }

  public readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryData = (e.target as FileReader).result as string;
        const base64String = binaryData.replace(/^data:.+;base64,/, '');
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
}
