import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { VerifyFalseComponent } from '../../../../../shared/components/verify-false.component';
import { VerifyTrueComponent } from '../../../../../shared/components/verify-true.component';
import { dateTransform } from '../../../../../core/functions/dateTransform.function';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { MessageService } from '../../../../../core/services/message/message.service';
import { Data, PostMessage } from '../../../../../core/types/message.interface';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    EditIconComponent,
    VerifyFalseComponent,
    VerifyTrueComponent,
    MiniDropdownComponent,
    RouterLink,
  ],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.css',
})
export class MessagesComponent {
  constructor(
    private router: Router,
    private dataService: DataService,
    private event: EventsService,
    private message: MessageService,
    private cd: ChangeDetectorRef,
  ) {}

  public showModal = false;
  public messagesIdsControllers = new Set<number>();

  public messages!: Data[];

  public actions = [
    {
      name: 'Criar',
      action: () => this.router.navigate(['/home', 'new-message']),
      href: ['/home', 'messages'],
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Deletar',
      action: () => this.deleteMessages(),
      href: ['/home', 'messages'],
      color: '#D13313',
    },
    {
      name: 'Copiar',
      action: () => this.copyMessages(),
      href: ['/home', 'messages'],
      color: '#000',
      darkColor: '#fff',
    },
  ];

  @ViewChild('messageInput', { static: false }) public reference!: ElementRef;
  @ViewChild('checkboxFather')
  public checkboxFather!: ElementRef<HTMLInputElement>;
  @ViewChildren('inputCheckbox') public inputCheckbox!: QueryList<ElementRef>;

  protected messageEdit = 0;

  protected messageName!: string;

  private statesOfMessageRenamed = false;

  private messagesSubscriptions$!: Subscription;

  private messagesControllers = new Set<Data>();

  ngOnInit() {
    this.dataService.changeData(
      'Mensagens',
      'Gerencie seus modelos de mensagem e prepare-as para o disparo.',
      true,
    );
    this.getMessages();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public unsubscribe() {
    this.messagesSubscriptions$.unsubscribe();
  }

  public getMessages() {
    this.messagesSubscriptions$ = this.message.getMessage().subscribe((msg) => {
      this.messages = msg.map((m) => ({
        ...m,
        created_at: dateTransform(m.created_at),
      }));
    });
  }

  public deleteMessages() {
    if (this.messagesIdsControllers.size === 0) return;

    const messages$ = Array.from(this.messagesIdsControllers).map((id) =>
      this.message.deleteMessage(id),
    );

    forkJoin(messages$).subscribe(() => {
      this.getMessages();
      this.clearControllers();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Mensagem deletada com sucesso!',
        event: 'success',
      });
    });
  }

  public copyMessages() {
    if (this.messagesControllers.size === 0) return;

    const messages: PostMessage[] = Array.from(this.messagesControllers).map(
      (data) => ({
        name: data.name,
        link: data.message.split('\n')[0],
        message: data.message,
        imageLink: !!data.image,
        image: data.image,
      }),
    );

    const observables$ = messages.map((msg) => this.message.postMessage(msg));

    forkJoin(observables$).subscribe(() => {
      this.getMessages();
      this.clearControllers();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Mensagem copiada com sucesso!',
        event: 'success',
      });
    });
  }

  public selectMessage(message: Data) {
    if (this.messagesControllers.has(message)) {
      this.messagesControllers.delete(message);
      this.messagesIdsControllers.delete(message.id);
      if (this.messagesControllers.size !== this.messages.length) {
        this.checkboxFather.nativeElement.checked = false;
      }
      return;
    }
    this.messagesControllers.add(message);
    this.messagesIdsControllers.add(message.id);
    if (this.messagesControllers.size === this.messages.length) {
      this.checkboxFather.nativeElement.checked = true;
    }
  }

  private markAllCheckboxHowChecked(): void {
    if (this.messagesControllers.size === this.messages.length) {
      for (const input of this.inputCheckbox) {
        input.nativeElement.checked = true;
      }
      return;
    }

    for (const input of this.inputCheckbox) {
      input.nativeElement.checked = false;
    }
  }

  public selectAllMessagesOfTable() {
    if (this.messagesControllers.size === this.messages.length) {
      this.clearControllers();
      this.markAllCheckboxHowChecked();
      return;
    }
    for (const message of this.messages) {
      if (this.messagesControllers.has(message)) {
        continue;
      }
      this.selectMessage(message);
      this.markAllCheckboxHowChecked();
    }
  }

  private clearControllers() {
    this.messagesControllers.clear();
    this.messagesIdsControllers.clear();
    this.checkboxFather.nativeElement.checked = false;

    for (const input of this.inputCheckbox) {
      input.nativeElement.checked = false;
    }
  }

  public displayInputOfMessageName(message: Data) {
    this.messageEdit = message.id;
    this.messageName = message.name;
    requestAnimationFrame(() => {
      this.reference.nativeElement.focus();
    });
  }

  public sendNewNameOfMessage(name: string, message: Data) {
    if (name === message.name) {
      this.messageEdit = 0;
      return;
    }

    if (this.statesOfMessageRenamed) return;

    this.statesOfMessageRenamed = true;

    this.message.updateMessage({ ...message, name }).subscribe(() => {
      this.messageEdit = 0;
      this.statesOfMessageRenamed = false;

      this.getMessages();
      this.cd.detectChanges();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Usuário alterado com sucesso!',
        event: 'success',
      });
    });
  }

  public navigateToEditStoresOfMessages() {
    if (this.messagesControllers.size === 0) {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Selecione ao menos um grupo para editar as regras!',
        event: 'error',
      });
      return;
    }

    this.router.navigate(['/home', 'messages-stores'], {
      queryParams: {
        id: Array.from(this.messagesControllers)
          .map((m) => m.id)
          .join(','),
      },
    });
  }
}
