<article class="overflow-hidden" id="auth-container-father">
  <div class="flex flex-row justify-center">
    <div class="">
      <div class="">
        <div class=" sm:w-36 h-36 select-none" id="logo"></div>
        <div class="">
          <h2 id="auth-title" class="mb-6 text-lg font-bold text-black dark:text-gray-300">
            Fazer Login
          </h2>
        </div>
        <div class="" id="card-auth">
          <form (submit)="ngLogin($event, useremail.value, password.value)">
            <div class="flex flex-col mb-3">
              <label for="useremail" class="text-sm font-bold mb-2 dark:text-gray-300" id="label-useremail">Email de
                usuário:</label>
              <input #useremail [ngClass]="{ 'input-error': loginInvalid }" type="text"
                class="dark:bg-gray-700 w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC]" id="useremail"
                name="useremail" autocomplete="usermail" required />
            </div>
            <div class="flex flex-col mb-3">
              <label for="password" class="text-sm font-bold mb-2 dark:text-gray-300" id="label-password">Senha:</label>
              <input #password [ngClass]="{ 'input-error': loginInvalid }" type="password"
                class="dark:bg-gray-700 w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC]" id="password"
                name="current-password" autocomplete="current-password" required />
            </div>
            <div class="mt-0 md:mt-2 mb-3">
              <input type="checkbox" name="" id="remember" />
              <label class="text-sm ml-3 dark:text-gray-300" for="remember" id="label-remember">Lembrar de mim</label>
            </div>
            <p id="message-error" class="text-[#f00] w-72 h-20 text-xs font-semibold tracking-widest"
              [ngClass]="{ block: loginInvalid, hidden: !loginInvalid }">
              {{message}}
            </p>
            @if (spinner){
            <div class="justify-center flex">
              <app-spinner></app-spinner>
            </div>
            } @else {
            <button type="submit" class="bg-blue-600 transition-all duration-300 hover:bg-blue-900" id="button-event-login">
              Login
            </button>
            }

          </form>
        </div>
        <div class="md:mt-3 sm:mt-2">
          <a (click)="ngForgout()" class="text-[#408fec] cursor-pointer">Esqueci a senha</a>
        </div>
      </div>
    </div>
  </div>
  <footer class="mt-12" id="auth-login-footer">
    <hr />
    <div class="text-center text-black text-sm dark:text-gray-300">
      <p id="footer-text">
        Ofertas Automatizadas © 2023 - Todos os direitos reservados
      </p>
    </div>
  </footer>
</article>
