@switch (type) { @case ('checkbox') {
<th [scope]="scope" class="px-8 py-3">
  <div class="flex items-center justify-center">
    <input
      id="checkbox-all-search"
      (change)="checkboxClick()"
      #checkboxFather
      type="checkbox"
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
    <label for="checkbox-all-search" class="sr-only">
      <ng-content></ng-content>
    </label>
  </div>
</th>
} @case ('empty') {
<tr class="xl:w-14">
  <td
    [attr.colspan]="colspan"
    class="px-6 py-4 text-center dark:text-white hover:transition-all"
  >
    <ng-content></ng-content>
  </td>
</tr>
} @default {
<th [scope]="scope" class="px-8 py-3 w-[13rem]">
    <ng-content></ng-content>
</th>
} }
