import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const MustMatch = (
  value: string,
  valueMatch: string,
): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    const match = control.get(value);
    const valueToMatch = control.get(valueMatch);

    if (match?.value !== valueToMatch?.value) {
      valueToMatch?.setErrors({ mustMatch: true });
      return { mustMatch: true };
    }

    return null;
  };
};
