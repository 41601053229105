import { Component, Input } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { SpinnerComponent } from '../../../../../../../../shared/components/spinner.component';

@Component({
  selector: 'app-starting',
  standalone: true,
  imports: [QRCodeModule, SpinnerComponent],
  templateUrl: './starting.component.html',
  styleUrl: './starting.component.css',
})
export class StartingComponent {
  @Input({ required: true }) public qrcode!: string;

  ngOnInit(): void {}
}
