import { Component } from '@angular/core';
import { Subscription, combineLatest, forkJoin, map } from 'rxjs';
import { DataService } from '../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../core/services/card-output/events.service';
import { MessageService } from '../../../../core/services/message/message.service';
import { StoreService } from '../../../../core/services/shop/store.service';
import {
  IPutStoreInMessage,
  Store2,
} from '../../../../core/types/message.interface';
import { IStores } from '../../../../core/types/store.interface';

@Component({
  selector: 'app-store-messages',
  standalone: true,
  imports: [],
  templateUrl: './store-messages.component.html',
})
export class StoreMessagesComponent {
  constructor(
    private data: DataService,
    private message: MessageService,
    private store: StoreService,
    private event: EventsService,
  ) {}

  private messagesForPutController = new Array<IPutStoreInMessage>();

  private urlSegmentQuery = new URLSearchParams(window.location.search).get(
    'id',
  );

  private subscription!: Subscription;

  protected messages!: IPutStoreInMessage[];

  protected stores!: Store2[];

  ngOnInit(): void {
    this.getStoresByUrlSegmentId();
    this.getMessages();

    this.data.changeData(
      'Mensagens associadas',
      'Manipule aqui as lojas associadas.',
      true,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.data.changeData('', '', false);
  }

  private getStoresByUrlSegmentId(): void {
    if (!this.urlSegmentQuery) {
      return;
    }
    const stores$ = this.urlSegmentQuery
      .split(',' || '')
      .map((id) => this.store.httpStoreGetById(id));

    this.subscription = combineLatest(stores$).subscribe((stores) => {
      this.stores = stores.flatMap((str) => ({
        name: str.name,
        id: str.id,
      }));
    });
  }

  private getMessages() {
    this.message.getMessage().subscribe((req) => {
      this.messages = req.flatMap((msg) => ({
        id: msg.id,
        name: msg.name,
        message: msg.message,
        stores: msg.stores.map((st) => st.store.id),
      }));
    });
  }

  public messageIncludesInStore(store: Store2, message: IPutStoreInMessage) {
    return message.stores.includes(store.id);
  }

  public selectMessage(message: IPutStoreInMessage, store: Store2) {
    const messageIndex = this.messagesForPutController.findIndex(
      (m) => m.id === message.id,
    );

    if (messageIndex > -1) {
      // Se já existe no messagesForPutController, remove
      const messageInController = this.messagesForPutController[messageIndex];
      const storeIndex = messageInController.stores.indexOf(store.id);

      if (storeIndex > -1) {
        messageInController.stores.splice(storeIndex, 1);
        return;
      }
      messageInController.stores.push(store.id);
      return;
    }

    // Se não existe, adiciona
    this.messagesForPutController.push({
      ...message,
      stores: [store.id],
    });
  }

  public associateMessage() {
    const stores = this.messagesForPutController.map((msg) => ({
      ...msg,
      stores: msg.stores,
    }));

    const observables$ = stores.map((msg) => this.message.updateMessage(msg));

    forkJoin(observables$).subscribe(() => {
      this.getStoresByUrlSegmentId();
      this.getMessages();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Mensagem associada com sucesso!',
        event: 'success',
      });
    });
  }
}
