import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { faSolidFaceLaugh } from '@ng-icons/font-awesome/solid';
import { catchError, delay } from 'rxjs';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { SpinnerComponent } from '../../../../../shared/components/spinner.component';
import { SafeurlPipe } from '../../../../../core/pipes/safe-url/safeurl.pipe';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { MessageService } from '../../../../../core/services/message/message.service';
import {
  Emoji,
  Line,
  PostMessage,
} from '../../../../../core/types/message.interface';

@Component({
  selector: 'app-new-message',
  standalone: true,
  imports: [
    CommonModule,
    MiniDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    NgIconComponent,
    PickerComponent,
    SpinnerComponent,
    SafeurlPipe,
  ],
  viewProviders: [
    provideIcons({
      faSolidFaceLaugh,
    }),
  ],
  templateUrl: './new-message.component.html',
  styleUrl: './new-message.component.css',
})
export class NewMessageComponent implements OnInit, OnDestroy {
  constructor(
    private data: DataService,
    private message: MessageService,
    private event: EventsService,
  ) {}

  @ViewChild('inputNameForMessage')
  private inputMessageName!: ElementRef<HTMLInputElement>;

  @ViewChild('inputFile')
  private labelForActivateInputFile!: ElementRef<HTMLDivElement>;

  @ViewChild('emojiElement') private picker!: ElementRef<HTMLDivElement>;

  private dataForEmojiComponent: Emoji = {
    visible: false,
    elementId: null,
  };

  private bufferForImageSelectedByUser!: File | null;

  protected items = [
    {
      name: 'Selecionar imagem',
      action: () => this.selectPersonalizedImageForItem(),
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Remover imagem',
      action: () => {
        this.previewImageMessage = undefined;
        this.bufferForImageSelectedByUser = null;
      },
      color: '#000',
      darkColor: '#fff',
    },
  ];

  protected alertInputNameIsEmpty = false;
  protected emojiDarkMode = this.data.isDarkTheme();
  protected messageInTextArea = '';

  protected previewImageMessage?: string;
  ngOnInit(): void {
    this.data.changeData(
      'Mensagens',
      'Gerencie seus modelos de mensagem e prepare-as para o disparo.',
      true,
    );
  }

  ngOnDestroy(): void {
    this.closeWindowsOfEmoji();
    this.data.changeData('', '', false);
  }

  private selectPersonalizedImageForItem() {
    this.labelForActivateInputFile.nativeElement.click();
  }

  private closeWindowsOfEmoji() {
    this.dataForEmojiComponent = {
      visible: false,
      elementId: null,
    };
  }

  @HostListener('window:paste', ['$event'])
  public async pasteClipboard(event: ClipboardEvent) {
    const items = event.clipboardData?.items;

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.kind === 'file' && item.type.indexOf('image') !== -1) {
          const image = item.getAsFile();
          if (image) {
            const arrayBuffer = await image.arrayBuffer();
            let binary = '';
            const bytes = new Uint8Array(arrayBuffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }

            this.previewImageMessage = `data:${image.type};base64,${window.btoa(
              binary,
            )}`;
          }
        }
      }
    }
  }

  public getDataForEmoji() {
    return this.dataForEmojiComponent;
  }

  public setThisElementInEmojiData(element: number) {
    this.dataForEmojiComponent.elementId = element;
  }

  public createMessage() {
    if (!this.inputMessageName.nativeElement.value) {
      this.alertInputNameIsEmpty = true;
      return;
    }

    const messageObj: PostMessage = {
      name: this.inputMessageName.nativeElement.value,
      message: this.messageInTextArea,
    };

    this.message.postMessage(messageObj).subscribe((msg) => {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Mensagem criada com sucesso!',
        event: 'success',
      });

      if (msg.id && this.bufferForImageSelectedByUser) {
        this.message
          .addImageForMessage(msg.id, this.bufferForImageSelectedByUser)

          .subscribe(() => {
            setTimeout(() => {
              this.event.emitEventForStoreSuccess({
                visible: true,
                message: 'Imagem atribuída a imagem com sucesso',
                event: 'success',
              });
            }, 1500);
          });
      }
    });
  }

  public addEmojiInLine(event: EmojiEvent) {
    this.messageInTextArea += event.emoji.native;
  }

  public toggleWindowOfEmoji() {
    this.dataForEmojiComponent.visible = !this.dataForEmojiComponent.visible;
  }

  public async changeFileForImage(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target?.files) {
      const image = target.files[0];
      if (!image.type.includes('image')) {
        this.previewImageMessage =
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQURNElvcizn19Vd-aVgHGVNQzxt5xent174Q&s';
        return;
      }

      this.bufferForImageSelectedByUser = image;

      const arrayBuffer = await image.arrayBuffer();
      let binary = '';
      const bytes = new Uint8Array(arrayBuffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      this.previewImageMessage = `data:${image.type};base64,${window.btoa(
        binary,
      )}`;
    }
  }
}
