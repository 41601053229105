import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactResponse } from '../../types/contacts.interface';
import { EventsService } from '../card-output/events.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
  ) {}

  public getContacts() {
    return new Observable<ContactResponse[]>();
  }

  public createContact() {
    return new Observable<ContactResponse>();
  }

  public updateContact(contact: ContactResponse) {
    return new Observable<ContactResponse>();
  }

  public deleteContact(id: number) {
    return new Observable<ContactResponse>();
  }

  public getContactById() {}

  public getContactByAgent(contact: string) {
    return new Observable<{
      name: string;
      photo: string;
      number: string;
    }>((obs) => {
      const contactT = {
        name: 'João da Silva',
        photo: 'https://picsum.photos/500',
        number: contact,
      };
      obs.next(contactT);
    });
  }
}
