<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div
    class="shadow-md sm:rounded-lg dark:bg-gray-800 relative xl:max-w-full xl:w-max"
  >
    <div class="">
      <p class="md:mr-4 font-normal text-3xl ml-8">Regras existentes</p>
    </div>

    <div class="w-min">
      <caption
        class="pl-5 pt-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row"
      >
        <div class="w-2/4">
          <h1 class="text-2xl sm:text-3xl">Regras</h1>
          <p
            class="mt-1 mb-4 text-sm font-normal text-[#16191F] dark:text-gray-400"
          >
            Suas regras, seus negócios!
          </p>
        </div>
        <div
          class="lg:flex justify-end mr-2 hidden relative mt-4 right-0 w-full"
        >
          <div class="flex flex-col">
            <input
              type="text"
              name="rule-name"
              id="rulename"
              placeholder="Digite o nome da regra"
              class="w- h-8 border border-[#ccc] bg-white dark:bg-gray-800 mr-11"
              [(ngModel)]="ruleName"
              #ctrl="ngModel"
              required
            />

            <p *ngIf="nameIsEmpty" class="text-red-800 mt-1 text-xs ml-2">
              O nome não pode ficar em branco!
            </p>
          </div>

          <button
            class="font-bold dark:font-light h-7 w-44 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
            (click)="ctrl.valid ? createNewRuleGroup() : setNameIsEmpty()"
          >
            Criar Regra
          </button>
        </div>
      </caption>
      <table
        class="w-max text-sm text-left overflow-auto rtl:text-right text-[#16191F] dark:text-gray-400 xl:min-w-[50rem] 2xl-w-[65rem] 3xl:w-[70rem]"
      >
        <thead
          class="text-xs text-[#16191F] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox-all-search"
                  disabled
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" colspan="2" class="px-8 py-3">
              <div class="flex justify-center items-center">Nome</div>
            </th>
            <th scope="col" colspan="2" class="px-8 py-3">
              <div class="flex justify-center items-center">Categoria</div>
            </th>
          </tr>
        </thead>
        <tbody>
          @for(rule of rules; track rule.id) {
          <tr
            [ngClass]="{
              'bg-[#0073bb42] border hover:bg-[#0073bb42] border-[#0073BB]':
                rulesIdsControllers.has(rule.id),
              'hover:bg-[#cfdcdd]': !rulesIdsControllers.has(rule.id)
            }"
            class="bg-white border text-[#16191F] dark:bg-gray-800 dark:border-gray-700 hover:transition-all hover:bg-[#cfdcdd]"
          >
            <td class="w-4 px-6">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox"
                  #checkbox
                  (change)="selectRule(rule.id)"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox" class="sr-only">checkbox</label>
              </div>
            </td>
            <td
              colspan="2"
              scope="row"
              class="relative space-x-12 px-8 py-0.5 dark:text-white"
            >
              <div class="flex justify-center items-center">
                <p>{{ rule.name || "Não informado" }}</p>
              </div>
            </td>
            <td
              colspan="2"
              class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all"
            >
              <div class="flex justify-center items-center">
                <p>
                  {{ rule.group || "Não informado" }}
                </p>
              </div>
            </td>
          </tr>
          } @empty {
          <tr class="xl:w-14">
            <td
              colspan="5"
              class="px-6 py-4 text-center dark:text-white hover:transition-all"
            >
              Nenhuma regra existente
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</section>
