<section class="pb-8">
  <div class="flex flex-row items-center mt-12">
    <div class="flex items-center rounded-full mr-24">
      <label
        for="image-profile"
        class="flex flex-col items-center justify-center w-48 h-48 rounded-full border-2 border-[#00D2FF] cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >



      <img class="rounded-full object-cover h-full w-full" [src]="previews.my" alt="" *ngIf="previews.my">
        <div class="justify-center" *ngIf="!previews.my">

          <p class="text-sm text-gray-500 dark:text-gray-400">
            Alterar imagem de perfil
          </p>
        </div>
        <input (change)="changeProfile($event,'my')" id="image-profile" type="file" class="hidden" />
      </label>
    </div>

    <div class="flex flex-row items-center mt-12">
      <div *ngIf="userModel">
        <input
          *ngIf="enableInputChangeName"
          (blur)="changeUsername(name.value)"
          (keydown.enter)="changeUsername(name.value)"
          class="min-w-[20rem] max-w-[25rem] text-center  dark:bg-gray-700 text-5xl"
          name="username"
          #name
          value="{{ user.name }}"
        />
        <p *ngIf="!enableInputChangeName" class="text-5xl text-nowrap">
          {{ user.name }}
        </p>
      </div>
      <div class="ml-2 cursor-pointer" (click)="toggleUsername()">
        <app-edit-icon></app-edit-icon>
      </div>
    </div>
  </div>

  <div class="flex flex-row items-center mt-12">
    <div class="flex items-center rounded-full mr-24">
      <label
        for="image-store"
        class="flex flex-col items-center justify-center w-48 h-48 rounded-full border-2 border-[#00D2FF] cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >

      <img class="rounded-full h-full w-auto" [src]="previews.store" alt="" *ngIf="previews.store">

        <div class="justify-center" *ngIf="!previews.store">
          <p
            class="text-sm text-gray-500 dark:text-gray-400 text-justify text-wrap"
          >
            Alterar imagem do sistema
          </p>
        </div>
        <input (change)="changeProfile($event,'store')" id="image-store" type="file" class="hidden" />
      </label>
    </div>

    <div class="flex flex-row items-center">
      <div class="">
        @if (enableInputChangeSystemName) {
        <input
          class="text-5xl text-center text-nowrap min-w-0.5 max-w-[40rem] dark:bg-gray-700"
          name="username"
          #nameSystem
          [size]="nameSystem.value.length"
          [style.width.rem]="
            nameSystem.value.length + (nameSystem.value.length + 7) / 3
          "
          value="Easy Ofertas"
        />
        } @else {
        <p class="text-5xl text-nowrap">Easy Ofertas</p>
        }
      </div>
      <div
        class="ml-2 cursor-pointer"
        (click)="enableInputChangeSystemName = !enableInputChangeSystemName"
      >
        <app-edit-icon></app-edit-icon>
      </div>
    </div>
  </div>
  <!-- <div class="mt-20">
    <div class="flex flex-row ml-12">
      <div class="mr-28 w-24 text-base">
        <app-select
          (valueChange)="
            this.userThemePreference = $event; toggleThemePreference()
          "
          [options]="userThemePreferenceOptions"
          placeholder="Tema"
        ></app-select>
      </div>

      <div class="ml-28 borde-[#545B64]">
        <app-select
          [options]="userLanguagePreferenceOptions"
          placeholder="Idioma"
        ></app-select>
      </div>
    </div>
  </div> -->
</section>
