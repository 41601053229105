<header>
  <app-navbar></app-navbar>
</header>
<main class="mt-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
  <section>
    <div>
      <div>
        <aside
          [ngClass]="{
            'bg-[#1D8102]': event === 'success',
            'bg-[#f04b4b]': event === 'error'
          }"
          class="h-8 border border-gray-200 lg:ml-56 dark:border-gray-700 dark:bg-gray-800"
          *ngIf="toast"
        >
          <div class="ml-12 h-full flex flex-row items-center">
            <app-verify-true *ngIf="event === 'success'"></app-verify-true>
            <app-verify-false *ngIf="event === 'error'"></app-verify-false>
            <span class="text-white font-bold ml-1">{{ message }}</span>
          </div>
        </aside>

        <app-home-card-body *ngIf="cardDescriptionVisible">
        </app-home-card-body>
        <!-- Rotas -->
        <div class="flex justify-center items-center overflow-x-hidden">
          <router-outlet></router-outlet>
        </div>
        <!-- Fim das Rotas -->
      </div>
    </div>
  </section>
</main>
