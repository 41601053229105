<article>
  <div class="flex flex-col items-center ">
    <div class="">
      <div class="w-32 h-32 select-none" id="logo"></div>
      <div class="">
        <h2 class="dark:text-gray-300 mt-6 mb-11 text-xl font-bold" id="auth-title">Recuperar Senha</h2>
      </div>
      <div>
        <form (ngSubmit)="forgotPassword($event)" *ngIf="email">
          <div class="flex flex-col justify-center mb-6">
            <label
              for="username"
              class="dark:text-gray-300 text-sm font-bold mb-1"
              id="label-username"
              >Nome de usuário:</label
            >
            <input
              type="text"
              [formControl]="email"
              class="border border-[#38D] dark:bg-gray-500"
              [ngClass]="{
                'input-auth': !inputValid,
                'input-error': email.invalid && email.touched
              }"
              id="username"
              name="username"
              required
            />
          </div>
          <p *ngIf="authController" class="text-[#38D] text-sm font-bold w-72 h-16 mb-3">
            Um e-mail de recuperação foi enviado para o endereço de e-mail
            cadastrado. Verifique sua caixa de entrada.
          </p>
          <p
          *ngIf="email.invalid && email.touched || inputValid"
            id="message-error"
            class=" text-sm font-bold w-72 h-32 text-[#f00]"
          >
            Digite uma credencial de login válida. <br />
            <br />
            Digite o nome de usuário ou e-mail. Você pode utilizar a mesma
            credencial que usa para fazer login normalmente. Se não tem
            cadastro, contate o administrador.
          </p>
          <button
            *ngIf="!authController"
            type="submit"
            id="button-event-forgot"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  </div>
  <footer class="mt-32">
    <hr />
    <div class="text-center dark:text-gray-300">
      Ofertas Automatizadas © 2023 - Todos os direitos reservados
    </div>
  </footer>
</article>
