<section class="w-screen flex flex-col justify-center items-center mt-8">
  <div class="w-screen">
    <div class="ml-96">
      <h1 class="text-4xl">Disparar uma mensagem</h1>
    </div>
  </div>

  <div class="w-10/12 ml-52">
    <div class="flex justify-center mt-8">
      <form class="w-4/5">
        <div class="flex flex-col">
          <label for="input-triggering-name" class="text-sm font-bold"
            >Título do disparo:</label
          >
          <input
            type="text"
            formControlName="name"
            class="h-8 w-full rounded-md"
            id="input-triggering-name"
          />
        </div>

        <div class="mt-6 border border-[#ccc] rounded-sm">
          <p class="text-sm font-bold mt-1 ml-1">Seleções:</p>

          <div class="mb-6">
            <div class="flex justify-center gap-1 2xl:gap-20 mt-8">
              <div class="">
                <app-select
                  [search]="true"
                  style="height: 0"
                  placeholder="Grupos"
                  [enabled]="selectGroupIsEnabled"
                  (queryEmitter)="searchGroups($event)"
                >
                  <ul class="w-full h-[100px] overflow-y-scroll">
                    @for (group of filterGroups; track group.id) {
                    <label
                      [for]="group.id"
                      class="block py-2 border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
                    >
                      <div class="flex flex-row ml-3">
                        <input
                          type="checkbox"
                          [name]="group.title"
                          [id]="group.id"
                          [checked]="groupsForShootController.has(group.id)"
                          (change)="markChatForShoot(group.id)"
                        />
                        <label [for]="group.id" class="ml-1">{{
                          group.title
                        }}</label>
                      </div>
                    </label>
                    }
                  </ul>

                  <div
                    (click)="getDataForTriggering(channel ? channel : 'WA')"
                    class="absolute flex items-center z-50 ml-[10rem] text-[#9CA3AF] hover:text-black"
                  >
                    <ng-icon size="22" name="faSolidArrowsRotate"></ng-icon>
                  </div>
                </app-select>
              </div>

              <div class="h-full">
                <app-select
                  placeholder="Lojas"
                  [enabled]="groupsForShootController.size > 0"
                  #storeSelect
                >
                  <ul class="w-full">
                    @for (store of stores; track store.id) {
                    <li
                      class="py-2 border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
                    >
                      <div class="flex flex-row items-center justify-center">
                        <p
                          [id]="store.id"
                          (click)="markStoreForShoot(store.id)"
                        >
                          {{ store.name }}
                        </p>
                      </div>
                    </li>
                    }
                  </ul>
                  <div
                    (click)="getStores()"
                    class="absolute flex items-center ml-[10rem] text-[#9CA3AF] hover:text-black"
                  >
                    <ng-icon size="22" name="faSolidArrowsRotate"></ng-icon>
                  </div>
                </app-select>
              </div>

              <div class="h-full">
                <app-select
                  placeholder="Mensagens"
                  [enabled]="storesForShootController.size > 0"
                >
                  <ul class="w-full">
                    @for (message of messagesThatHaveStoresIncluded; track
                    message.id) {
                    <li
                      class="py-2 py-2border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
                    >
                      <div class="flex flex-row items-center justify-center">
                        <input
                          type="checkbox"
                          [name]="message.name"
                          [id]="message.id"
                          [checked]="messagesForShootController.has(message.id)"
                          (change)="markMessageForShoot(message.id)"
                        />
                        <label [for]="message.id" class="ml-1">{{
                          message.name
                        }}</label>
                      </div>
                    </li>
                    } @empty {
                    <li class="mb-4">
                      nenhuma mensagem cadastrada para essa loja
                    </li>
                    }
                  </ul>

                  <div
                    (click)="getMessages()"
                    class="absolute flex items-center ml-[10rem] text-[#9CA3AF] hover:text-black"
                  >
                    <ng-icon size="22" name="faSolidArrowsRotate"></ng-icon>
                  </div>
                </app-select>
              </div>
            </div>

            <div class="flex mt-4 gap-6 ml-8">
              <label
                class="flex gap-3 items-center transition-all duration-300 justify-center font-bold hover:cursor-pointer hover:bg-gray-300 p-2 rounded-lg"
              >
                <input
                  type="checkbox"
                  id="whatsapp"
                  [checked]="getChannelOfTriggering().whatsapp"
                  (change)="setChannelOfTriggering('WA')"
                  formControlName="whatsapp"
                  class="disabled:text-gray-400"
                />
                Disparar para WhatsApp
              </label>
              <label
                class="flex gap-3 items-center transition-all duration-300 justify-center font-bold hover:cursor-pointer hover:bg-gray-300 p-2 rounded-lg"
              >
                <input
                  type="checkbox"
                  id="telegram"
                  (change)="setChannelOfTriggering('TEL')"
                  [checked]="getChannelOfTriggering().telegram"
                  formControlName="telegram"
                  class="disabled:text-gray-400"
                />
                Disparar para Telegram
              </label>
            </div>
          </div>
        </div>

        <div class="my-2">
          <app-scheduling></app-scheduling>
        </div>
        <div class="flex justify-center">
          <button
            type="submit"
            class="transition-all duration-300 rounded-xl bg-[#ff8a12] hover:bg-[#ec7311ae] dark:font-light font-bold text-black w-44 h-8 text-center"
            (click)="sendTrigger()"
          >
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
