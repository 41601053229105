<caption
  class="pl-5 pt-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row w-full"
>
  <div class="w-full">
    <h1 class="text-2xl sm:text-3xl">
      <ng-content select="h1"></ng-content>
    </h1>
    <p class="mt-1 text-sm font-normal text-[#16191F] dark:text-gray-400">
      <ng-content select="h2"></ng-content>
    </p>
  </div>
  <div class="lg:flex justify-end mr-2 hidden relative mt-4 right-0 w-full">
    <div>
      <ng-content select="app-mini-dropdown"></ng-content>
    </div>

    <button
      (click)="goBack()"
      class="font-bold dark:font-light h-8 w-48 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
    >
      <ng-content select="h3"></ng-content>
    </button>
  </div>
</caption>
