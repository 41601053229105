import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { VerifyFalseComponent } from '../../shared/components/verify-false.component';
import { VerifyTrueComponent } from '../../shared/components/verify-true.component';
import { DataService } from '../../core/services/card-output/data-service.service';
import { EventsService } from '../../core/services/card-output/events.service';
import { HomeCardBodyComponent } from './components/home-card-body/home-card-body.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AgentsComponent } from './sub-pages/agents/agents.component';
import { CardComponent } from './sub-pages/stores/lojas/card.component';
import { RegisteredStoresComponent } from './sub-pages/stores/lojas-cadastradas/registered-stores.component';
import { ProfileComponent } from './sub-pages/profile/profile.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    HomeCardBodyComponent,
    AgentsComponent,
    CardComponent,
    RegisteredStoresComponent,
    RouterOutlet,
    VerifyTrueComponent,
    VerifyFalseComponent,
    ProfileComponent,
    RouterModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  constructor(
    private dataService: DataService,
    private cd: ChangeDetectorRef,
    private events: EventsService,
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  public cardDescriptionVisible = false;
  public message = '';
  public toast = false;
  public event: 'success' | 'error' = 'success';

  ngOnInit() {
    this.dataService.routeData().cardVisible.subscribe((isVisible) => {
      this.cardDescriptionVisible = isVisible;
      this.cd.detectChanges();
    });

    this.events.getStatisticsOfToast().subscribe((event) => {
      this.message = event.message;
      this.toast = event.visible;
      this.event = event.event;
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) initFlowbite();
  }
}
