import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  map,
  shareReplay,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Actions } from '../../class/agents/actions/actions';
import { Status } from '../../enums/agent-whatsapp-status.enum';
import {
  Action,
  AddAgentData,
  AddAgentResponse,
  AgentKey,
  AgentStatus,
  AgentType,
  Chats,
  GetChatsRequest,
  SendResponse,
  SendResponseData,
  SendShoot,
} from '../../types/agents.interface';
import { EventsService } from '../card-output/events.service';
import { UserService } from '../user/user.service';
import { SocketService } from './socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  constructor(
    private event: EventsService,
    private http: HttpClient,
  ) {
    this._actions = new Actions(this.http, this.event);
  }

  private url = environment.socket.endpoints.chats;
  private _actions!: Actions;
  public qrcode = new BehaviorSubject<string>('');
  public status = new BehaviorSubject<Status>('disconnected');

  public get actions(): Actions {
    return this._actions;
  }

  public getChats(key: AgentKey): Observable<Chats[]> {
    return this.http.post<GetChatsRequest>(this.url.GET_CHAT, { key }).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
      map((req) => req.data),
    );
  }

  public markOrUnmarkChatsForTriggering(
    key: AgentKey,
    chat: number | string,
    action: Action,
  ): Observable<AddAgentData> {
    return this.http
      .put<AddAgentResponse>(this.url.ADD_OR_REMOVE_CHAT, {
        key,
        chatId: chat,
        action,
      })
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public shootTextForChats(body: SendShoot): Observable<SendResponse> {
    return this.http
      .post<SendResponseData>(this.url.SHOOT_MESSAGE, {
        ...body,
        type: 'text',
        _date_from_start: body._date_from_start,
      })
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public sendText(body: SendShoot): Observable<SendResponse> {
    return this.http
      .post<SendResponseData>(this.url.SEND_MESSAGE, {
        ...body,
      })
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public connectIntegration(key: AgentKey) {
    return this.http.post<SendResponseData>(
      environment.socket.endpoints.connection.CONNECT_AGENT,
      { key },
    );
  }
  public disconnectIntegration(key: AgentKey) {
    return this.http.delete<SendResponseData>(
      environment.socket.endpoints.connection.DISCONNECT_AGENT,
      { body: { key } },
    );
  }
  public getAgent() {
    return this.actions.getDataForAgent().pipe(
      map((agent) => agent.data),
      shareReplay(1),
    );
  }
}
