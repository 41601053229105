import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from '@angular/router';

import { initDropdowns } from 'flowbite';
import { Subscription } from 'rxjs';
import { LogoComponent } from '../../../../../assets/imagens/logo/logo.component';
import { ToggleIconComponent } from '../../../../shared/components/toggle-icon.component';
import { UseTokenService } from '../../../../core/services/auth/use-token.service';
import { UserService } from '../../../../core/services/user/user.service';
import { PhotoComponent } from './components/photo/photo.component';
import { SocketService } from '../../../../core/services/agents/socket/socket.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    LogoComponent,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    ToggleIconComponent,
    PhotoComponent,
    SidebarComponent,
  ],

  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private token: UseTokenService,
    private user: UserService,
    private socket: SocketService,
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  protected showMenuUser = false;
  protected showSideBar = true;
  protected logoPath = '/assets/logo.jpg';
  protected data = this.user.me;

  private getMetadataOfUser$!: Subscription;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) initDropdowns();
  }

  ngOnDestroy() {
    this.getMetadataOfUser$.unsubscribe();
  }

  navbarExpand(): void {
    this.showMenuUser = !this.showMenuUser;
  }

  sidebarExpand(): void {
    this.showSideBar = !this.showSideBar;
  }

  logout(): void {
    this.token.removeToken();
    this.socket.removeBotIdCookie();
    this.router.navigate(['/auth']);
    window.location.reload();
  }
}
