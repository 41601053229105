import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventsService } from '../card-output/events.service';
import { environment } from '../../../../environments/environment';
import { catchError, EMPTY, from, map, Observable, switchMap } from 'rxjs';
import {
  AgentKey,
  AgentShootResponse,
  AgentType,
  SendMedia,
  ShootMediaBody,
  ShootMediaResponse,
} from '../../types/agents.interface';
import {
  HistoryShoot,
  HistoryShootResponse,
} from '../../types/triggering.interface';
import { DataService } from '../card-output/data-service.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TriggeringService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
    private user: UserService,
    private data: DataService
  ) {}

  public getTriggering() {
    return this.http.get('').pipe();
  }

  public newTriggering() {}

  public deleteTriggering() {}

  public sendMessage(formData: FormData) {
    // if (!message.file) {
    //   console.log('Cade a imagem parceiro?');

    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Imagem é obrigatoria!',
    //     event: 'error',
    //   });
    //   return EMPTY;
    // }

    // const formData = new FormData();

    // formData.append('bot', this.user.me.agentId);
    // formData.append('key', message.key);
    // formData.append('chatId', message.chatId);
    // formData.append('type', message.type);
    // formData.append('text', message.text);
    // formData.append('file', message.file);

    formData.append('bot', this.user.me.agentId);

    return this.http
      .post<AgentShootResponse>(
        `https://${environment.socket.agents.WSS_URL}/bot/send/media`,
        formData,
        {
          params: { token: environment.AGENTS_TOKEN },
        }
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        })
      );
  }

  public createSchedule() {}

  public createDraft() {}

  public getStatusForAllTriggering(type: AgentKey): Observable<HistoryShoot[]> {
    return this.http
      .get<HistoryShootResponse>(environment.shoot.TRIGGERING_STATUS(type))
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((res) => res.data)
      );
  }

  public shootMessageMediaForMarkedChats(
    formData: FormData
  ): Observable<ShootMediaResponse> {
    // if (!message.key) {
    //   console.log('Cade a chave parceiro?');

    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Chave é obrigatoria!',
    //     event: 'error',
    //   });
    //   return EMPTY;
    // }

    // if (!message) {
    //   console.log('Cade a mensagem parceiro?');

    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Mensagem é obrigatoria!',
    //     event: 'error',
    //   });
    //   return EMPTY;
    // }

    // if (!message.file) {
    //   console.log('Cade a imagem parceiro?');

    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Imagem é obrigatoria!',
    //     event: 'error',
    //   });
    //   return EMPTY;
    // }

    // const formData = new FormData();
    // formData.append('bot', this.user.me.agentId);
    // formData.append('key', message.key);
    // formData.append('name', 'Mensagem');
    // formData.append('type', message.type);
    // formData.append('text', message.text);
    // formData.append('file', message.file, message.file.name);

    // if (message._date_from_start) {
    //   formData.append('date_from_start', message._date_from_start);
    // }

    formData.append('bot', this.user.me.agentId);

    return this.http
      .post<ShootMediaResponse>(
        `https://${environment.socket.agents.WSS_URL}/bot/integration/shoot/media`,
        formData,
        {
          params: { token: environment.AGENTS_TOKEN },
        }
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        })
      );

    // return from(this.data.readFileAsDataURL(message.file)).pipe(
    //   switchMap((fileDataURL: string) => {
    //     const requestBody = {
    //       ...message,
    //       file: fileDataURL,
    //       image_base64: fileDataURL,
    //     };
    //     return this.http.post<ShootMediaResponse>(
    //       environment.socket.endpoints.chats.SEND_MESSAGE_MEDIA_FOR_CHATS,
    //       requestBody,
    //       { reportProgress: true }
    //     );
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     this.event.emitEventForStoreSuccess({
    //       visible: true,
    //       message: error.error.message,
    //       event: 'error',
    //     });
    //     return EMPTY;
    //   })
    // );
  }
}
