import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-thead-th-table',
  standalone: true,
  imports: [],
  templateUrl: './thead-th-table.component.html',
})
export class TheadThTableComponent {
  @Input() type?: string;
  @Input() scope?: string;
  @Input() colspan?: number;
  @Output() checkbox = new EventEmitter();

  checkboxClick() {
    this.checkbox.emit();
  }
}
