import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { TriggeringService } from '../../../../../core/services/triggering/triggering.service';
import { Triggering } from '../../../../../core/types/triggering.interface';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';

@Component({
  selector: 'app-message-triggering',
  standalone: true,
  imports: [
    CommonModule,
    MiniDropdownComponent,
    EditIconComponent,
    FormsModule,
    RouterLink
  ],
  templateUrl: './message-triggering.component.html',
})
export class MessageTriggeringComponent implements OnInit {
  constructor(
    private router: Router,
    private data: DataService,
    private event: EventsService,
    private triggeringService: TriggeringService,
    private cd: ChangeDetectorRef,
  ) {}

  public showModal = false;
  public triggeringIdsControllers = new Set<number>();

  public triggerings!: Triggering[];
  // = [
  //   {
  //     id: 1,
  //     name: 'Modelo de emergência',
  //     status: 1,
  //     text: '',
  //     created_by: {
  //       id: 0,
  //       name: 'Nicollas Prudencio',
  //     },
  //     createdAt: '',
  //     updatedAt: '',
  //     triggeringAt: 'hoje, 10:31',
  //   },
  //   {
  //     id: 2,
  //     name: 'Disparos para Whatsapp',
  //     status: 0,
  //     text: '',
  //     created_by: {
  //       id: 0,
  //       name: 'Igor Ferreira',
  //     },
  //     createdAt: '',
  //     updatedAt: '',
  //     triggeringAt: '23/11/2023, 15:22',
  //   },
  // ];

  public actions = [
    {
      name: 'Criar disparo',
      action: () => this.router.navigate(['/home', 'new-triggering']),
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Deletar',
      action: () => this.deleteTriggering(),
      color: '#D13313',
    },
    {
      name: 'Duplicar',
      action: () => this.duplicateTriggering(),
      color: '#000',
      darkColor: '#fff',
    },
  ];

  @ViewChild('triggeringInput', { static: false })
  public reference!: ElementRef;
  @ViewChild('checkboxFather')
  public checkboxFather!: ElementRef<HTMLInputElement>;
  @ViewChildren('inputCheckbox') public inputCheckbox!: QueryList<ElementRef>;

  protected triggeringEdit = 0;

  protected triggeringName!: string;

  private statesOfTriggeringRenamed = false;

  private triggeringSubscriptions$!: Subscription;

  private triggeringControllers = new Set<Triggering>();

  ngOnInit(): void {
    this.data.changeData(
      'Disparos',
      'Automatize seu trabalho disparando em massa qualquer mensagem para seus clientes.',
      true,
    );
  }

  public selectAllTriggeringsOfTable() {}

  public deleteTriggering() {}

  public duplicateTriggering() {}

  public selectTriggering(triggering: Triggering) {
    console.log(triggering);
  }

  public sendNewNameOfTriggering(name: string, triggering: Triggering) {}

  public displayInputOfTriggeringName(triggering: Triggering) {}
  public shoot() {}
}
