import { group } from 'console';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { VerifyFalseComponent } from '../../../../../shared/components/verify-false.component';
import { VerifyTrueComponent } from '../../../../../shared/components/verify-true.component';
import { dateTransform } from '../../../../../core/functions/dateTransform.function';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { RulesService } from '../../../../../core/services/rules/rules.service';
import { Data } from '../../../../../core/types/groups.interface';

@Component({
  selector: 'app-rules',
  standalone: true,
  imports: [
    CommonModule,
    EditIconComponent,
    VerifyFalseComponent,
    VerifyTrueComponent,
    MiniDropdownComponent,
    RouterLink,
  ],
  templateUrl: './rules.component.html',
  styleUrl: './rules.component.css',
})
export class RulesComponent {
  constructor(
    private router: Router,
    private dataService: DataService,
    private rulesService: RulesService,
    private event: EventsService,
  ) {}

  private rulesSubscription!: Subscription;

  public showModal = false;
  public rulesIdsControllers = new Set<number>();

  public rules!: Data[];

  public actions = [
    {
      name: 'Criar',
      action: () => this.router.navigate(['/home', 'new-rule']),
      href: ['/home', 'rules'],
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Deletar',
      action: () => this.deleteRule(),
      href: ['/home', 'rules'],
      color: '#D13313',
    },
    {
      name: 'Copiar',
      action: () => this.copyRule(),
      href: ['/home', 'rules'],
      color: '#000',
      darkColor: '#fff',
    },
  ];

  ngOnInit() {
    this.dataService.changeData(
      'Regras',
      'Cadastre regras e comande seus usuários de facilmente.',
      true,
    );
    this.getRules();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public unsubscribe() {
    this.rulesSubscription.unsubscribe();
  }

  public getRules() {
    this.rulesSubscription = this.rulesService.getGroups().subscribe((rule) => {
      this.rules = rule.map<Data>((rules) => ({
        ...rules,
        created_at: dateTransform(rules.created_at),
      }));
    });
  }

  public deleteRule() {
    if (this.rulesIdsControllers.size === 0) return;

    const observables = Array.from(this.rulesIdsControllers).map((group) =>
      this.rulesService.deleteGroup(group),
    );

    forkJoin(observables).subscribe(() => {
      this.getRules();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Regras deletadas com sucesso!',
        event: 'success',
      });
    });
  }

  public copyRule() {
    if (this.rulesIdsControllers.size === 0) return;

    const rulesGroups = this.rules.filter((rule) =>
      this.rulesIdsControllers.has(rule.id),
    );

    const observables = rulesGroups.map((group) =>
      this.rulesService.createGroup({
        name: group.name,
        permissions: group.permissions.map((p) => p.id),
      }),
    );

    forkJoin(observables).subscribe(() => {
      this.getRules();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Regras copiada com sucesso!',
        event: 'success',
      });
    });
  }

  public selectRule(id: number) {
    if (this.rulesIdsControllers.has(id)) {
      this.rulesIdsControllers.delete(id);
      return;
    }

    this.rulesIdsControllers.add(id);
  }

  public navigateToEditRulesOfUsers() {
    if (this.rulesIdsControllers.size === 0) {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Selecione ao menos um grupo para editar as regras!',
        event: 'error',
      });
      return;
    }

    this.router.navigate(['/home', 'groups-rules'], {
      queryParams: { id: Array.from(this.rulesIdsControllers).join(',') },
    });
  }
}
