import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { StoreResponse, TotalStores } from '../../../types/store.interface';
import { UseTokenService } from '../../auth/use-token.service';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(
    private http: HttpClient,
    private token: UseTokenService,
  ) {}

  private page = new BehaviorSubject<number>(1);
  private per_page = new BehaviorSubject<number>(10);
  private total = new BehaviorSubject<number>(0);

  private urlForGet(page: number) {
    return `${environment.store.LOJA_END_POINT}?page=${page}&per_page=${this.per_page.value}`;
  }

  public getPages(page: number): Observable<StoreResponse> {
    // Adiciona o parâmetro page
    return this.http.get<StoreResponse>(this.urlForGet(page)).pipe(
      catchError(() => {
        return EMPTY;
      }),
    );
  }

  public getTotalPages(): Observable<TotalStores> {
    return this.http
      .get<TotalStores>(environment.store.TOTAL_STORES)
      .pipe(catchError(() => of({ success: false, data: 0, message: '' })));
  }

  public setPage(page: number) {
    this.page.next(page);
  }

  public setPerPage(per_page: number) {
    this.per_page.next(per_page);
  }

  public get pageValue() {
    return this.page.value;
  }

  public get perPageValue() {
    return this.per_page.value;
  }

  public get urlForGetPages() {
    return this.urlForGet(this.page.value);
  }

  public get urlForGetPagesWithParams() {
    return `${environment.store.LOJA_END_POINT}?page=${this.page.value}&per_page=${this.per_page.value}`;
  }

  public get totalValue() {
    return this.total.value;
  }

  public setTotal(total: number) {
    this.total.next(total);
  }
}
