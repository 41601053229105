<div class="w-screen flex flex-col justify-center items-center text-lg">
  <div class="mt-12 w-3/4">
    <h1 class="ml-32 text-3xl text-red-700 font-medium">Desconectado</h1>
    <div
      class="flex flex-col items-center justify-center pb-3 border border-[#ECEEEE] bg-[#f2f3f3]"
    >
      <div class="w-full">
        <div class="ml-12 mt-2">
          <h1 class="text-2xl font-bold">Oops, não conectado!</h1>
          <p class="font-medium">Deseja iniciar uma nova conexão?</p>
        </div>
      </div>
      <div class="mt-12">
        @if (!searchingConnection) { @if (integrationExists) {

        <button
          class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] text-white rounded-lg py-2 px-24"
          (click)="fetchConnection()"
        >
          Iniciar
        </button>

        } @else {

        <button
          class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] text-white rounded-lg py-2 px-24"
          (click)="addIntegrationWithWhatsapp()"
        >
          Adicionar integração com whatsapp
        </button>

        } } @else {
        <div>
          <app-spinner></app-spinner>
        </div>
        }
      </div>
    </div>
  </div>
</div>
