import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EMPTY, catchError, interval, map } from 'rxjs';
import { SpinnerComponent } from '../../../../../../shared/components/spinner.component';
import { Status } from '../../../../../../core/enums/agent-whatsapp-status.enum';
import { AgentsService } from '../../../../../../core/services/agents/agents.service';
import {
  DefaultComponent,
  DisconnectedComponent,
  ErrorComponent,
  LoadingComponent,
  StartedComponent,
  StartingComponent,
} from './components/components';

@Component({
  selector: 'app-whatsapp',
  standalone: true,
  imports: [
    SpinnerComponent,
    CommonModule,
    DefaultComponent,
    DisconnectedComponent,
    ErrorComponent,
    LoadingComponent,
    StartedComponent,
    StartingComponent,
  ],
  templateUrl: './whatsapp.component.html',
  styleUrl: './whatsapp.component.css',
})
export class WhatsappComponent implements OnInit, AfterViewInit {
  constructor(private agents: AgentsService) {}

  protected qrBase64!: string;

  protected whatsappIsEnable: Status = 'disconnected';
  private reqController = 0;
  private maxRequest = 6;
  private interval!: NodeJS.Timeout;
  protected load = true;

  ngOnInit(): void {
    this.agents.qrcode.subscribe((qr) => {
      console.warn(`qrcode ${qr.length}`);

      this.qrBase64 = qr;
      if (qr.length) this.whatsappIsEnable = 'connecting';
    });

    // this.interval = setInterval(() => {
    //   this.getStatus();
    //   this.reqController++;
    // }, 50000);
  }

  ngAfterViewInit(): void {
    this.getStatus();
  }

  private getStatus() {
    if (this.reqController === this.maxRequest) {
      this.interval.unref();
    }

    this.agents.actions
      .getStatusForAgent('whatsapp')
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.whatsappIsEnable = 'disconnected';
          return EMPTY;
        }),
        map((req) => req.data),
      )
      .subscribe({
        next: (req) => {
          this.load = false;
          if (req.status) {
            this.whatsappIsEnable = req.status;
          }

          // if (req.qr && req.status === 'connecting') {
          //   this.qrBase64 = `data:image/png;base64,${req.qr}`;
          // }
        },
        error: (err) => {
          this.load = false;
          this.whatsappIsEnable = 'disconnected';
        },

        complete: () => {
          this.load = false;
        },
      });
  }

  public toggleQrcode(qr: string) {
    this.qrBase64 = qr;
  }
  disconnect(confirm: boolean) {
    if (confirm) {
      this.whatsappIsEnable = 'disconnected';
    }
  }
}
