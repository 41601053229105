import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BannerComponent } from '../../../../../../../shared/components/banner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-scheduling',
  standalone: true,
  imports: [CommonModule, BannerComponent, FormsModule],
  templateUrl: './scheduling.component.html',
})
export class SchedulingComponent {
  private _dateTime = new BehaviorSubject<string>('');

  protected secureShoot = {
    wa: false,
    tel: false,
  };

  protected min = (() => {
    const now = new Date();

    const pad = (num: number) => String(num).padStart(2, '0');

    const date = now.toISOString().split('T')[0];
    const time = `${pad(now.getHours())}:${pad(now.getMinutes())}`;

    return `${date}T${time}`;
  })();

  protected readonly date = new Date()
    .toLocaleDateString()
    .split('/')
    .reverse()
    .join('-');

  protected weekDays = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  protected pickDateTimeLocal(time: Event) {
    const target = time.target as HTMLInputElement;

    if (target.value) {
      this._dateTime.next(target.value);
      return;
    }
  }

  public get getDate() {
    if (this._dateTime.value) {
      return new Date(this._dateTime.value);
    }
    return null;
  }

  public get secure() {
    return this.secureShoot;
  }
}
