<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div
    class="shadow-md sm:rounded-lg dark:bg-gray-800 relative xl:max-w-full xl:w-max"
  >
    <div class="">
      <p class="md:mr-4 font-normal text-3xl ml-8">Regras existentes</p>
    </div>

    <div class="w-min">
      <caption
        class="pl-5 pt-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row"
      >
        <div class="w-2/4">
          <h1 class="text-2xl sm:text-3xl">Regras</h1>
          <p class="mt-1 text-sm font-normal text-[#16191F] dark:text-gray-400">
            Suas regras, seus negócios!
          </p>
        </div>
        <div
          class="lg:flex justify-end mr-2 hidden relative mt-4 right-0 w-full"
        >
          <app-mini-dropdown
            [items]="actions"
            [align]="
              ' lg:left-[0.5rem] xl:left-[11.8rem] 2xl:left-[11.9rem] 3xl:left-[25rem]'
            "
          ></app-mini-dropdown>

          <button
            (click)="navigateToEditRulesOfUsers()"
            class="font-bold dark:font-light h-8 w-48 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
          >
            Associar
          </button>
        </div>
      </caption>
      <table
        class="w-max text-sm text-left overflow-auto rtl:text-right text-[#16191F] dark:text-gray-400 xl:min-w-[50rem] 2xl-w-[65rem] 3xl:w-[70rem]"
      >
        <thead
          class="text-xs text-[#16191F] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox-all-search"
                  disabled
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" class="px-8 py-3">Nome</th>
            <th scope="col" class="px-8 py-3">Status</th>
            <th scope="col" class="px-8 py-3">Criado em</th>
            <th scope="col" class="px-8 py-3">Criado por</th>
          </tr>
        </thead>
        <tbody>
          @for(rule of rules; track rule.id) {
          <tr
            [ngClass]="{
              'bg-[#0073bb42] border hover:bg-[#0073bb42] border-[#0073BB]':
                rulesIdsControllers.has(rule.id),
              'hover:bg-[#cfdcdd]': !rulesIdsControllers.has(rule.id)
            }"
            class="bg-white border-b text-[#16191F] dark:bg-gray-800 dark:border-gray-700 hover:transition-all hover:bg-[#cfdcdd]"
          >
            <td class="w-4 px-6">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox"
                  (change)="selectRule(rule.id)"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox" class="sr-only">checkbox</label>
              </div>
            </td>
            <td
              scope="row"
              class="relative space-x-12 items-center group px-8 py-0.5 dark:text-white"
            >
              <p>{{ rule.name || "" }}</p>
              <div class="" (click)="('')">
                <app-edit-icon
                  class="absolute right-0 bottom-0 hidden group-hover:block hover:border-gray-400 hover:border z-50 mr-0 p-0"
                ></app-edit-icon>
              </div>
            </td>
            <td
              [ngClass]="[rule.status ? 'text-[#28BD09]' : 'text-[#D13313]']"
              class="mx-1 px-6 py-0.5 flex flex-row gap-1 text-[#28BD09]"
            >
              @if (rule.status) {
              <app-verify-true></app-verify-true> habilitado } @else {
              <app-verify-false></app-verify-false> desabilitado }
            </td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              {{ rule.created_at || "Não informado" }}
            </td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              <div class="flex flex-col items-center justify-center">
                {{ rule.created_by ? rule.created_by.name : "Não informado" }}
              </div>
            </td>
          </tr>
          } @empty {
          <tr class="xl:w-14">
            <td
              colspan="5"
              class="px-6 py-4 text-center dark:text-white hover:transition-all"
            >
              Nenhuma regra existente
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <div
      class="dark:border-gray-600 z-50 fixed top-0 right-0 left-0"
      cdkDrag
      *ngIf="showModal"
    >
      <div class="mt-3">
        <!-- <app-modal-rule [model]="model" (close)="closeModal()" (modal)="closeModal()"></app-modal-rule> -->
      </div>
    </div>
  </div>
</section>
