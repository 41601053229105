import moment from 'moment';

export function dateTransform(date: string): string {
  const now = moment();
  const receivedDate = moment(date).format('DD/MM/YYYY, HH:mm');
  const diffInSeconds = Math.abs(now.diff(receivedDate, 'seconds'));
  const diffInHours = diffInSeconds / 3600;
  if (diffInSeconds < 60) {
    return 'Agora mesmo';
  }
  if (diffInHours < 24 && now.isSame(receivedDate, 'day')) {
    return `Hoje, ${moment(date).format('HH:mm')}`;
  }
  return receivedDate;
}
