import { CommonModule, WeekDay } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { faSolidArrowsRotate } from '@ng-icons/font-awesome/solid';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  forkJoin,
  of,
} from 'rxjs';
import { AgentsService } from '../../../../../core/services/agents/agents.service';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { StoreService } from '../../../../../core/services/shop/store.service';
import {
  AgentKey,
  AgentType,
  Chats,
  SendResponse,
} from '../../../../../core/types/agents.interface';
import { IMessage } from '../../../../../core/types/message.interface';
import { IStores } from '../../../../../core/types/store.interface';
import { MessageService } from '.././../../../../core/services/message/message.service';
import { BannerComponent } from '../../../../../shared/components/banner.component';
import { SelectComponent } from '../../../../../shared/components/select.component';
import { Router } from '@angular/router';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { TriggeringService } from '../../../../../core/services/triggering/triggering.service';
@Component({
  selector: 'app-new-triggering',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    NgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    BannerComponent,
    SchedulingComponent,
  ],
  viewProviders: [
    provideIcons({
      faSolidArrowsRotate,
    }),
  ],
  templateUrl: './new-triggering.component.html',
  styleUrl: './new-triggering.component.css',
})
export class NewTriggeringComponent implements OnInit, AfterViewInit {
  searchGroups($event: string) {
    const regex = new RegExp($event.toLowerCase(), 'g');
    this.filterGroups = this.groups.filter((g) =>
      regex.test(g.title.toLowerCase())
    );
  }
  constructor(
    private data: DataService,
    private agents: AgentsService,
    private store: StoreService,
    private message: MessageService,
    private event: EventsService,
    private router: Router,
    private triggering: TriggeringService
  ) {}

  @ViewChild(SchedulingComponent) scheduling!: SchedulingComponent;
  @ViewChild('storeSelect') private storeSelect!: SelectComponent;
  @ViewChild(SelectComponent)
  private select!: SelectComponent;

  private channelSelected = new BehaviorSubject<'WA' | 'TEL' | null>(null);
  private groupsForShoot = new Set<string>();
  private storesForShoot = new Set<number>();
  private messagesForShoot = new Set<number>();
  private messages!: IMessage[];
  protected messagesThatHaveStoresIncluded!: IMessage[];

  protected groups!: Chats[];
  protected filterGroups!: Chats[];
  protected stores!: IStores[];

  protected selectGroupIsEnabled = false;
  protected selectMessageIsEnabled = false;

  ngOnInit(): void {
    this.data.changeData(
      'Disparos',
      'Automatize seu trabalho disparando em massa qualquer mensagem para seus clientes.',
      true
    );
    this.getStores();
    this.getMessages();
  }

  ngAfterViewInit(): void {
    this.channelSelected.subscribe((channel) => {
      if (!this.select) return;
      if (!channel) {
        this.select.enabled = false;
        return;
      }
      this.select.enabled = true;
      this.getDataForTriggering(channel);
    });
  }

  protected getChannelOfTriggering(): { whatsapp: boolean; telegram: boolean } {
    const channel = this.channelSelected.value;
    return {
      whatsapp: channel === 'WA',
      telegram: channel === 'TEL',
    };
  }

  private updateMessagesThatHaveStoresIncluded() {
    if (!this.messages) return;

    const filteredMessages = this.messages.filter((message) =>
      message.stores.some((storeObj) =>
        this.storesForShoot.has(storeObj.store.id)
      )
    );
    this.messagesThatHaveStoresIncluded = filteredMessages;
  }

  protected getStores() {
    this.store.getAllStores().subscribe((req) => {
      this.stores = req;
    });
  }

  protected getMessages() {
    this.message.getMessage().subscribe((msg) => {
      this.messages = msg;
    });

    this.updateMessagesThatHaveStoresIncluded();
  }

  protected getDataForTriggering(channel: 'WA' | 'TEL') {
    if (!this.select.enabled) return;

    this.agents.getAgent().subscribe((res) => {
      const integration =
        this.channelSelected.value === 'TEL'
          ? res.integration.telegram
          : res.integration.whatsapp;

      this.groupsForShoot = new Set(integration.markedChats);
    });

    this.agents.getChats(channel).subscribe((chats) => {
      this.groups = chats.map((ch) => ({ id: ch.id, title: ch.title }));

      this.filterGroups = this.groups;
    });
  }

  protected setChannelOfTriggering(channel: 'WA' | 'TEL') {
    this.channelSelected.next(channel);
  }

  protected markChatForShoot(id: string) {
    if (!this.channelSelected.value) {
      return;
    }

    const inList = this.groupsForShoot.has(id);

    this.agents
      .markOrUnmarkChatsForTriggering(
        this.channelSelected.value,
        id,
        inList ? 'remove' : 'add'
      )
      .subscribe(() => {
        inList ? this.groupsForShoot.delete(id) : this.groupsForShoot.add(id);
      });
  }

  protected markStoreForShoot(id: number) {
    this.storesForShoot.clear();
    this.storesForShoot.add(id);
    this.storeSelect.close();
    this.updateMessagesThatHaveStoresIncluded();
  }

  protected markMessageForShoot(id: number) {
    if (this.messagesForShoot.has(id)) {
      this.messagesForShoot.delete(id);
      return;
    }

    this.messagesForShoot.add(id);
    this.updateMessagesThatHaveStoresIncluded();
  }

  private createTriggering(data$: Observable<SendResponse>[]) {
    forkJoin(data$)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: 'Mensagem enviada para a fila!',
            event: 'success',
          });
          this.router.navigate(['/home', 'status-triggering'], {
            queryParams: {
              key: this.channelSelected.value,
              return: 'new-triggering',
            },
          });
        },
      });
  }

  public sendTrigger() {
    // console.log(this.scheduling.getDate().toString());
    // const channel = this.channelSelected.value;
    // if (!channel) {
    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Canal não selecionado!',
    //     event: 'error',
    //   });
    //   return;
    // }
    // if (this.groupsForShoot.size < 1) {
    //   this.event.emitEventForStoreSuccess({
    //     visible: true,
    //     message: 'Selecione os grupos/canais onde o disparo irá ocorrer.',
    //     event: 'error',
    //   });
    //   return;
    // }
    // const messagesForShoot$ = this.messages
    //   .filter((msg) => this.messagesForShoot.has(msg.id))
    //   .map((msg) =>
    //     this.triggering.shootMessageMediaForMarkedChats({
    //       key: channel,
    //       text: msg.message,
    //       image: msg.image,
    //       type: 'text',
    //       _date_from_start: this.scheduling.getDate().toString(),
    //     })
    //   );
    // if (this.scheduling.isImmediate) {
    //   const messagesForShootImediate$ = this.messages
    //     .filter((msg) => this.messagesForShoot.has(msg.id))
    //     .map((msg) =>
    //       this.triggering.shootMessageMediaForMarkedChats({
    //         key: channel,
    //         text: msg.message,
    //         image: msg.image,
    //         type: 'text',
    //       })
    //     );
    //   this.createTriggering(messagesForShootImediate$);
    // }
    // this.createTriggering(messagesForShoot$);
  }

  public get storesForShootController() {
    return this.storesForShoot;
  }

  public get groupsForShootController() {
    return this.groupsForShoot;
  }
  public get messagesForShootController() {
    return this.messagesForShoot;
  }

  public get channel() {
    return this.channelSelected.value;
  }
}
