import { group } from 'console';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, combineLatest, map } from 'rxjs';
import { ChangedPermissionGroupIsInvalidOrDoesNotExistException } from '../../../../../shared/exceptions/groups/ChangedPermissionGroupIsInvalidOrDoesNotExistException.exception';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { GroupsService } from '../../../../../core/services/groups/groups.service';
import { PermissionsService } from '../../../../../core/services/permissions/permissions.service';
import { Data, Permission } from '../../../../../core/types/groups.interface';
import { PermissionData } from '../../../../../core/types/permissions.interface';

@Component({
  selector: 'app-groups-rules',
  standalone: true,
  imports: [],
  templateUrl: './groups-rules.component.html',
})
export class GroupsRulesComponent {
  constructor(
    private data: DataService,
    private groupsService: GroupsService,
    private route: Router,
    private permission: PermissionsService,
  ) {}

  private urlSegmentQuery = new URLSearchParams(window.location.search).get(
    'id',
  );

  private groupsForPutController: Data[] = [];

  private subscription!: Subscription;

  protected groups!: Data[];

  protected rules!: PermissionData[];

  ngOnInit(): void {
    this.getGroupsByUrlSegmentId();
    this.getPermissions();

    this.data.changeData(
      'Regras associadas',
      'Manipule aqui as regras associadas a um ou mais usuários',
      true,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private parseIdToNumber(id: string) {
    if (Number.isNaN(id)) this.route.navigate(['/home']);

    return Number.parseInt(id);
  }

  private getGroupsByUrlSegmentId(): void {
    if (!this.urlSegmentQuery) {
      return;
    }

    const groups$ = this.urlSegmentQuery
      .split(',' || '')
      .map((id) => this.groupsService.getGroupsById(this.parseIdToNumber(id)));

    this.subscription = combineLatest(groups$).subscribe((groups) => {
      this.groups = groups;
    });
  }

  private getPermissions() {
    this.permission.getPermissions().subscribe((permission) => {
      this.rules = permission as unknown as PermissionData[];
    });
  }

  public idAsIncludesInGroups(id: number) {
    const permissions: Permission[] = this.groups.flatMap(
      (group) => group.permissions,
    );

    return permissions.map((p) => p.id).includes(id);
  }

  public addOrRemoveGroupPermission(
    rule: PermissionData,
    permissionIndex: number,
    group: Data,
  ) {
    const groupClone = Object.assign({}, group);
    const permission = { id: rule.id, name: rule.name };

    if (this.groupsForPutController.includes(groupClone)) {
      const gpIndex = this.groupsForPutController.findIndex(
        (gp) => gp.id === groupClone.id,
      );
      this.groupsForPutController.splice(gpIndex, 1);
    }

    if (groupClone.permissions.includes(permission)) {
      groupClone.permissions.splice(permissionIndex, 1);
      this.groupsForPutController.push(groupClone);
      return;
    }

    groupClone.permissions.push(permission);
    this.groupsForPutController.push(groupClone);
  }

  // public addOrRemoveGroupPermission(
  //   value: boolean,
  //   rule: PermissionData,
  //   index: number,
  // ) {

  //   const updatedGroup = { ...this.groups[index] };
  //   const updatedPermissions = [...updatedGroup.permissions];

  //   const ruleIndex = updatedPermissions.findIndex(
  //     (data) => data.id === rule.id,
  //   );

  //   if (!value && ruleIndex !== -1) {
  //     console.log('false, colocando no controller');

  //     updatedPermissions.splice(ruleIndex, 1);
  //     updatedGroup.permissions = updatedPermissions;
  //     this.groupsForPutController[index] = updatedGroup;
  //     return;
  //   }

  //   updatedPermissions.push(rule);
  //   updatedGroup.permissions = updatedPermissions;

  //   if (!this.groupsForPutController.includes(updatedGroup)) {
  //     console.log('Não está no controller');

  //     this.groupsForPutController[index] = updatedGroup;
  //   }
  // }

  public sendChangesInGroups() {
    console.log(this.groupsForPutController);
  }
}
