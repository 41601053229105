import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AgentsService } from '../../services/agents/agents.service';

export const checkAgentGuard: CanActivateFn = (route, state) => {
  const agent = inject(AgentsService);
  const router = inject(Router);

  return agent.actions.getDataForAgent().pipe((source) => {
    return source.pipe(
      switchMap((agent) => {
        const test = agent.success && Object.keys(agent.data).length > 0;

        if (!test) router.navigate(['/home', 'agentes']);
        return of<boolean>(test);
      }),
    );
  });
};
