import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DownIconComponent } from '../../../../shared/components/down-icon.component';
import { EditIconComponent } from '../../../../shared/components/edit-icon.component';
import { SelectComponent } from '../../../../shared/components/select.component';
import { DataService } from '../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../core/services/card-output/events.service';
import { UserService } from '../../../../core/services/user/user.service';
import { PutUserMe, UserMe } from '../../../../core/types/user.interface';

@Component({
  selector: 'app-profile',
  standalone: true,
  host: { ngSkipHydration: 'true' },
  imports: [
    DownIconComponent,
    CommonModule,
    EditIconComponent,
    FormsModule,
    SelectComponent,
  ],
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  constructor(
    private dataService: DataService,
    private userService: UserService,
    private event: EventsService,
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  protected user!: UserMe;
  protected userModel!: string;
  protected enableInputChangeName = false;
  protected enableInputChangeSystemName = false;

  protected userThemePreference = 'Tema';
  protected userThemePreferenceOptions = [
    { value: 'Tema', label: 'Tema' },
    { value: 'dark', label: 'Escuro' },
    { value: 'ligth', label: 'Claro' },
  ];

  protected userLanguagePreference = 'Idioma';
  protected userLanguagePreferenceOptions = [
    { value: 'Idioma', label: 'Idioma' },
    { value: 'pt-BR', label: 'Português' },
    { value: 'en-US', label: 'Inglês' },
  ];

  protected previews: {
    my?: string;
    store?: string;
  } = {};

  @ViewChild('name') private name!: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.dataService.changeData('Perfil', '', true);
    this.getUser();
  }

  private getUser() {
    this.userService.getDataOfUser().subscribe((user) => {
      this.user = user;
      this.userModel = user.name;
    });
  }

  public toggleUsername() {
    this.enableInputChangeName = !this.enableInputChangeName;
  }

  public toggleThemePreference() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.userThemePreference === 'Tema') return;

      localStorage.setItem('color-theme', this.userThemePreference);
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.remove('ligth');
      document.documentElement.classList.add(this.userThemePreference);
    }
  }

  public changeProfile(event: Event, type: 'my' | 'store') {
    const target = event.target as HTMLInputElement;

    if (target?.files) {
      if (target.files[0].type.includes('image') === false) {
        // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
        return (this.previews[type] = undefined);
      }
      this.previews[type] = URL.createObjectURL(target.files[0]);

      if (this.previews[type]) {
        this.userService
          .addImageProfileForUser(target.files[0])
          .subscribe(() => {
            this.event.emitEventForStoreSuccess({
              visible: true,
              message: 'Imagem de perfil registrada com sucesso!',
              event: 'success',
            });
            this.getUser();
          });
      }
    }
  }

  public changeUsername(name: string) {
    this.enableInputChangeName = !this.enableInputChangeName;

    if (this.user.name === name) return;

    this.userService.getUserById(this.user.id).subscribe((user) => {
      const userMe: PutUserMe = {
        ...this.user,
        name: name,
        password: user.data.password,
      };

      this.userService.updateUserMe(userMe).subscribe((user) => {
        this.userService.me = user.data;
        this.user = user.data;
      });
    });
  }
}
