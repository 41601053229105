import { AgentKey, AgentType } from '../../../types/agents.interface';
import { Connection } from '../connection/connection';

export class Actions extends Connection {
  private _qrcode!: string | null;

  public get qrcode(): string | null {
    return this._qrcode;
  }

  public getStatusForAgent(agent: AgentType) {
    return this.getStatus(agent);
  }

  public getDataForAgent() {
    return this.getAgent();
  }

  public createNewAgent() {
    return this.createAgent();
  }

  public addAgent(key: AgentKey, apiKey?: string) {
    return this._addAgent(key, apiKey);
  }
}
