import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SelectComponent } from '../../../../../../../shared/components/select.component';
import {
  AgentKey,
  Chats,
} from '../../../../../../../core/types/agents.interface';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  forkJoin,
  map,
  shareReplay,
  tap,
} from 'rxjs';
import { AgentsService } from '../../../../../../../core/services/agents/agents.service';
import { faSolidArrowsRotate } from '@ng-icons/font-awesome/solid';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { EventsService } from '../../../../../../../core/services/card-output/events.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SchedulingComponent } from '../scheduling/scheduling.component';

@Component({
  selector: 'app-triggering',
  standalone: true,
  imports: [
    SelectComponent,
    NgIconComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SchedulingComponent,
  ],
  viewProviders: [
    provideIcons({
      faSolidArrowsRotate,
    }),
  ],
  templateUrl: './triggering.component.html',
})
export class TriggeringComponent implements OnInit, OnDestroy {
  constructor(private agents: AgentsService) {}

  private waChats!: Chats[];
  private telChats!: Chats[];

  protected waChatsForShoot: Set<string> = new Set();
  protected telChatsForShoot: Set<string> = new Set();
  protected searchingWaData = false;
  protected searchingTelData = false;

  private _waSelected = new BehaviorSubject<boolean>(false);
  private _telSelected = new BehaviorSubject<boolean>(false);

  protected waFilterGroups: Chats[] = [];
  protected telFilterGroups: Chats[] = [];

  ngOnInit(): void {
    this._waSelected.subscribe((value) => {
      if (value) {
        this.getWaChats();
      }
    });

    this._telSelected.subscribe((value) => {
      if (value) {
        this.getTelChats();
      }
    });
  }
  ngOnDestroy(): void {}

  protected selectWa() {
    this._waSelected.next(!this.waSelected);
  }

  protected selectTel() {
    this._telSelected.next(!this.telSelected);
  }

  protected getWaChats() {
    this.searchingWaData = true;

    const integration$ = this.agents.getAgent().pipe(
      map((res) => res.integration.whatsapp),
      tap((integration) => {
        this.waChatsForShoot = new Set(integration.markedChats);
      }),
      shareReplay(1)
    );

    const chats$ = this.agents.getChats('WA').pipe(
      finalize(() => {
        this.searchingWaData = false;
      })
    );

    combineLatest([integration$, chats$]).subscribe(
      ([integration, waChats]) => {
        // TODO waChats pode conter chats repetidos. Coloque em um array chats com ids diferentes
        const chats: Chats[] = [];

        for (const chat of waChats) {
          if (!chats.some((c) => c.id === chat.id)) {
            chats.push(chat);
          }
        }

        this.waChats = chats;
        this.waFilterGroups = chats;
      }
    );
  }

  protected getTelChats() {
    this.searchingTelData = true;

    const integration$ = this.agents.getAgent().pipe(
      map((res) => res.integration.telegram),
      tap((integration) => {
        this.telChatsForShoot = new Set(integration.markedChats);
      }),
      shareReplay(1)
    );

    const chats$ = this.agents.getChats('TEL').pipe(
      finalize(() => {
        this.searchingTelData = false;
      })
    );

    combineLatest([integration$, chats$]).subscribe(
      ([integration, telChats]) => {
        this.telChats = telChats;
        this.telFilterGroups = telChats;
      }
    );
  }

  protected markChatForShoot(id: string, type: 'WA' | 'TEL') {
    if (type === 'WA') {
      const inList = this.waChatsForShoot.has(id);

      this.agents
        .markOrUnmarkChatsForTriggering(type, id, inList ? 'remove' : 'add')
        .subscribe(() => {
          inList
            ? this.waChatsForShoot.delete(id)
            : this.waChatsForShoot.add(id);
        });
    }

    if (type === 'TEL') {
      const inList = this.telChatsForShoot.has(id);

      this.agents
        .markOrUnmarkChatsForTriggering(type, id, inList ? 'remove' : 'add')
        .subscribe(() => {
          inList
            ? this.telChatsForShoot.delete(id)
            : this.telChatsForShoot.add(id);
        });
    }
  }

  protected searchGroups($event: string, type: AgentKey) {
    const regex = new RegExp($event.toLowerCase(), 'g');

    if (type === 'WA') {
      this.waFilterGroups = this.waChats.filter((chat) =>
        regex.test(chat.title.toLowerCase())
      );
    }

    if (type === 'TEL') {
      this.telFilterGroups = this.telChats.filter((chat) =>
        regex.test(chat.title.toLowerCase())
      );
    }
  }

  public get telSelected() {
    return this._telSelected.value;
  }

  public get waSelected() {
    return this._waSelected.value;
  }

  public get markedChats() {
    return {
      WA: Array.from(this.waChatsForShoot),
      TEL: Array.from(this.telChatsForShoot),
    };
  }
}
