<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div class="shadow-md sm:rounded-lg dark:bg-gray-800 relative w-3/5">
    <div>
      <app-page-title>Status de disparo</app-page-title>
    </div>
    <div>
      <app-caption-table>
        <h1>Status</h1>
        <h2>Tenha o controle e saiba quais disparos chegaram ao destino!</h2>
        <h3>Voltar</h3>
      </app-caption-table>
    </div>
    <app-table-container>
      <app-thead-table>
        <app-thead-tr-table>
          <app-thead-th-table scope="col" type="column">
            <div class="flex items-center justify-center w-full h-full">
              Destino
            </div>
          </app-thead-th-table>
          <app-thead-th-table scope="col" type="column">
            <div class="flex justify-center w-full h-full">Status</div>
          </app-thead-th-table>
          <app-thead-th-table scope="col" type="column">
            <div class="flex justify-center w-full h-full">
              <p class="mr-5">Tipo</p>
            </div>
          </app-thead-th-table>
          <app-thead-th-table scope="col" type="column">
            <div class="flex items-center justify-center w-full h-full">
              Plataforma
            </div>
          </app-thead-th-table>
        </app-thead-tr-table>
      </app-thead-table>
      <app-tbody-table>
        @for (item of items; track $index) {
        <app-tbody-tr-table>
          <app-tbody-td-table> {{ item.name }}</app-tbody-td-table>
          <app-tbody-td-table>
            <div class="flex items-center justify-center h-full w-full">
              @if (item.status === 'in progress') {
              <app-load-icon></app-load-icon>
              }@else {
              <div class="">
                <app-success-icon></app-success-icon>
              </div>
              }
            </div>
          </app-tbody-td-table>
          @if(item.key === 'WA') {
          <app-tbody-td-table>Grupo</app-tbody-td-table>
          <app-tbody-td-table>Whatsapp</app-tbody-td-table>
          } @else {
          <app-tbody-td-table>Canal</app-tbody-td-table>
          <app-tbody-td-table>Telegram</app-tbody-td-table>

          }
        </app-tbody-tr-table>
        }
      </app-tbody-table>
    </app-table-container>
  </div>
</section>
