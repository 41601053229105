import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY } from 'rxjs';
import { SpinnerComponent } from '../../../../../../shared/components/spinner.component';
import { AgentsService } from '../../../../../../core/services/agents/agents.service';
import { UserService } from '../../../../../../core/services/user/user.service';
import {
  AgentType,
  GetAgentData,
} from '../../../../../../core/types/agents.interface';
import { RouterModule, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-agents-default',
  standalone: true,
  imports: [SpinnerComponent, NgIf, RouterOutlet, RouterModule],
  templateUrl: './agents-default.component.html',
})
export class AgentsDefaultComponent implements OnInit {
  constructor(
    private agents: AgentsService,
    private user: UserService,
  ) {}

  public searchingConnection!: boolean;

  private _agentExists!: boolean;
  protected agent?: GetAgentData;
  protected load = true;

  ngOnInit(): void {
    this.agents
      .getAgent()
      .pipe(
        catchError((err) => {
          this.load = false;
          this._agentExists = false;
          return EMPTY;
        }),
      )
      .subscribe({
        next: (res) => {
          this.agent = res;
          this._agentExists = true;
          this.load = false;
        },

        error: (err) => {
          console.error(err);
          this._agentExists = false;
        },
      });
  }

  public get agentExists(): boolean {
    return this._agentExists;
  }

  public createNewAgent() {
    this.agents.actions.createNewAgent().subscribe((agent) => {
      this.user.agent = agent;
      this._agentExists = true;
    });
  }
}
