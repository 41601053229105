<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div
    class="shadow-md sm:rounded-lg dark:bg-gray-800 relative xl:max-w-full xl:w-max"
  >
    <div class="">
      <p class="md:mr-4 font-normal text-3xl ml-8">Usuários cadastrados</p>
    </div>

    <div>
      <caption
        class="pl-5 pt-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row"
      >
        <div class="w-2/4">
          <h1 class="text-2xl sm:text-3xl">Usuários</h1>
          <p class="mt-1 text-sm font-normal text-[#16191F] dark:text-gray-400">
            Gerencie usuários e acessos.
          </p>
        </div>
        <div
          class="lg:flex justify-end mr-2 hidden relative mt-4 right-0 w-full"
        >
          <app-mini-dropdown [items]="actions"></app-mini-dropdown>

          <button
            (click)="navigateToEditRulesOfUsersSelected()"
            class="font-bold dark:font-light h-8 w-48 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
          >
            Associar regra
          </button>
        </div>
      </caption>
      <table
        class="w-max text-sm text-left overflow-auto rtl:text-right text-[#16191F] dark:text-gray-400 xl:min-w-[50rem] 2xl-w-[65rem] 3xl:w-[70rem]"
      >
        <thead
          class="text-xs text-[#16191F] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox-all-search"
                  (change)="selectAllUsersOfTable()"
                  #checkboxFather
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center">Nome</div>
            </th>
            <th scope="col" class="px-8 py-3">Status</th>
            <th scope="col" class="px-8 py-3">Criado por</th>
            <th scope="col" class="px-8 py-3">Grupo de política associado</th>
          </tr>
        </thead>
        <tbody>
          @for(user of users; track user.id) {
          <tr
            [ngClass]="{
              'bg-[#0073bb42] dark:bg-[#18345a] hover:bg-[#0073bb42] border-[#0073BB]':
                usersIdsControllers.has(user.id),
              'hover:bg-[#cfdcdd] dark:bg-gray-800 dark:border-gray-700':
                !usersIdsControllers.has(user.id)
            }"
            class="bg-white border-b border text-[#16191F] hover:transition-all hover:bg-[#cfdcdd]"
          >
            <td class="w-4 px-6">
              <div class="flex items-center justify-center">
                <input
                  (change)="selectUser(user)"
                  type="checkbox"
                  #inputCheckbox
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox" class="sr-only">checkbox</label>
              </div>
            </td>
            <td
              scope="row"
              class="relative items-center group px-8 py-0.5 dark:text-white"
            >
              <input
                class="h-5 border-none bg-transparent"
                type="text"
                #userInput
                [ngClass]="user.id === userEdit ? 'block' : 'hidden'"
                [(ngModel)]="username"
                (blur)="sendNewNameOfUser(userInput.value, user)"
                (keyup.enter)="sendNewNameOfUser(userInput.value, user)"
              />

              <p *ngIf="user.id !== userEdit">{{ user.name || "" }}</p>

              <div
                class=""
                *ngIf="user.id !== userEdit"
                (click)="displayInputOfUsername(user)"
              >
                <app-edit-icon
                  class="absolute right-0 bottom-0 hidden group-hover:block hover:border-gray-400 hover:border z-50 mr-0 p-0"
                ></app-edit-icon>
              </div>
            </td>
            <td
              [ngClass]="[user.status ? 'text-[#28BD09]' : 'text-[#D13313]']"
              class="mx-1 px-6 py-0.5 flex flex-row gap-1 text-[#28BD09]"
            >
              @if (user.status) {
              <app-verify-true></app-verify-true> habilitado } @else {
              <app-verify-false></app-verify-false> desabilitado }
            </td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              {{ user.created_by ? user.created_by.name : "Não informado" }}
            </td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              <div class="flex flex-col items-center justify-center">
                {{ user.role || "Não informado" }}
              </div>
            </td>
          </tr>
          } @empty {
          <tr class="xl:w-14">
            <td
              colspan="5"
              class="px-6 py-4 text-center dark:text-white hover:transition-all"
            >
              Nenhum usuário existente
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</section>
