import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DataService } from '../../../../core/services/card-output/data-service.service';

@Component({
  selector: 'app-home-card-body',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-card-body.component.html',
})
export class HomeCardBodyComponent {
  constructor(private dataService: DataService) {}

  cardName = '';
  cardDescription = '';

  ngOnInit() {
    this.dataService.routeData().name.subscribe((name) => {
      this.cardName = name;
    });

    this.dataService.routeData().description.subscribe((description) => {
      this.cardDescription = description;
    });
  }
}
