import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SocketService } from '../../services/agents/socket/socket.service';
import { UserService } from '../../services/user/user.service';

export const socketGuard: CanActivateFn = (route, state) => {
  const socket = inject(SocketService);
  const user = inject(UserService);

  try {
    if (socket.verifyBotIdCookie()) {
      socket.startWebsocket(socket.getBotIdCookie());
    }

    if (user.me.agentId) {
      socket.startWebsocket(user.me.agentId);
    }
  } catch (error) {
    return true;
  }

  return true;
};
