import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EMPTY, Subscription, catchError } from 'rxjs';
import { AuthService } from '../../../core/services/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.css',
    'forgot-responsive.component.css',
  ],
})
export class ForgotPasswordComponent {
  constructor(private auth: AuthService) {}
  public authController = false;
  public inputValid = false;
  public email!: FormControl;

  ngOnInit() {
    this.email = new FormControl('', [Validators.required, Validators.email]);
  }

  private changeController() {
    this.inputValid = false;
    this.authController = !this.authController;
  }

  public forgotPassword(event: Event) {
    event.preventDefault();
    if (this.email.invalid) return;

    this.auth
      .sendMailForResetPassword(this.email.value)
      .pipe(
        catchError((err) => {
          this.inputValid = true;
          this.authController = false;
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.changeController();
      });
  }
}
