import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SpinnerComponent } from '../../../../../shared/components/spinner.component';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { StoreService } from '../../../../../core/services/shop/store.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { StoreForModal } from '../../../../../core/types/store.interface';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, FormsModule, SpinnerComponent, ReactiveFormsModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.css',
})
export class CardComponent {
  constructor(
    private storeService: StoreService,
    private dataService: DataService,
    private event: EventsService,
    private user: UserService,
  ) {}

  ngOnInit() {
    const store = 'Lojas';
    const description =
      'Descubra, cadastre e gerencie suas lojas nesta tela. Use esta tela para organizar suas lojas.';
    this.dataService.changeData(store, description, true);

    this.storeFormGroup = new FormGroup({
      loja: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(/\S/),
      ]),
    });

    this.tagsFormGroup = new FormGroup({
      tags: new FormControl(null, [
        Validators.required,
        Validators.pattern(/\S/),
      ]),
    });
  }

  ngOnDestroy() {
    this.dataService.changeData('', '', false);
  }

  /* Boleanos de controle */
  protected alertNoCaractereInInput = false;
  protected spinner = false;
  /* Fim dos Boleanos de controle */

  /* Grupos de Formularios*/
  protected storeFormGroup!: FormGroup;
  protected tagsFormGroup!: FormGroup;
  /* Fim dos Grupos de Formularios*/

  protected messageErrorInRequestAPI = '';
  private storeList: string[] = new Array<string>();

  private get tagsControl() {
    return this.tagsFormGroup.get('tags');
  }

  private updateTagsControlState() {
    if (this.getTags().length >= 5) {
      this.tagsControl?.disable();
    }
    this.tagsControl?.enable();
  }

  public removeTag(index: number) {
    this.storeList.splice(index, 1);
    this.tagsFormGroup.reset();
    this.updateTagsControlState();
  }

  public addTagItem() {
    if (!this.tagsFormGroup.valid) return;
    const tag = this.tagsFormGroup.get('tags')?.value;
    this.storeList.push(tag);
    this.tagsFormGroup.reset();
    this.updateTagsControlState();
  }

  public getTags() {
    return this.storeList;
  }

  public addStore() {
    this.newStore();
  }

  private newStore() {
    if (!this.storeFormGroup.valid) return;

    const dataStore: StoreForModal = {
      status: 1,
      name: this.storeFormGroup.get('loja')?.value,
      tags: this.getTags(),
      users: [this.user.me.id ?? 0],
    };

    this.spinner = true;

    this.storeService
      .addStore(dataStore)

      .subscribe((newStore) => {
        if (newStore.success) {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: `Loja ${newStore.data.name} criada com sucesso!`,
            event: 'success',
          });
          this.storeFormGroup.reset();
          this.storeList = new Array<string>();
        }
        this.spinner = false;
        this.spinner = false;
      })
      .add(() => {
        this.spinner = false;
      });
  }
}
