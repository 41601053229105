import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { ContactsService } from '../../../../../core/services/contacts/contacts.service';
import { InputStylesDirective } from '../../../../../shared/directive/inputs/input-styles.directive';

@Component({
  selector: 'app-new-contacts',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputStylesDirective,
  ],

  templateUrl: './new-contacts.component.html',
})
export class NewContactsComponent implements OnInit, OnDestroy {
  constructor(
    private contacts: ContactsService,
    private data: DataService,
    private event: EventsService,
  ) {}

  protected alertInputNameIsEmpty = false;
  protected form!: FormGroup;

  protected contact: {
    name: string;
    photo: string;
    number: string;
  } = {
    name: '',
    photo: '',
    number: '',
  };

  ngOnInit() {
    this.data.changeData(
      'Contatos',
      'Registre contatos e dispare mensagens em massa como se fosse para grupos.',
      true,
    );

    this.generateForm();
  }

  ngOnDestroy(): void {
    this.data.changeData('', '', false);
  }

  private generateForm() {
    this.form = new FormGroup({
      contactName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),

      ddi: new FormControl<string | number>('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4),
      ]),

      ddd: new FormControl<string | number>('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4),
      ]),

      phone: new FormControl<string | number>('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(12),
      ]),
    });
  }

  public createContact() {}

  public getContact() {
    if (this.form.invalid) return;

    const phoneNumber = `${this.form.get('ddi')?.value}${
      this.form.get('ddd')?.value
    }${this.form.get('phone')?.value}@whatsapp.net`;

    this.contacts.getContactByAgent(phoneNumber).subscribe((con) => {
      this.contact = con;
    });
  }
}
