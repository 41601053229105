import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, forkJoin, map, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Data,
  IStores,
  StoreById,
  StoreForModal,
  StoreResponse,
  newStoreResponse,
} from '../../types/store.interface';
import { EventsService } from '../card-output/events.service';
import { PagesService } from './pagination/pages.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(
    private http: HttpClient,
    private pages: PagesService,
    private event: EventsService,
  ) {}

  private allStores!: IStores[];

  private httpStorePost(store: StoreForModal): Observable<newStoreResponse> {
    return this.http.post<newStoreResponse>(
      environment.store.LOJA_REGISTER,
      store,
    );
  }

  public httpStoreGet(): Observable<StoreResponse> {
    return this.pages.getPages(this.pages.pageValue);
  }

  public getAllStores(): Observable<Data[]> {
    return this.pages.getTotalPages().pipe(
      switchMap((totalStores) => {
        const totalPages = Math.ceil(
          totalStores.data / this.pages.perPageValue,
        );

        const pageRequests: Observable<StoreResponse>[] = [];

        for (let i = 1; i <= totalPages; i++) {
          pageRequests.push(this.pages.getPages(i));
        }
        return forkJoin(pageRequests);
      }),
      map((resp) => resp.flatMap((resp) => resp.data)),

      map((allData) => {
        this.allStores = allData;
        return allData;
      }),
    );
  }

  public httpStoreGetById(id: string): Observable<Data> {
    return this.http
      .get<StoreById>(`${environment.store.LOJA_END_POINT}/${id}`)
      .pipe(map((store) => store.data));
  }

  public httpStorePut(store: StoreForModal): Observable<StoreResponse> {
    return this.http
      .put<StoreResponse>(
        `${environment.store.LOJA_END_POINT}/${store.id}`,
        store,
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public httpStoreDelete(id: number): Observable<StoreResponse> {
    return this.http
      .delete<StoreResponse>(`${environment.store.LOJA_END_POINT}/${id}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public addStore(store: StoreForModal): Observable<newStoreResponse> {
    const newStore = {
      status: 1,
      name: store.name,
      tags: store.tags,
      users: store.users,
    };

    return this.httpStorePost(newStore).pipe(
      catchError((err: HttpErrorResponse) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
    );
  }
}
