import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { EventsService } from '../../card-output/events.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { io, Socket } from 'socket.io-client';
import { AgentsService } from '../agents.service';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private cookies: SsrCookieService, private user: UserService) {}

  private ws = environment.socket.agents.WSS_URL;
  private platform = 'WA';
  private _socket!: Socket;
  private _connected = false;

  private getBotId(): string {
    if (this.cookies.check('botId')) {
      return this.cookies.get('botId');
    }
    return this.user.me.agentId;
  }

  public startWebsocket(bot: string = this.getBotId()) {
    this._socket = io(this.ws, {
      transports: ['websocket'],
      query: {
        type: this.platform,
        botId: this.getBotId(),
      },
    });

    return this._socket;
  }

  public setBotIdCookie(botId: string) {
    this.cookies.set('botId', botId);
  }

  public getBotIdCookie() {
    return this.cookies.get('botId');
  }

  public removeBotIdCookie() {
    this.cookies.delete('botId');
  }

  public verifyBotIdCookie() {
    return this.cookies.check('botId');
  }

  public get socket(): Socket {
    return this._socket;
  }

  public get connected() {
    return this._connected;
  }

  public set connected(value) {
    this._connected = value;
  }
}
