import { Component, OnInit } from '@angular/core';
import { Subscription, combineLatest, forkJoin, map } from 'rxjs';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { RulesService } from '../../../../../core/services/rules/rules.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { GroupsData } from '../../../../../core/types/groups.interface';
import {
  User,
  UserRequest,
  UserWithGroups,
} from '../../../../../core/types/user.interface';
import { PermissionsService } from './../../../../../core/services/permissions/permissions.service';

@Component({
  selector: 'app-user-rules',
  standalone: true,
  imports: [],
  templateUrl: './user-rules.component.html',
  styleUrl: './user-rules.component.css',
})
export class UserRulesComponent implements OnInit {
  constructor(
    private data: DataService,
    private user: UserService,
    private rule: RulesService,
    private event: EventsService,
    private permission: PermissionsService,
  ) {}

  private urlSegmentQuery = new URLSearchParams(window.location.search).get(
    'id',
  );

  private userForPutController = new Array<UserWithGroups>();

  private subscription!: Subscription;

  protected users!: UserWithGroups[];

  protected groups!: GroupsData[];

  ngOnInit(): void {
    this.getUsersByUrlSegmentId();
    this.getRules();

    this.data.changeData(
      'Regras associadas',
      'Manipule aqui as regras associadas a um ou mais usuários',
      true,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getRules() {
    this.rule.getGroups().subscribe((req) => {
      this.groups = req;
    });
  }

  private getUsersByUrlSegmentId(): void {
    if (!this.urlSegmentQuery) return;

    const users$ = this.urlSegmentQuery
      .split(',' || '')
      .map((id) =>
        this.user
          .getUserById(Number.parseInt(id))
          .pipe(map((user) => user.data)),
      );

    this.subscription = combineLatest(users$).subscribe((req) => {
      this.users = req.map((user) => ({
        ...user,
        permission: [] as number[],
      }));
    });
  }

  // public groupIncludesInUser(user: User, group: GroupsData) {
  //   const groups = user.groups.map((store) => store.id);
  //   return groups.includes(store.id);
  // }

  public selectGroup(group: GroupsData, user: UserWithGroups) {
    const findUserInController = this.userForPutController.find(
      (gp) => gp.id === user.id,
    );

    if (findUserInController) {
      const gpIndex = this.userForPutController.findIndex(
        (gp) => gp.id === user.id,
      );
      this.userForPutController.splice(gpIndex, 1);
    }

    const cloneUser = Object.assign({}, user);

    if (
      this.userForPutController.map((user) => user.id).includes(cloneUser.id)
    ) {
      const index = this.userForPutController.findIndex(
        (user) => user.id === cloneUser.id,
      );
      this.userForPutController.splice(index, 1);
    }

    cloneUser.permission.push(group.id);
    this.userForPutController.push(cloneUser);
    user.permission.push(...group.permissions.map((p) => p.id));
  }

  public associateGroupForUser() {
    const obs$ = this.userForPutController.map((user) =>
      this.permission.setPermission(user.id, user.permission),
    );

    forkJoin(obs$).subscribe(() => {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Grupo(s) associado(s) com sucesso ao(s) usuário(s)!',
        event: 'success',
      });
    });
  }
}
