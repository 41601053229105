import { Component } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  faSolidBagShopping,
  faSolidBook,
  faSolidBookOpenReader,
  faSolidEnvelope,
  faSolidListCheck,
  faSolidPaperPlane,
  faSolidRightFromBracket,
  faSolidRss,
  faSolidShop,
  faSolidUser,
} from '@ng-icons/font-awesome/solid';

import {
  faBrandTelegram,
  faBrandWhatsapp,
} from '@ng-icons/font-awesome/brands';

import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from '@angular/router';
import { PhotoComponent } from '../photo/photo.component';
import { RobotComponent } from '../robot-icon.component';
import { CommonModule } from '@angular/common';
import { UseTokenService } from '../../../../../../core/services/auth/use-token.service';
import { SocketService } from '../../../../../../core/services/agents/socket/socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    NgIconComponent,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    RobotComponent,
    PhotoComponent,
  ],
  viewProviders: [
    provideIcons({
      faSolidShop,
      faSolidBagShopping,
      faSolidEnvelope,
      faSolidPaperPlane,
      faSolidBook,
      faSolidRightFromBracket,
      faSolidUser,
      faSolidRss,
      faSolidBookOpenReader,
      faSolidListCheck,
      faBrandWhatsapp,
      faBrandTelegram,
    }),
  ],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private token: UseTokenService,
    private socket: SocketService,
  ) {}

  private queryParams!: Subscription;

  protected showAgentsHistory = false;

  protected showRules =
    this.router.url === '/home/rules' || this.router.url === '/home/users';

  protected showHistory =
    this.router.url.startsWith('/home/status-triggering') ||
    this.router.url.startsWith('/home/message-triggering');

  logout(): void {
    this.token.removeToken();
    this.socket.removeBotIdCookie();
    this.router.navigate(['/auth']);
    window.location.reload();
  }

  protected getReturn() {
    // Extrai o caminho da URL sem os query params
    const urlPath = this.router.url.split('?')[0];
    let urlSegments = urlPath.split('/').filter((segment) => segment); // Remove segmentos vazios

    if (urlSegments[0] === 'home') {
      urlSegments = urlSegments.slice(1);
    }

    const returnPath = urlSegments.join('/');

    const queryParams = this.route.snapshot.queryParams;

    return {
      path: returnPath,
      queryParams: queryParams,
    };
  }

  protected openWhatsapp() {
    this.router.navigate(['/home', 'status-triggering'], {
      queryParams: { key: 'WA', return: this.getReturn().path },
    });
  }

  ngAfterViewChecked(): void {
    // biome-ignore lint/complexity/noForEach: <explanation>
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.showHistory =
          this.router.url.startsWith('/home/status-triggering') ||
          this.router.url.startsWith('/home/message-triggering');

        this.showRules =
          this.router.url === '/home/rules' ||
          this.router.url === '/home/users';
      }
    });
  }
}
