import { Routes } from '@angular/router';
import { activeUserGuard } from '../core/guards/active-user/active-user.guard';
import { checkAgentGuard } from '../core/guards/agents/check-agent.guard';
import { tokenAuthenticationIsValidGuard } from '../core/guards/password/token-autentication-is-valid.guard';
import { IsAuthenticatedGuard } from '../core/guards/section/is-Authenticated/is-authenticated.guard';
import { preserveSectionGuard } from '../core/guards/section/preserve-section/preserve-section.guard';
import { verifyQueryParamsGuard } from '../core/guards/verify-query-params/verify-query-params.guard';
import { ActiveUserComponent } from '../pages/auth/active-user/active-user.component';
import { ForgotPasswordComponent } from '../pages/auth/forgot-password/forgot-password.component';
import { AuthComponent } from '../pages/auth/login/auth.component';
import { ResetPasswordComponent } from '../pages/auth/reset-password/reset-password.component';
import { AgentsComponent } from '../pages/home/sub-pages/agents/agents.component';
import { AgentsDefaultComponent } from '../pages/home/sub-pages/agents/components/default/agents-default.component';
import { InstagramComponent } from '../pages/home/sub-pages/agents/components/instagram/instagram.component';
import { TelegramComponent } from '../pages/home/sub-pages/agents/components/telegram/telegram.component';
import { WhatsappComponent } from '../pages/home/sub-pages/agents/components/whatsapp/whatsapp.component';
import { ContactsComponent } from '../pages/home/sub-pages/contacts/contacts/contacts.component';
import { NewContactsComponent } from '../pages/home/sub-pages/contacts/new-contacts/new-contacts.component';
import { GroupsRulesComponent } from '../pages/home/sub-pages/rules/groups-rules/groups-rules.component';
import { RegisteredStoresComponent } from '../pages/home/sub-pages/stores/lojas-cadastradas/registered-stores.component';
import { CardComponent } from '../pages/home/sub-pages/stores/lojas/card.component';
import { ProfileComponent } from '../pages/home/sub-pages/profile/profile.component';
import { StoreMessagesComponent } from '../pages/home/sub-pages/store-messages/store-messages.component';
import { UsersComponent } from '../pages/home/sub-pages/users/users/users.component';
import { UserRulesComponent } from '../pages/home/sub-pages/users/user-rules/user-rules.component';
import { NewUserComponent } from '../pages/home/sub-pages/users/new-user/new-user.component';
import { RulesComponent } from '../pages/home/sub-pages/rules/rules/rules.component';
import { NewRuleComponent } from '../pages/home/sub-pages/rules/new-rule/new-rule.component';
import { MessagesComponent } from '../pages/home/sub-pages/messages/messages/messages.component';
import { NewMessageComponent } from '../pages/home/sub-pages/messages/new-message/new-message.component';
import { MessageTriggeringComponent } from '../pages/home/sub-pages/shoot/message-triggering/message-triggering.component';
import { NewTriggeringComponent } from '../pages/home/sub-pages/shoot/new-triggering/new-triggering.component';
import { MessagesStoresComponent } from '../pages/home/sub-pages/messages/messages-stores/messages-stores.component';
import { ShootImediateComponent } from '../pages/home/sub-pages/shoot/shoot-imediate/shoot-imediate.component';
import { StatusTriggeringComponent } from '../pages/home/sub-pages/shoot/status-triggering/status-triggering.component';
import { HomeComponent } from '../pages/home/home.component';
import { socketGuard } from '../core/guards/socket/socket.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [IsAuthenticatedGuard, socketGuard],
    children: [
      {
        path: 'lojas',
        component: CardComponent,
      },
      {
        path: 'lojas-cadastradas',
        component: RegisteredStoresComponent,
      },
      {
        path: 'agentes',
        component: AgentsComponent,
        children: [
          {
            path: 'whatsapp',
            component: WhatsappComponent,
            canActivate: [checkAgentGuard],
          },
          {
            path: 'telegram',
            component: TelegramComponent,
            canActivate: [checkAgentGuard],
          },
          {
            path: 'instagram',
            component: InstagramComponent,
            canActivate: [checkAgentGuard],
          },
          { path: 'default', component: AgentsDefaultComponent },
          { path: '', redirectTo: 'default', pathMatch: 'full' },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },

      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'user-rules',
        component: UserRulesComponent,
        canActivate: [verifyQueryParamsGuard],
      },
      {
        path: 'new-user',
        component: NewUserComponent,
      },
      {
        path: 'rules',
        component: RulesComponent,
      },
      {
        path: 'new-rule',
        component: NewRuleComponent,
      },
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'new-message',
        component: NewMessageComponent,
      },
      {
        path: 'message-imediate',
        component: ShootImediateComponent,
      },
      {
        path: 'message-triggering',
        component: MessageTriggeringComponent,
      },
      {
        path: 'status-triggering',
        component: StatusTriggeringComponent,
      },
      {
        path: 'new-triggering',
        component: NewTriggeringComponent,
      },
      {
        path: 'messages-stores',
        canActivate: [verifyQueryParamsGuard],
        component: MessagesStoresComponent,
      },
      {
        path: 'groups-rules',
        component: GroupsRulesComponent,
        canActivate: [verifyQueryParamsGuard],
      },

      {
        path: 'store-messages',
        component: StoreMessagesComponent,
        canActivate: [verifyQueryParamsGuard],
      },
      {
        path: 'contacts',
        component: ContactsComponent,
      },
      {
        path: 'new-contact',
        component: NewContactsComponent,
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [],
    children: [
      {
        path: 'login',
        component: AuthComponent,
      },
      {
        path: 'reset-password',
        component: ForgotPasswordComponent,
      },
      {
        // path: 'reset-password-token'
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        canActivate: [tokenAuthenticationIsValidGuard],
      },
      {
        path: 'active-user/:token',
        component: ActiveUserComponent,
        canActivate: [activeUserGuard],
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
