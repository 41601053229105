import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthResponse } from '../../types/auth.interface';
import { AgentsService } from '../agents/agents.service';
import { UseTokenService } from './use-token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: UseTokenService,
    private agents: AgentsService,
  ) {}

  private newHeader() {
    const token = this.token.getToken();
    const header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return header;
  }

  private HttpLoginRequest(
    email: string,
    password: string,
  ): Observable<AuthResponse> {
    const user = { email, password };
    return this.http.post<AuthResponse>(
      environment.users.auth.USERS_URL_LOGIN,
      user,
      {
        headers: this.newHeader(),
      },
    );
  }

  public login(username: string, password: string) {
    this.token.removeToken();
    return this.HttpLoginRequest(username, password).pipe(
      tap((res) => {
        if (res.success && res.data) {
          this.token.setRulesOfAuthentication(res.data, { state: 'local' });
          this.router.navigate(['/home']);
        }
      }),
    );
  }

  public sendMailForResetPassword(email: string) {
    return this.http.post<void>(
      `${environment.users.auth.password.SEND_RESET_TOKEN}/${email}`,
      { email },
    );
  }

  public resetPasswordByToken(password: string) {
    const token = this.router.routerState.snapshot.url.split('/').reverse()[0];

    return this.http.post<{
      success: boolean;
      message: string;
      data: string;
    }>(`${environment.users.auth.password.RESET_BY_TOKEN}/${token}`, {
      password,
    });
  }
}
