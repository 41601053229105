import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, forkJoin, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PostGroup } from '../../types/groups.interface';
import {
  Data,
  GetPermissionResponse,
  PermissionResponse,
} from '../../types/permissions.interface';
import { EventsService } from '../card-output/events.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
    private user: UserService,
  ) {}

  public getPermissions(): Observable<Data[]> {
    return this.http
      .get<PermissionResponse>(environment.permissions.BASE_URL)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((response) => response.data),
      );
  }

  public checkUserPermission() {
    this.user.getUserById(this.user.me.id).subscribe((user) => {});
  }

  public getPermissionsById(id: number): Observable<Data> {
    return this.http
      .get<GetPermissionResponse>(`${environment.permissions.BASE_URL}/${id}`)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((response) => response.data),
      );
  }

  public setPermission(userId: number, permissionIds: number[]) {
    // const post$ = permissionIds.map((id) =>
    return this.http
      .post<GetPermissionResponse>(
        `${environment.users.USERS_URL}/${userId}/permissions`,
        { permissions: permissionIds },
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((req) => req.data),
      );
    // );
    // return forkJoin(post$);
  }
}
