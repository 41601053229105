<div class="mt-6 border border-[#ccc] rounded-sm">
  <p class="text-sm font-bold mt-1 ml-1">Seleções:</p>

  <div class="mb-4">
    <div class="flex items-center justify-center gap-1 2xl:gap-20 mt-6">
      <div class="">
        <div class="flex gap-6 h-7">
          <app-select
            [search]="true"
            style="height: 0"
            placeholder="Wa Grupos"
            [enabled]="waSelected && !searchingWaData"
            (queryEmitter)="searchGroups($event, 'WA')"
          >
            <ul class="w-full h-[14rem] overflow-y-scroll">
              @for (group of waFilterGroups; track group.id) {
              <label
                [for]="group.id"
                class="block py-2 border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
              >
                <div class="flex flex-row ml-3">
                  <input
                    type="checkbox"
                    [name]="group.title"
                    [id]="group.id"
                    [checked]="waChatsForShoot.has(group.id)"
                    (change)="markChatForShoot(group.id, 'WA')"
                  />
                  <label [for]="group.id" class="ml-1">{{ group.title }}</label>
                </div>
              </label>
              }
            </ul>

            <div
              [ngClass]="{ 'animate-spin': searchingWaData }"
              (click)="getWaChats()"
              class="absolute flex items-center z-50 ml-[10rem] text-[#9CA3AF] hover:text-black animate__animated animate__rotateIn"
            >
              <ng-icon size="22" name="faSolidArrowsRotate"></ng-icon>
            </div>
          </app-select>

          <app-select
            [search]="true"
            style="height: 0"
            placeholder="Tel Grupos"
            [enabled]="telSelected && !searchingTelData"
            (queryEmitter)="searchGroups($event, 'TEL')"
          >
            <ul class="w-full h-[14rem] overflow-y-scroll">
              @for (group of telFilterGroups; track group.id) {
              <label
                [for]="group.id"
                class="block py-2 border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
              >
                <div class="flex flex-row ml-3">
                  <input
                    type="checkbox"
                    [name]="group.title"
                    [id]="group.id"
                    [checked]="telChatsForShoot.has(group.id)"
                    (change)="markChatForShoot(group.id, 'TEL')"
                  />
                  <label [for]="group.id" class="ml-1">{{ group.title }}</label>
                </div>
              </label>
              }
            </ul>

            <div
              [ngClass]="{ 'animate-spin': searchingTelData }"
              (click)="getTelChats()"
              class="absolute flex items-center z-50 ml-[10rem] text-[#9CA3AF] hover:text-black animate__animated animate__rotateIn"
            >
              <ng-icon size="22" name="faSolidArrowsRotate"></ng-icon>
            </div>
          </app-select>
        </div>
      </div>

      <div class="flex gap-6 ml-8">
        <label
          class="flex gap-3 items-center transition-all duration-300 justify-center font-bold hover:cursor-pointer hover:bg-gray-300 p-2 rounded-lg"
        >
          <input
            type="checkbox"
            id="whatsapp"
            #wa
            (change)="selectWa()"
            class="disabled:text-gray-400"
          />
          Disparar para WhatsApp
        </label>
        <label
          class="flex gap-3 items-center transition-all duration-300 justify-center font-bold hover:cursor-pointer hover:bg-gray-300 p-2 rounded-lg"
        >
          <input
            type="checkbox"
            id="telegram"
            #tel
            (change)="selectTel()"
            class="disabled:text-gray-400"
          />
          Disparar para Telegram
        </label>
      </div>
    </div>
  </div>
</div>
