import { Component } from '@angular/core';
import { BannerComponent } from '../../../../../../shared/components/banner.component';

@Component({
  selector: 'app-instagram',
  standalone: true,
  imports: [BannerComponent],
  templateUrl: './instagram.component.html',
})
export class InstagramComponent {}
