import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { DownIconComponent } from '../../../../../shared/components/down-icon.component';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { VerifyFalseComponent } from '../../../../../shared/components/verify-false.component';
import { VerifyTrueComponent } from '../../../../../shared/components/verify-true.component';
import { Flowbite } from '../../../../../core/functions/flowbit.function';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { User } from '../../../../../core/types/user.interface';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    DownIconComponent,
    EditIconComponent,
    VerifyFalseComponent,
    VerifyTrueComponent,
    CdkDrag,
    DragDropModule,
    MiniDropdownComponent,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
})
@Flowbite()
export class UsersComponent implements OnInit, OnDestroy {
  constructor(
    private data: DataService,
    private user: UserService,
    private event: EventsService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {}

  @ViewChild('userInput', { static: false }) public reference!: ElementRef;
  @ViewChild('checkboxFather')
  public checkboxFather!: ElementRef<HTMLInputElement>;
  @ViewChildren('inputCheckbox') public inputCheckbox!: QueryList<ElementRef>;

  public users!: User[];

  protected userEdit = 0;

  protected username!: string;

  private statesOfUserRenamed = false;

  private usersSubscriptions$!: Subscription;

  private usersControllers = new Set<User>();

  public usersIdsControllers = new Set<number>();

  public actions = [
    {
      name: 'Cadastrar',
      action: () => {
        this.router.navigate(['/home', 'new-user']);
      },
      href: ['/home', 'users'],
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Habilitar',
      action: () => {
        this.enableUser();
      },
      href: ['/home', 'users'],
      color: '#28BD09',
    },
    {
      name: 'Desabilitar',
      action: () => {
        this.disableUser();
      },
      href: ['/home', 'users'],
      color: '#D13313',
    },
    {
      name: 'Remover',
      action: () => {
        this.deleteUser();
      },
      href: ['/home', 'users'],
      color: '#000',
      darkColor: '#fff',
    },
  ];

  ngOnInit() {
    this.data.changeData(
      'Usuários',
      'Crie, remova, altere opções de/e usuários.',
      true,
    );

    this.getUsers();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private getUsers() {
    this.usersSubscriptions$ = this.user.getUsers().subscribe((users) => {
      this.users = users;
    });
    this.cd.detectChanges();
  }

  private unsubscribe() {
    this.usersSubscriptions$.unsubscribe();
  }

  private clearControllers() {
    this.usersControllers.clear();
    this.usersIdsControllers.clear();
    this.checkboxFather.nativeElement.checked = false;
  }

  public displayInputOfUsername(user: User) {
    this.userEdit = user.id;
    this.username = user.name;
    requestAnimationFrame((frame) => {
      console.log(frame);
      this.reference.nativeElement.focus();
    });
  }

  public sendNewNameOfUser(name: string, user: User) {
    if (name === user.name) {
      this.userEdit = 0;
      return;
    }

    if (this.statesOfUserRenamed) return;

    this.statesOfUserRenamed = true;

    this.user.updateUser({ ...user, name }).subscribe(() => {
      this.userEdit = 0;
      this.statesOfUserRenamed = false;

      this.getUsers();
      this.cd.detectChanges();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Usuário alterado com sucesso!',
        event: 'success',
      });
    });
  }

  public selectUser(user: User) {
    if (this.usersControllers.has(user)) {
      this.usersControllers.delete(user);
      this.usersIdsControllers.delete(user.id);
      if (this.usersControllers.size !== this.users.length) {
        this.checkboxFather.nativeElement.checked = false;
      }
      return;
    }
    this.usersControllers.add(user);
    this.usersIdsControllers.add(user.id);
    if (this.usersControllers.size === this.users.length) {
      this.checkboxFather.nativeElement.checked = true;
    }
  }

  public deleteUser() {
    if (this.usersControllers.size === 0) return;
    const users = Array.from(this.usersControllers);
    const observables = users.map((user) => this.user.deleteUser(user.id));
    console.log(users);

    forkJoin(observables).subscribe((user) => {
      if (user) {
        this.clearControllers();
        this.getUsers();

        this.event.emitEventForStoreSuccess({
          visible: true,
          message: 'Usuário(s) removido(s) com sucesso!',
          event: 'success',
        });
      }
    });
  }

  public enableUser() {
    if (this.usersControllers.size === 0) return;

    const users = Array.from(this.usersControllers);
    const observables = users.map((user) =>
      this.user.updateUser({ ...user, status: '1' }),
    );

    forkJoin(observables).subscribe(() => {
      this.getUsers();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Usuário(s) ativado(s) com sucesso!',
        event: 'success',
      });
    });
  }

  public disableUser() {
    if (this.usersControllers.size === 0) return;

    const users = Array.from(this.usersControllers);
    const observables = users.map((user) =>
      this.user.updateUser({ ...user, status: '0' }),
    );

    forkJoin(observables).subscribe(() => {
      this.getUsers();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Usuário(s) desativado(s) com sucesso!',
        event: 'success',
      });
    });
  }

  private markAllCheckboxHowChecked(): void {
    if (this.usersControllers.size === this.users.length) {
      for (const input of this.inputCheckbox) {
        input.nativeElement.checked = true;
      }
      return;
    }

    for (const input of this.inputCheckbox) {
      input.nativeElement.checked = false;
    }
  }

  public selectAllUsersOfTable() {
    if (this.usersControllers.size === this.users.length) {
      this.clearControllers();
      this.markAllCheckboxHowChecked();
      return;
    }
    for (const user of this.users) {
      if (this.usersControllers.has(user)) {
        continue;
      }
      this.selectUser(user);
      this.markAllCheckboxHowChecked();
    }
  }

  public navigateToEditRulesOfUsersSelected() {
    if (this.usersControllers.size === 0) {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Selecione ao menos um usuário para editar as regras!',
        event: 'error',
      });
      return;
    }

    this.router.navigate(['/home', 'user-rules'], {
      queryParams: { id: Array.from(this.usersIdsControllers).join(',') },
    });
  }
}
