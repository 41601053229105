import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { EMPTY, catchError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Session } from '../../types/section.interface';
import { TokenIsEmptyError } from './error/TokenIsEmpty.error';

@Injectable({
  providedIn: 'root',
})
export class UseTokenService {
  constructor(
    private cookies: SsrCookieService,
    private http: HttpClient,
    private router: Router,
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  public isAuthenticate() {
    return this.cookies.check('_AUTH_TOKEN');
  }

  public removeToken() {
    this.cookies.delete('_AUTH_TOKEN');
  }

  private setToken(token: string) {
    if (this.isAuthenticate()) this.removeToken();
    this.cookies.set('_AUTH_TOKEN', token, {
      expires: new Date(Date.now() + 86400000),
      secure: true,
    });
  }

  public getRulesOfAuthentication() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('session'))
        return sessionStorage.getItem('session');

      if (localStorage.getItem('session'))
        return localStorage.getItem('session');
    }
    return null;
  }

  public setRulesOfAuthentication(token: string, session: Session) {
    if (!token) return;
    if (isPlatformBrowser(this.platformId)) {
      if (!session || session.state === 'session') {
        sessionStorage.removeItem('session');
        sessionStorage.setItem('session', session.state);
        return;
      }
      localStorage.removeItem('session');
      localStorage.setItem('session', session.state);
    }
    this.setToken(token);
  }

  public getToken() {
    return this.cookies.get('_AUTH_TOKEN');
  }

  public getRulesOfAuthenticationByToken(token: string) {
    if (this.getToken() === token) return this.getRulesOfAuthentication();
    return null;
  }

  public verifyNewUserByToken(token: string) {
    if (!token) throw new TokenIsEmptyError();

    return this.http
      .post<boolean>(environment.core.auth.EMAIL_URL, { token })
      .pipe(
        catchError((err) => {
          this.router.navigate(['/auth/login']);
          return EMPTY;
        }),
      );
  }
}
