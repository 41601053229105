<div class="mt-6 border border-[#ccc] rounded-sm">
  <p class="text-sm font-bold mt-1 ml-1">Agendamento:</p>

  <div class="mb-4 w-full mx-4 flex">
    <div class="w-full m-4 flex items-center justify-start">
      <input
        type="datetime-local"
        name="scheduling"
        id="date-for-shoot"
        class="h-8 rounded-[0.3125rem] mt-2"
        (change)="pickDateTimeLocal($event)"
        [min]="min"
      />
    </div>
    <div class="w-full mr-5 flex flex-col gap-5 items-center">
      <div class="flex justify-center items-center font-semibold">
        <input
          type="checkbox"
          name="wa-secure"
          id="secure-wa-shoot"
          [(ngModel)]="secureShoot.wa"
        />
        <label for="secure-wa-shoot" class="ml-2"
          >Desabilitar disparo seguro para WhatsApp</label
        >
      </div>
      <div class="flex justify-center items-center font-semibold">
        <input
          type="checkbox"
          name="tel-secure"
          id="secure-tel-shoot"
          [(ngModel)]="secureShoot.tel"
        />
        <label for="secure-tel-shoot" class="ml-2"
          >Desabilitar disparo seguro para Telegram</label
        >
      </div>
    </div>
  </div>
</div>
