<nav
  class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
>
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start rtl:justify-end">
        <button
          (click)="sidebarExpand()"
          type="button"
          data-drawer-target="logo-sidebar"
          data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span class="sr-only">Open sidebar</span>
          <app-toggle-icon></app-toggle-icon>
        </button>

        <a routerLink="['/']" routerLinkActive="" class="flex ms-2 md:me-24">
          <img [src]="logoPath" class="h-10 me-3" alt="Easy Ofertas" />
          <span
            class="self-center text-xl font-semibold sm:text-lg whitespace-nowrap text-[#0069e5] dark:text-white"
            >Easy Ofertas</span
          >
        </a>
      </div>

      <div class="flex items-center relative">
        <div class="flex items-center ms-3">
          <div
            class="hidden sm:block text-[#00D2FF] font-bold text-1xl mr-4"
            *ngIf="data.name"
          >
            Olá, {{ data.name }}!
          </div>

          <div>
            <button
              type="button"
              class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              aria-expanded="false"
              data-dropdown-toggle="dropdown-user"
            >
              @defer {

              <app-photo [name]="data.name" [src]="data.photo"></app-photo>
              }

              <span class="sr-only">Open user menu</span>
            </button>
          </div>

          <div
            class="hidden z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
            id="dropdown-user"
          >
            <div class="px-4 py-3" role="none">
              <p
                class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                role="none"
                *ngIf="data.name"
              >
                {{ data.name }}
              </p>

              <p
                class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                role="none"
                *ngIf="data.email"
              >
                {{ data.email }}
              </p>
            </div>
            <ul class="py-1" role="none">
              <li>
                <a
                  [routerLink]="['/home', 'profile']"
                  [routerLinkActive]="'text-[#0073C1]'"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Perfil</a
                >
              </li>
              <li>
                <button
                  (click)="logout()"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                >
                  Sair
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<aside
  id="logo-sidebar"
  [ngClass]="{
    ' translate-x-0': !showSideBar,
    '-translate-x-full lg:translate-x-0': showSideBar
  }"
  class="fixed top-0 left-0 z-40 w-60 h-screen pt-24 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
  aria-label="Sidebar"
>
  <app-sidebar></app-sidebar>
</aside>
