import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tbody-td-table',
  standalone: true,
  imports: [],
  templateUrl: './tbody-td-table.component.html',
})
export class TbodyTdTableComponent {
  @Input() public type?: string;
  @Input() public scope?: string;
  @Output() public checkbox = new EventEmitter();
  public checkboxClick() {
    this.checkbox.emit();
  }
}
