import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { SpinnerComponent } from '../../../../../../../../shared/components/spinner.component';
import { Status } from '../../../../../../../../core/enums/agent-whatsapp-status.enum';
import { AgentsService } from '../../../../../../../../core/services/agents/agents.service';
import { SocketService } from '../../../../../../../../core/services/agents/socket/socket.service';
import { GetAgentStatus } from '../../../../../../../../core/types/agents.interface';

@Component({
  selector: 'app-disconnected',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './disconnected.component.html',
  styleUrl: './disconnected.component.css',
})
export class DisconnectedComponent implements OnInit {
  constructor(
    private agents: AgentsService,
    private socketService: SocketService,
  ) {}

  @Output() public onqrcode = new EventEmitter<string>();

  private _searchingConnection!: boolean;

  private _integrationExists!: boolean;

  private connectingInAgent = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.agents.actions
      .getStatusForAgent('whatsapp')
      .pipe(
        catchError(() => {
          this._integrationExists = false;
          return EMPTY;
        }),
      )
      .subscribe((int) => {
        if (int.success) {
          this._integrationExists = true;
        }
      });

    this.connectingInAgent.subscribe(() => {
      setTimeout(() => {
        this.connectingInAgent.next(false);
      }, 3000);
    });

    this.startConnectionWithWhatsapp();
  }

  private startConnectionWithWhatsapp() {
    if (this.socketService.connected) return;

    this.socketService.startWebsocket().on('conn', (req) => {
      this.socketService.connected = true;

      const response = JSON.parse(req.payload);
      this.agents.status.next(response.status);

      console.log(response);

      switch (response.status) {
        case 'connecting':
          if (response.qr) this.agents.qrcode.next(response.qr);
          break;

        case 'connected':
          this.agents.status.next('connected');
          return;

        case 'disconnected':
          console.warn('disconnected');
          break;
        default:
          break;
      }
    });
  }

  public fetchConnection() {
    this._searchingConnection = true;

    this.agents.actions
      .getStatus('whatsapp')
      .pipe(
        filter(
          (status) => status.success && status.data.status === 'disconnected',
        ),
        switchMap(() => this.agents.actions.createConnectionInWhatsapp()),
        tap(() => this.connectingInAgent.next(true)),
        take(1),
      )
      .subscribe(() => {
        this.startConnectionWithWhatsapp();
      });
  }

  public addIntegrationWithWhatsapp() {
    this.agents.actions.addAgent('WA').subscribe((wa) => {
      if (wa) {
        this._integrationExists = true;
      }
    });
  }

  public get searchingConnection(): boolean {
    return this._searchingConnection;
  }

  public get integrationExists(): boolean {
    return this._integrationExists;
  }
}
