import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  map,
  Observable,
  switchMap,
  from,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Data,
  DeleteMessageResponse,
  IPutStoreInMessage,
  ImageResponse,
  MessageResponse,
  PostDataResponse,
  PostMessage,
  PostMessageResponse,
  PutMessageRequest,
  PutMessageResponse,
} from '../../types/message.interface';
import { UseTokenService } from '../auth/use-token.service';
import { EventsService } from '../card-output/events.service';
import {
  ShootMediaBody,
  ShootMediaResponse,
} from '../../types/agents.interface';
import { DataService } from '../card-output/data-service.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
    private data: DataService
  ) {}

  private imgBase64 = new BehaviorSubject<string>('');
  private imageFileSelected!: File;

  public set addFile(file: File) {
    this.imageFileSelected = file;
  }

  public get getFile(): File {
    return this.imageFileSelected;
  }

  public updateImageFileForPost(file: File) {
    this.imageFileSelected = file;
  }

  public getImageString() {
    return this.imgBase64;
  }

  public set imageString(img: string) {
    this.imgBase64.next(img);
  }

  public postMessage(message: PostMessage): Observable<PostDataResponse> {
    return this.http
      .post<PostMessageResponse>(environment.message.BASE_URL, message)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });

          return EMPTY;
        }),
        map((msg) => msg.data)
      );
  }

  public updateMessage(
    message: Data | IPutStoreInMessage
  ): Observable<PutMessageResponse> {
    return this.http
      .put<PutMessageRequest>(
        `${environment.message.BASE_URL}/${message.id}`,
        message
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((msg) => msg.data)
      );
  }

  public getMessage(): Observable<Data[]> {
    return this.http
      .get<MessageResponse>(environment.message.BASE_URL)
      .pipe(map((msg) => msg.data));
  }

  public deleteMessage(id: number): Observable<DeleteMessageResponse> {
    return this.http.delete<DeleteMessageResponse>(
      `${environment.message.BASE_URL}/${id}`
    );
  }

  public addImageForMessage(id: number, image: File) {
    const api_url = `${environment.message.BASE_URL}/${id}/image`;
    return from(this.data.readFileAsDataURL(image)).pipe(
      switchMap((fileDataURL: string) => {
        return this.http.post(api_url, {
          image_base64: fileDataURL,
        });
      }),
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      })
    );
  }
}
