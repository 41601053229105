import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SpinnerComponent } from '../../../shared/components/spinner.component';
import { MustMatch } from '../../../core/functions/mustMatch';
import { AuthService } from '../../../core/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SpinnerComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
})
export class ResetPasswordComponent {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  public resetPasswordForm!: FormGroup;

  public spinner = false;

  ngOnInit() {
    this.resetPasswordForm = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(environment.core.auth.password),
          Validators.minLength(8),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      { validators: MustMatch('password', 'confirmPassword') },
    );
  }

  public resetPassword() {
    if (!this.resetPasswordForm.valid) return;
    this.spinner = true;

    const password = this.resetPasswordForm.get('password')?.value;

    if (password) {
      this.auth.resetPasswordByToken(password).subscribe({
        next: (req) => {
          if (req.success) {
            this.router.navigate(['/auth/login']);
          }
        },
        error: (err) => {},
        complete: () => {
          this.spinner = false;
        },
      });
    }
  }
}
