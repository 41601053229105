import { Component } from '@angular/core';
import { TheadTableComponent } from '../thead/thead-table/thead-table.component';
import { TheadTdTableComponent } from '../thead/thead-td-table/thead-td-table.component';

@Component({
  selector: 'app-table-container',
  standalone: true,
  templateUrl: './table-container.component.html',
  imports: [
    TheadTableComponent,
    TheadTdTableComponent,

  ],
})
export class TableContainerComponent {}
