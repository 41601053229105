import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { GroupsService } from '../../../../../core/services/groups/groups.service';
import { UserService } from '../../../../../core/services/user/user.service';
import {
  GroupsData,
  Permission,
} from '../../../../../core/types/groups.interface';
import { NewUser } from '../../../../../core/types/user.interface';
import { SpinnerComponent } from '../../../../../shared/components/spinner.component';
import { SelectComponent } from '../../../../../shared/components/select.component';

@Component({
  selector: 'app-new-user',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    SpinnerComponent,
  ],
  templateUrl: './new-user.component.html',
  styleUrl: './new-user.component.css',
})
export class NewUserComponent implements OnInit {
  constructor(
    private data: DataService,
    private user: UserService,
    private event: EventsService,
    private group: GroupsService,
  ) {}

  @ViewChild(SelectComponent) private selectComponent!: SelectComponent;

  private inputPasswordState = new BehaviorSubject<boolean>(true);
  private policySelected = new BehaviorSubject<{
    permissions: number[];
    name: 'standard' | 'admin';
  }>({ permissions: [], name: 'standard' });

  public form!: FormGroup;
  public spinner = false;

  protected polices!: GroupsData[];

  ngOnInit(): void {
    this.data.changeData(
      'Usuários',
      'Crie, remova, altere opções de/e usuários.',
      true,
    );

    this.getRules();

    this.generateForm();

    this.inputPasswordState.subscribe((state) => {
      if (!state) {
        this.form.setValue({
          ...this.form.value,
          password: this.user.getRandomPassword(),
        });
        return;
      }
      this.form.setValue({
        ...this.form.value,
        password: '',
      });
    });

    this.policySelected.subscribe((val) => {
      if (this.selectComponent) {
        this.selectComponent.close();

        this.selectComponent.selected = val.name || 'Política';
      }
    });
  }

  private getRules() {
    this.group.getRules().subscribe((rules) => {
      this.polices = rules;
    });
  }

  private generateForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(6)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.minLength(8),
        Validators.pattern(environment.core.auth.password),
      ]),
      photo: new FormControl('12345678'),
      notify_by_email: new FormControl(false),
      generate_password: new FormControl(false),
    });
  }

  public changePassword() {
    if (this.inputPasswordState.value) {
      this.form.get('password')?.disable();
      this.inputPasswordState.next(false);
      return;
    }
    this.form.get('password')?.enable();
    this.inputPasswordState.next(true);
  }

  public setRole(roles: Permission[], name: string) {
    this.policySelected.next({
      permissions: roles.map((r) => r.id),
      name: name ? 'admin' : 'standard',
    });
  }

  public createUser() {
    if (this.form.invalid || this.form.get('password')?.value === '') {
      return;
    }

    this.spinner = true;

    const user: NewUser = {
      name: this.form.get('name')?.value,
      email: this.form.get('email')?.value,
      password: this.form.get('password')?.value,
      role: this.policySelected.value.name || 'Visualizar',
      photo: this.form.get('photo')?.value || '12345678',
      notify_by_email: this.form.get('notify_by_email')?.value,
      generate_password: this.form.get('generate_password')?.value,
    };

    this.user
      .createUser(user)
      .subscribe((user) => {
        if (user) {
          this.group
            .setRulesGroupForUser(
              user.id,
              this.policySelected.value.permissions,
            )
            .subscribe(() => {
              this.form.reset();
              this.inputPasswordState.next(true);
              this.policySelected.next({
                permissions: [],
                name: 'standard',
              });
            });
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: 'Usuário criado com sucesso!',
            event: 'success',
          });
        }
      })
      .add(() => {
        this.spinner = false;
      });
  }
}
