import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DownIconComponent } from './down-icon.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [DownIconComponent, NgIf, NgFor, NgClass, FormsModule],
  template: `
    <div
      [ngClass]="{ 'select-none text-gray-400 border-gray-300': !this.enabled }"
      class="focus:outline-none flex flex-row justify-center text-center text-sm mx-1 mr-4 w-56 3xl:w-64 h-7 dark:bg-gray-800 dark:border-[#545B64] border border-[#545B64]"
    >
      <div
        (click)="open()"
        class="flex flex-row justify-center text-center items-center w-full"
      >
        <button
          type="button"
          class="w-full flex flex-row gap-4 items-center justify-center text-[#545B64]"
        >
          {{ selected || placeholder }}
          <app-down-icon></app-down-icon>
        </button>

        <ng-content select="div"></ng-content>
      </div>

      <div
        *ngIf="isOpen"
        class="z-10 bg-white divide-y divide-gray-400 shadow border border-[#545B64] dark:bg-gray-700 dark:divide-gray-600 absolute w-56 3xl:w-64 mt-[1.69rem]"
      >
        <div *ngIf="search">
          <input
            type="search"
            name=""
            id=""
            placeholder="buscar grupos"
            [(ngModel)]="querySearch"
          />
          <button
            class="w-full bg-blue-600 text-white text-xl transition-all duration-300 hover:bg-blue-900"
            (click)="sendNewQuery()"
          >
            buscar
          </button>
        </div>

        <ul
          class="py-1 text-sm dark:text-gray-200 flex flex-col items-center justify-center w-full"
        >
          @for (option of options; track $index) {

          <li class="w-full">
            <button
              type="button"
              (click)="selected = option.value; emitChange()"
              class="block w-full font-normal cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
            >
              {{ option.label }}
            </button>
          </li>

          } @empty {
          <ng-content select="ul"></ng-content>
          }
        </ul>
      </div>
    </div>
  `,
})
export class SelectComponent implements OnInit {
  @Input() options!: Options[];
  @Input() placeholder = 'Selecione';
  @Input() enabled = true;
  @Input() search: boolean = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() queryEmitter = new EventEmitter<string>();
  protected querySearch: string = '';

  private _selected = '';

  protected isOpen = false;

  ngOnInit(): void {}

  public sendNewQuery() {
    this.queryEmitter.emit(this.querySearch);
  }
  public open(): void {
    if (this.enabled) this.isOpen = !this.isOpen;
  }

  public close(): void {
    this.isOpen = false;
  }

  public emitChange(): void {
    if (!this.enabled) return;
    this.valueChange.emit(this.selected);
    this.isOpen = false;
  }

  public get selected() {
    return this._selected;
  }

  public set selected(value) {
    this._selected = value;
  }
}

type Options = { label: string; value: string };
