import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-true',
  standalone: true,
  imports: [CommonModule],
  template: ` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 25 25"
    fill="none"
  >
    <circle
      cx="12.5"
      cy="12.5"
      r="11"
      fill="white"
      stroke="#28BD09"
      stroke-width="3"
    />
    <line
      x1="7.81457"
      y1="11.4199"
      x2="12.0873"
      y2="17.4199"
      stroke="#28BD09"
      stroke-width="2"
    />
    <line
      x1="11.1425"
      y1="17.4855"
      x2="17.1425"
      y2="7.4855"
      stroke="#28BD09"
      stroke-width="2"
    />
  </svg>`,
})
export class VerifyTrueComponent {}
