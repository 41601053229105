export enum WhatsappStatus {
  STARTING = 0,
  STARTED = 1,
  LOADING = 2,
  ONLINE = 3,
  OFFLINE = 4,
  CONNECTING = 5,
  DISCONNECTED = 6,
  AWAY = 7,
  BUSY = 8,
  IDLE = 9,
  ERROR = 10,
}

export type Status = 'connected' | 'disconnected' | 'connecting';
