<div *ngIf="!load">
  <div *ngIf="!agentExists" class="w-screen flex flex-col justify-center items-center text-lg">
    <div class="mt-12 w-1/2">
      <h1 class="ml-32 text-3xl text-red-700 font-medium">Desconectado</h1>
      <div class="flex flex-col items-center justify-center pb-3 border border-[#ECEEEE] bg-[#f2f3f3]">
        <div class="w-full">
          <div class="ml-12 mt-2">
            <h1 class="text-2xl font-bold">
              Oops, não existe agente para seu usuário!
            </h1>
            <p class="font-medium">Deseja criar um novo agente?</p>
          </div>
        </div>
        <div class="mt-12">
          @if (!searchingConnection) {
          <button
            class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] text-white rounded-lg py-2 px-24"
            (click)="createNewAgent()">
            Criar agente
          </button>
          } @else {

          <div>
            <app-spinner></app-spinner>

          </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="agent"
    class=" w-[50%]  flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg animate__animated animate__fadeIn m-auto mt-10">
    <h2 class="text-2xl font-bold mb-4 text-center text-blue-900">Você já possui agentes, acesse suas integrações</h2>
    <img src="assets/imagens/icons/agent.jpg" alt="Agente" class="w-40 h-40 rounded-full mb-4 shadow-md">
    <p>As integrações dos agentes com plataformas como <a routerLink="/home/agentes/telegram"
      class=" text-blue-700 hover:text-blue-500 transition duration-300 ease-in-out">Telegram</a> e <a routerLink="/home/agentes/whatsapp"
      class=" text-green-700 hover:text-green-500 transition duration-300 ease-in-out">WhatsApp</a> requerem autenticação para garantir que as
      ofertas sejam enviadas de forma segura e confiável. É necessário conectar sua conta dessas plataformas para
      permitir que o sistema envie ofertas diretamente aos seus clientes. A autenticação garante que apenas usuários
      autorizados possam acessar e utilizar as funcionalidades de envio de ofertas, protegendo a integridade e a
      segurança das suas comunicações.</p>
   
  </div>
</div>
<div *ngIf="load">
<img class="mt-4 rounded-full" src="https://i.pinimg.com/originals/1c/13/f3/1c13f3fe7a6bba370007aea254e195e3.gif" alt="">
</div>