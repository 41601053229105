import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { EMPTY, Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { UseTokenService } from '../../../services/auth/use-token.service';
import { UserService } from '../../../services/user/user.service';
import { UserMe, UserMeRequest } from '../../../types/user.interface';
import { SocketService } from '../../../services/agents/socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private token: UseTokenService,
    private router: Router,
    private http: HttpClient,
    private user: UserService,
    private socket: SocketService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    if (!this.token.isAuthenticate()) this.router.navigate(['/auth']);

    return this.http.get<UserMeRequest>(environment.users.USERS_URL_ME).pipe(
      map((user) => {
        this.user.me = user.data;
        this.socket.setBotIdCookie(user.data.agentId);
        return true;
      }), // Se a solicitação for bem-sucedida, retorna true
      catchError(() => {
        this.router.navigate(['/auth']);
        return of(false); // Se a solicitação falhar, retorna false
      }),
    );
  }
}
