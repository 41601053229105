import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BannerComponent } from '../../../../../../../shared/components/banner.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-scheduling',
  standalone: true,
  imports: [CommonModule, BannerComponent],
  templateUrl: './scheduling.component.html',
})
export class SchedulingComponent {
  @ViewChild('firedImmediate')
  private firedImmediate!: ElementRef<HTMLInputElement>;
  @ViewChildren('weekDaysCheck')
  private weekDaysCheck!: QueryList<ElementRef<HTMLInputElement>>;

  private anyValueInDayIsChecked = new BehaviorSubject<boolean>(false);
  private dateSpecificValue = new BehaviorSubject<boolean>(false);
  private firedImmediateIsCheck = new BehaviorSubject<boolean>(false);
  private checkboxRecurrence = new BehaviorSubject<boolean>(false);
  private time = '';
  private dateInput = '';

  protected firedImmediateIsEnabled = true;
  protected schedulingNotImplemented = false;
  protected dateEnabled = false;
  protected triggeringFormObject!: FormGroup;

  protected readonly date = new Date()
    .toLocaleDateString()
    .split('/')
    .reverse()
    .join('-');

  protected weekDays = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  ngOnInit(): void {
    this.generateFormGroup();
  }

  private generateFormGroup() {
    this.triggeringFormObject = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      recurrence: new FormControl({ value: false, disabled: false }),
      inDate: new FormControl({
        disabled: this.anyValueInDayIsChecked.value,
      }),
      date: new FormControl({ value: '', disabled: this.dateEnabled }),
      isDraft: new FormControl(),
      immediate: new FormControl(),
    });
  }

  protected getStatusOfWeekDaysCheckbox() {
    return this.dateSpecificValue.value;
  }

  protected setFiredImmediate(value: boolean) {
    this.firedImmediateIsCheck.next(value);
  }

  protected markRecurrence(checked: boolean) {
    this.checkboxRecurrence.next(checked);

    for (const day of this.weekDaysCheck) {
      day.nativeElement.checked = false;
    }
    if (checked) {
      this.firedImmediateIsEnabled = false;
      this.firedImmediate.nativeElement.checked = false;
      return;
    }
    this.firedImmediateIsEnabled = true;
  }

  protected pickTime(time: Event) {
    const target = time.target as HTMLInputElement;
    this.time = target.value;
  }

  protected pickDate(date: Event) {
    const target = date.target as HTMLInputElement;
    this.dateInput = target.value;
  }

  public getDate() {
    const dateTime = new Date(`${this.dateInput}T${this.time}:00`);
    return dateTime;
  }

  public setIfAnyValueInDayIsChecked(): void {
    const arrayDates: boolean[] = this.triggeringFormObject.get('days')?.value;
    for (const date of arrayDates) {
      if (date) this.anyValueInDayIsChecked.next(true);
    }
    this.anyValueInDayIsChecked.next(false);
  }

  protected markDateSpecificAtValue(value: boolean) {
    this.dateSpecificValue.next(value);
    if (value) {
      this.firedImmediateIsEnabled = false;
      this.firedImmediate.nativeElement.checked = false;
      return;
    }
    this.firedImmediateIsEnabled = true;
  }

  public get isRecurrence() {
    return this.checkboxRecurrence.value;
  }

  public get isSpecificDay() {
    return this.dateSpecificValue.value;
  }

  public get isImmediate() {
    return this.firedImmediateIsCheck.value;
  }

  public get days() {
    return this.triggeringFormObject.get('days') as FormArray<FormGroup>;
  }
}
