import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';

interface QueryParams {
  id: string;
}

export const verifyQueryParamsGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const locate = route.routeConfig?.path;

  const queryParams = route.queryParams;
  if (!queryParams) router.navigate(['/home']);

  const { id } = queryParams as QueryParams;

  if (!id) {
    locate === 'user-rules'
      ? router.navigate(['/home', 'users'])
      : locate === 'groups-rules'
        ? router.navigate(['/home', 'rules'])
        : locate === 'messages-stores'
          ? router.navigate(['/home', 'messages'])
          : locate === 'store-messages'
            ? router.navigate(['/home', 'messages'])
            : '';
  }

  return true;
};
