import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, forkJoin, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Data,
  GroupRequest,
  GroupsRequest,
  PostGroup,
} from '../../types/groups.interface';
import { PermissionsService } from '../permissions/permissions.service';
import { EventsService } from './../card-output/events.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
    private permission: PermissionsService,
  ) {}

  private url = environment.permissions.groups.BASE_URL;

  public createRulesGroup(rule: PostGroup) {
    return this.http.post<Data>(this.url, rule);
  }

  public getRules(): Observable<Data[]> {
    return this.http.get<GroupsRequest>(this.url).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
      map((req) => req.data),
    );
  }

  public deleteGroups(id: number) {
    return this.http.delete(`${this.url}/${id}`).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
    );
  }

  public getGroupsById(id: number): Observable<Data> {
    return this.http.get<GroupRequest>(`${this.url}/${id}`).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
      map((req) => req.data),
    );
  }

  public setRulesGroupForUser(user: number, rules: number[]) {
    const obs$ = rules.map((id) => this.permission.setPermission(user, rules));

    return forkJoin(obs$).pipe(
      catchError((err: HttpErrorResponse) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
    );
  }
}
