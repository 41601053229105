import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { Subscription, forkJoin } from 'rxjs';
import { DownIconComponent } from '../../../../../shared/components/down-icon.component';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { SelectComponent } from '../../../../../shared/components/select.component';
import { VerifyFalseComponent } from '../../../../../shared/components/verify-false.component';
import { VerifyTrueComponent } from '../../../../../shared/components/verify-true.component';
import { dateTransform } from '../../../../../core/functions/dateTransform.function';
import { Flowbite } from '../../../../../core/functions/flowbit.function';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { PagesService } from '../../../../../core/services/shop/pagination/pages.service';
import { StoreService } from '../../../../../core/services/shop/store.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { Data, StoreForModal } from '../../../../../core/types/store.interface';
import { ModalComponent } from './components/modal/modal.component';

@Component({
  selector: 'app-registered-stores',
  standalone: true,
  imports: [
    CommonModule,
    VerifyTrueComponent,
    VerifyFalseComponent,
    EditIconComponent,
    RouterLink,
    DownIconComponent,
    ModalComponent,
    CdkDrag,
    DragDropModule,
    MiniDropdownComponent,
    SelectComponent,
    FormsModule,
  ],
  templateUrl: './registered-stores.component.html',
  styleUrl: './registered-stores.component.css',
})
@Flowbite()
export class RegisteredStoresComponent implements OnInit {
  constructor(
    private store: StoreService,
    private dataService: DataService,
    private event: EventsService,
    private pages: PagesService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private user: UserService,
  ) {}

  @ViewChild('storeInput', { static: false }) public reference!: ElementRef;
  @ViewChild('checkboxFather')
  public checkboxFather!: ElementRef<HTMLInputElement>;
  @ViewChildren('inputCheckbox') public inputCheckbox!: QueryList<ElementRef>;

  private subscription!: Subscription;

  public actions = [
    {
      name: 'Cadastrar',
      action: () => {
        this.router.navigate(['/home', 'lojas']);
      },
      href: ['/home', 'lojas'],
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Habilitar',
      action: () => {
        this.enableStore();
      },
      href: ['/home', 'lojas-cadastradas'],
      color: '#28BD09',
    },
    {
      name: 'Desabilitar',
      action: () => {
        this.disableStore();
      },
      href: ['/home', 'lojas-cadastradas'],
      color: '#D13313',
    },
    {
      name: 'Copiar',
      action: () => {
        this.copyStore();
      },
      href: ['/home', 'lojas-cadastradas'],
      color: '#000',
      darkColor: '#fff',
    },
  ];

  public viewModalActive = false;
  public dropdownButtonAction = false;
  public dropdown = false;
  private statesOfStoreRenamed = false;

  public stores!: Data[];
  public currentPage = 1;

  public itemsPerPage = 0;
  public totalStores = 0;

  public storename!: string;
  public storeEdit = 0;

  public arrayOfPages!: number[];

  public controlItems = new Set<number>();
  public storesIdControllers = new Set<number>();

  public model: StoreForModal = {
    status: 1,
    name: '',
    tags: [],
    users: 0,
  };

  ngOnInit(): void {
    this.getStores();
    this.getTotalPages();

    this.dataService.changeData(
      'Lojas',
      'Descubra, cadastre e gerencie suas lojas nesta tela. Use esta tela para organizar suas lojas.',
      true,
    );
  }

  ngAfterContentInit() {
    initFlowbite();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private parseTypeDataToObservable(store: Data) {
    const newStore: StoreForModal = {
      id: store.id,
      status: store.status,
      name: store.name,
      tags: store.tags.map((tag) => tag.name),
      users: [store.users[0] ? store.users[0].user.id : this.user.me.id],
    };

    return this.store.httpStorePut(newStore);
  }

  private getTotalPages() {
    this.pages.getTotalPages().subscribe((total) => {
      this.totalStores = total.data;
      this.arrayOfPages = Array.from(
        { length: Math.ceil(this.totalStores / 10) },
        (_, i) => i + 1,
      );
    });
  }

  public getStores() {
    this.subscription = this.store.httpStoreGet().subscribe((store) => {
      this.stores = store.data.map<Data>((store) => ({
        ...store,
        created_at: dateTransform(store.created_at),
      }));
      this.cd.detectChanges();
    });
  }

  public toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  public toggleDrop() {
    this.dropdownButtonAction = !this.dropdownButtonAction;
  }

  public deleteStore(): void {
    if (this.controlItems.size === 0) return;
    const stores = this.stores.filter((store) =>
      this.controlItems.has(store.id),
    );
    const observables = stores.map((store) =>
      this.store.httpStoreDelete(store.id),
    );

    forkJoin(observables).subscribe(() => {
      this.getStores();
      this.getTotalPages();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) deletada(s) com sucesso!',
        event: 'success',
      });
    });
  }

  public disableStore() {
    if (this.controlItems.size === 0) return;

    const stores = this.stores.filter((store) =>
      this.controlItems.has(store.id),
    );
    const observables = stores.map((store) =>
      this.parseTypeDataToObservable({ ...store, status: 0 }),
    );

    forkJoin(observables).subscribe(() => {
      this.getStores();
      this.getTotalPages();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) desativada(s) com sucesso!',
        event: 'success',
      });
    });
  }

  public enableStore() {
    if (this.controlItems.size === 0) return;

    const stores = this.stores.filter((store) =>
      this.controlItems.has(store.id),
    );
    const observables = stores.map((store) =>
      this.parseTypeDataToObservable({ ...store, status: 1 }),
    );

    forkJoin(observables).subscribe(() => {
      this.getStores();
      this.getTotalPages();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) ativada(s) com sucesso!',
        event: 'success',
      });
    });
  }

  public copyStore() {
    if (this.controlItems.size === 0) return;

    const stores = this.stores.filter((store) =>
      this.controlItems.has(store.id),
    );

    console.log(stores);

    const observables = stores.map((store) =>
      this.store.addStore({
        ...store,
        tags: store.tags.map((tag) => tag.name),
        users: store.users.map((usr) => usr.user.id),
      }),
    );

    forkJoin(observables).subscribe(() => {
      this.getStores();
      this.getTotalPages();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) copiada com sucesso!',
        event: 'success',
      });
    });
  }

  public getPreviusPage() {
    if (this.currentPage === 1) return;
    this.currentPage--;
    this.pages.setPage(this.currentPage);
    this.getStores();
  }

  public navigateToPage(page: number) {
    this.currentPage = page;
    this.pages.setPage(page);
    this.getStores();
  }

  public getNextPage() {
    if (this.currentPage === this.arrayOfPages.length) return;
    this.currentPage++;
    this.pages.setPage(this.currentPage);
    this.getStores();
  }

  public displayInputOfStorename(store: Data) {
    this.storename = store.name;
    this.storeEdit = store.id;
    requestAnimationFrame(() => {
      this.reference.nativeElement.focus();
    });
  }

  public sendNewNameOfStore(name: string, store: Data) {
    if (name === store.name) {
      this.storeEdit = 0;
      return;
    }

    if (this.statesOfStoreRenamed) return;

    this.statesOfStoreRenamed = true;

    const storeRenamed = {
      id: store.id,
      status: store.status,
      name,
      tags: store.tags.map((tag) => tag.name),
      users: [this.user.me.id] ?? [0],
    };

    this.store.httpStorePut(storeRenamed).subscribe(() => {
      this.storeEdit = 0;
      this.statesOfStoreRenamed = false;
      this.getStores();
      this.cd.detectChanges();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja alterada com sucesso!',
        event: 'success',
      });
    });
  }

  public selectStore(id: number) {
    if (this.controlItems.has(id)) {
      this.controlItems.delete(id);
      this.storesIdControllers.delete(id);
      if (this.controlItems.size !== this.stores.length) {
        this.checkboxFather.nativeElement.checked = false;
      }
      return;
    }

    this.controlItems.add(id);
    this.storesIdControllers.add(id);
    if (this.controlItems.size === this.stores.length) {
      this.checkboxFather.nativeElement.checked = true;
    }
  }

  private markAllCheckboxHowChecked(): void {
    if (this.controlItems.size === this.stores.length) {
      for (const input of this.inputCheckbox) {
        input.nativeElement.checked = true;
      }
      return;
    }

    for (const input of this.inputCheckbox) {
      input.nativeElement.checked = false;
    }
  }

  private clearControllers(): void {
    this.controlItems.clear();
    this.storesIdControllers.clear();
    this.checkboxFather.nativeElement.checked = false;
  }

  public selectAllstoresOfTable() {
    if (this.controlItems.size === this.stores.length) {
      this.clearControllers();
      this.markAllCheckboxHowChecked();
      return;
    }
    for (const store of this.stores) {
      if (this.controlItems.has(store.id)) {
        continue;
      }
      this.selectStore(store.id);
      this.markAllCheckboxHowChecked();
    }
  }

  public navigateToEditStoresOfMessages() {
    if (this.storesIdControllers.size === 0) {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Selecione ao menos uma loja para editar as mensagens!',
        event: 'error',
      });
      return;
    }

    this.router.navigate(['/home', 'store-messages'], {
      queryParams: {
        id: Array.from(this.storesIdControllers).join(','),
      },
    });
  }
}
