import { Component } from '@angular/core';
import { Subscription, combineLatest, forkJoin, map } from 'rxjs';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { MessageService } from '../../../../../core/services/message/message.service';
import { StoreService } from '../../../../../core/services/shop/store.service';
import {
  IMessage,
  IMessageRequest,
} from '../../../../../core/types/message.interface';

@Component({
  selector: 'app-messages-stores',
  standalone: true,
  imports: [],
  templateUrl: './messages-stores.component.html',
  styleUrl: './messages-stores.component.css',
})
export class MessagesStoresComponent {
  constructor(
    private data: DataService,
    private message: MessageService,
    private store: StoreService,
    private event: EventsService,
  ) {}

  private messagesForPutController = new Array<IMessageRequest>();

  private urlSegmentQuery = new URLSearchParams(window.location.search).get(
    'id',
  );

  private subscription!: Subscription;

  protected messages!: IMessageRequest[];

  protected stores!: { name: string; id: number }[];

  ngOnInit(): void {
    this.getMessagesByUrlSegmentId();
    this.getStores();

    this.data.changeData(
      'Mensagens associadas',
      'Manipule aqui as lojas associadas.',
      true,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getMessagesByUrlSegmentId(): void {
    if (!this.urlSegmentQuery) {
      return;
    }
    const messages$ = this.urlSegmentQuery
      .split(',' || '')
      .map((id) =>
        this.message
          .getMessage()
          .pipe(map((msg) => msg.filter((m) => m.id === Number.parseInt(id)))),
      );

    this.subscription = combineLatest(messages$)
      .pipe()
      .subscribe((messages) => {
        this.messages = messages.flat().map((msg) => ({
          ...msg,
          stores: msg.stores.map((s) => ({
            id: s.store.id,
            name: s.store.name,
          })),
        }));
      });
  }

  private getStores() {
    this.store.getAllStores().subscribe((req) => {
      this.stores = req.map((store) => ({ name: store.name, id: store.id }));
    });
  }

  public storeIncludesInMessage(
    message: IMessageRequest,
    store: { name: string; id: number },
  ) {
    const stores = message.stores.map((store) => store.id);
    return stores.includes(store.id);
  }

  public selectStore(
    store: { name: string; id: number },
    message: IMessageRequest,
  ) {
    const findMessageInController = this.messagesForPutController.find(
      (msg) => msg.id === message.id,
    );

    if (findMessageInController) {
      const msgIndex = this.messagesForPutController.findIndex(
        (msg) => msg.id === message.id,
      );
      this.messagesForPutController.splice(msgIndex, 1);
    }
    const cloneMsg = Object.assign({}, message);

    const storeInMessage = cloneMsg.stores.find((st) => st.id === store.id);

    if (storeInMessage) {
      const storeIndex = cloneMsg.stores.findIndex((st) => st.id === store.id);
      cloneMsg.stores.splice(storeIndex, 1);
      this.messagesForPutController.push(cloneMsg);
      return;
    }

    cloneMsg.stores.push(store);
    this.messagesForPutController.push(cloneMsg);
  }

  public associateStore() {
    console.log(this.messagesForPutController);

    const messages = this.messagesForPutController.map((msg) => ({
      ...msg,
      stores: msg.stores.map((s) => s.id),
    }));

    const observables$ = messages.map((msg) =>
      this.message.updateMessage(msg as unknown as IMessage),
    );

    forkJoin(observables$).subscribe(() => {
      this.getMessagesByUrlSegmentId();
      this.getStores();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) associada(s) com sucesso!',
        event: 'success',
      });
    });
  }
}
