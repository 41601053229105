<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div
    class="shadow-md sm:rounded-lg dark:bg-gray-800 relative xl:max-w-full xl:w-max"
  >
    <div class="">
      <p class="md:mr-4 font-normal text-3xl ml-8">Contatos existentes</p>
    </div>

    <div class="w-min">
      <caption
        class="pl-5 pt-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row"
      >
        <div class="w-2/4">
          <h1 class="text-2xl sm:text-3xl">Contatos</h1>
          <p class="mt-1 text-sm font-normal text-[#16191F] dark:text-gray-400">
            Cadastre contatos para aumentar seu leque de clientes.
          </p>
        </div>
        <div
          class="lg:flex justify-end mr-2 hidden relative mt-4 right-0 w-full"
        >
          <app-mini-dropdown [items]="actions"></app-mini-dropdown>

          <a
            [routerLink]="['/home', 'new-contact']"
            class="font-bold dark:font-light h-8 w-48 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
          >
            Novo Contato
          </a>
        </div>
      </caption>
      <table
        class="w-max text-sm text-left overflow-auto rtl:text-right text-[#16191F] dark:text-gray-400 xl:min-w-[50rem] 2xl-w-[65rem] 3xl:w-[70rem]"
      >
        <thead
          class="text-xs text-[#16191F] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox-all-search"
                  #checkboxFather
                  (change)="selectAllContactsOfTable()"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" class="px-8 py-3">Nome</th>
            <th scope="col" class="px-8 py-3">Plataforma</th>
            <th scope="col" class="px-8 py-3">Criado em</th>
            <th scope="col" class="px-8 py-3">Criado por</th>
          </tr>
        </thead>
        <tbody>
          @for(contact of contacts; let i = $index; track contact.id) {
          <tr
            [ngClass]="{
              'bg-[#0073bb42] dark:bg-[#18345a] hover:bg-[#0073bb42] border-[#0073BB]':
                contactsIdControllers.has(contact.id),
              'hover:bg-[#cfdcdd] dark:bg-gray-800 dark:border-gray-700':
                !contactsIdControllers.has(contact.id)
            }"
            class="border-b border text-[#16191F] dark:bg-gray-800 dark:border-gray-700 hover:transition-all"
          >
            <td class="w-4 px-6">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox"
                  #inputCheckbox
                  (change)="selectContact(contact.id)"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox" class="sr-only">checkbox</label>
              </div>
            </td>
            <td
              scope="row"
              class="relative space-x-12 items-center group px-8 py-0.5 dark:text-white"
            >
              <input
                class="h-5 border-none bg-transparent"
                type="text"
                #contactInput
                [ngClass]="contact.id === contactEdit ? 'block' : 'hidden'"
                [(ngModel)]="contactName"
                (blur)="sendNewNameOfContact(contactInput.value, contact)"
                (keyup.enter)="
                  sendNewNameOfContact(contactInput.value, contact)
                "
              />

              <p *ngIf="contact.id !== contactEdit">{{ contact.name || "" }}</p>

              <div
                *ngIf="contact.id !== contactEdit"
                (click)="displayInputOfContactName(contact)"
              >
                <app-edit-icon
                  class="absolute ml-5 right-0 bottom-0 hidden group-hover:block hover:border-gray-400 hover:border z-50 mr-0 p-0"
                ></app-edit-icon>
              </div>
            </td>
            <td class="mx-1 px-6 flex flex-row gap-1 py-0.5 text-[#28BD09]]">
              {{ contact.platform }}
            </td>
            <td
              *ngIf="contact.created_at"
              class="mx-1 px-6 py-0.5 dark:text-white"
            >
              {{ contact.created_at }}
            </td>
            <td
              *ngIf="contact.created_by"
              class="mx-1 px-6 py-0.5 dark:text-white"
            >
              {{ contact.created_by.name || "Não informado" }}
            </td>
          </tr>
          } @empty {
          <tr class="xl:w-14">
            <td
              colspan="5"
              class="px-6 py-4 text-center dark:text-white hover:transition-all hover:ease-in-out hover:bg-[#cfdcdd]"
            >
              Nenhum contato cadastrado
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    <nav
      class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
      aria-label="Table navigation"
      *ngIf="contacts && contacts.length > 0"
    >
      <span
        class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto"
        >Exibindo
        <span class="font-semibold text-gray-900 dark:text-white">
          {{ currentPage }} - @if(contacts.length >= 10){10} @else{
          {{ contacts.length }} }
        </span>
        de
        <span class="font-semibold text-gray-900 dark:text-white">{{
          totalContacts
        }}</span></span
      >
      <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
        <li
          [ngClass]="[
            currentPage == 1 ? 'cursor-not-allowed' : 'cursor-pointer'
          ]"
        >
          <a
            (click)="('')"
            class="cursor-pointer flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >Anterior</a
          >
        </li>

        @for (page of arrayOfPages; track $index) {
        <li>
          <a
            (click)="('')"
            class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >{{ page }}</a
          >
        </li>
        }

        <li>
          <a
            (click)="('')"
            class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >Próximo</a
          >
        </li>
      </ul>
    </nav>
  </div>
</section>
