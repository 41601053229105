<div class="w-screen pb-48 2xl:h-screen" *ngIf="!load">
  <div *ngIf="telStatus=='disconnected'">
    <div class="flex flex-col justify-center items-center text-lg">
      <div class="w-4/5 mt-10">
        <p><span class="text-[#00558F] font-bold">Vamos autenticar com o Telegram?</span> Siga as seguintes etapas e
          instruções para criar o bot com Telegram para usá-lo no Easy System. <br> Primeiramente, vc precisa obter uma
          chave de API. Podemos obtê-la por criar um novo bot. No Telegram, fazemos isso
          iniciando uma
          conversa com um outro bot oficial do Telegram. Para obter a nova chave API, você deve abrir o Telegram, procurar
          por um usuário com o nome <strong>&#64;botfather</strong> e iniciar um diálogo com ele. Você deve enviar uma
          mensagem para o &#64;botfather com o
          texto /newbot para criar um novo bot:</p>
      </div>
      <div class="w-[22rem] h-[15rem] mt-5" id="botfather-img-1"></div>
      <div class="w-4/5 mt-8 flex flex-col items-center">
        <p>Digite o nome do novo bot. Este nome será visualizado pelos usuários durante o chat. Por exemplo, "tester".
          Agora,
          escolha um nome de usuário para o seu bot.</p>
        <div class="w-[32rem] h-[4rem] mt-8" id="botfather-img-2"></div>
      </div>
    </div>
    <div class="mt-12">
      <form action="" class="flex flex-col items-center">
        <div class="flex flex-col">
  
          <label for="api-key" class="font-bold text-sm text-black">Chave API:</label>
          <input [(ngModel)]="apiKey" type="text" name="key" id="api-key" class="w-80 h-8 mt-4 rounded-md border border-[#38D] shadow-ligth-blue">
        </div>
        <p class="bg-red-400 rounded-lg m-auto mt-2 text-white p-2" *ngIf="error">{{error}}</p>
        <button type="button" (click)="addApiKey()"
          class="text-white w-40 h-8 mt-5 font-bold border border-solid border-submit-blue-default rounded-md bg-gradient-to-r from-submit-blue-default via-blue-transparent to-submit-blue-default">Autenticar</button>
      </form>
    </div>
  
  </div>
  <div class="flex flex-col justify-center items-center text-lg" *ngIf="telStatus=='connected'">
    <div class="shadow-xl mb-10 pb-2">
      <h2 class="text-center text-2xl font-bold mb-2 text-[#1885cd]">Telegram conectado</h2>
      <div class="w-screen flex justify-center items-center ">
          <img [src]="integrationInfo?.adInfo?.profile || '/assets/imagens/icons/agent.jpg'" alt="Profile Picture" class="w-40 h-40 rounded-full object-cover mr-5">
          <div>
              <h2 class="text-2xl font-semibold  text-[#1885cd]">{{integrationInfo?.adInfo?.name}}</h2>
             
             <div class="flex">
              <b>chave da api: </b>
              <input class="text-gray-600 border-none text-xl w-[300px]" [value]="integrationInfo?.apiKey" [type]="ocult?'password':'text'" readonly>
            <button (click)="toogleOcult()" class="w-20 bg-blue-600 text-white text-xl rounded-lg">{{ocult?'ver':'ocultar'}}</button>
             </div> 
               <p class="text-xl text-green-500">conectado</p>
          </div>
          
      </div>
      <div class="flex w-full justify-center my-2">
          <button (click)="disconnect()" class="w-[120px] bg-red-400 m-auto transition-all duration-300 p-2 text-white rounded-xl hover:bg-red-700">desconectar</button>
  
      </div>
  </div>
  </div>

</div>
<div *ngIf="load">
  <img class="mt-4 w-24" src="https://cdn.pixabay.com/animation/2023/03/20/02/45/02-45-27-186_512.gif" alt="">
  </div>