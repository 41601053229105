import { Component, OnInit } from '@angular/core';
import { Status } from '../../../../../../core/enums/agent-whatsapp-status.enum';
import { AgentsService } from '../../../../../../core/services/agents/agents.service';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Telegram } from '../../../../../../core/types/agents.interface';

@Component({
  selector: 'app-telegram',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './telegram.component.html',
  styleUrl: './telegram.component.css',
})
export class TelegramComponent implements OnInit {
  toogleOcult() {
    this.ocult = !this.ocult;
  }
  protected apiKey = '';
  protected error?: string;
  ocult = true;
  constructor(private agents: AgentsService) {}
  protected telStatus: Status = 'disconnected';
  protected integrationInfo?: Telegram;
  protected load:boolean=true
  ngOnInit(): void {
    this.agents.actions
      .getDataForAgent()
      .pipe(map((res) => res.data))
      .subscribe((res) => {
        this.load=false
        this.integrationInfo = res.integration.telegram;
        this.telStatus = res.integration.telegram
          ? 'connected'
          : 'disconnected';
      });
  }

  addApiKey() {
    this.error = undefined;
    if (!this.apiKey) {
      return;
    }
    this.agents.actions.addAgent('TEL', this.apiKey).subscribe({
      next: (res) => {
        this.agents.connectIntegration('TEL').subscribe({
          next: () => {
            this.telStatus = 'connected';
          },
          error: (err) => {
            console.warn(err);
          },
        });
      },
      error: (err) => {
        console.warn(err);
        if (err.status == 400) {
          this.error = 'chave da api inválida.';
        }
      },
    });
  }
  disconnect() {
    this.agents.disconnectIntegration('TEL').subscribe({
      next: () => {
        this.telStatus = 'disconnected';
      },
      error: (err) => {
        console.warn(err);
      },
    });
  }
}
