import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, catchError, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { WhatsappStatus } from '../../../enums/agent-whatsapp-status.enum';
import { ApiKeyTelNotFoundException } from '../../../../shared/exceptions/agents/ApiKeyTelNotFoundException';
import { EventsService } from '../../../services/card-output/events.service';
import { UserService } from '../../../services/user/user.service';
import {
  AddAgentData,
  AddAgentResponse,
  AgentKey,
  AgentStatus,
  AgentType,
  CreateAgentData,
  CreateAgentResponse,
  CreateConnectionAgentResponse,
  CreateConnectionData,
  GetAgentResponse,
  GetAgentStatus,
} from '../../../types/agents.interface';
import { SocketService } from '../../../services/agents/socket/socket.service';

export class Connection {
  constructor(
    private http: HttpClient,
    private event: EventsService,
  ) {}

  private url = environment.socket.endpoints;

  private connectionStatus: WhatsappStatus = WhatsappStatus.DISCONNECTED;

  private _agentNotIsEmpty: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _integrationNotIsEmpty: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public getStatus(agent: AgentType): Observable<GetAgentStatus> {
    return this.http
      .get<GetAgentStatus>(this.url.connection.GET_STATUS_AGENT(agent))
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  protected createAgent(): Observable<CreateAgentData> {
    return this.http.post<CreateAgentResponse>(this.url.CREATE_AGENT, {}).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
      map((req) => req.data),
    );
  }

  protected _addAgent(
    key: AgentKey,
    apiKey?: string,
  ): Observable<AddAgentData> {
    if (key === 'TEL' && !apiKey)
      throw new ApiKeyTelNotFoundException(
        'Api key não informada ao criar agente',
        __filename,
      );

    return this.http
      .post<AddAgentResponse>(this.url.ADD_AGENT, {
        key: key,
        integrationInfo: { apiKey },
      })
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  protected createConnection(): Observable<CreateConnectionData> {
    return this.http
      .post<CreateConnectionAgentResponse>(
        this.url.connection.CONNECT_AGENT,
        {},
      )
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((req) => req.data),
      );
  }

  protected getAgent(): Observable<GetAgentResponse> {
    return this.http.get<GetAgentResponse>(this.url.BASE_URL).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        throw err;
      }),
    );
  }

  public createConnectionInWhatsapp(): Observable<CreateConnectionData> {
    return this.http
      .post<CreateConnectionAgentResponse>(this.url.connection.CONNECT_AGENT, {
        key: 'WA',
      })
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((req) => req.data),
      );
  }

  public get WaConnection(): WhatsappStatus {
    return this.connectionStatus;
  }

  public startWaConnection() {
    this.connectionStatus = WhatsappStatus.CONNECTING;
  }

  public get agentNotIsEmpty(): BehaviorSubject<boolean> {
    return this._agentNotIsEmpty;
  }

  public set agentNotIsEmpty(value: boolean) {
    this._agentNotIsEmpty.next(value);
  }

  public get integrationNotIsEmpty(): BehaviorSubject<boolean> {
    return this._integrationNotIsEmpty;
  }
  public set integrationNotIsEmpty(value: boolean) {
    this._integrationNotIsEmpty.next(value);
  }
}
