<section class="w-screen flex flex-col items-center justify-center mt-8">
  <div class="w-3/4 ml-52">
    <div class="w-full flex h-full">
      <div class="ml-8 h-full">
        <h1 class="text-2xl font-bold">Cadastrar mensagem</h1>
        <p>Crie mensagens personalizadas para mostrar aos seus clientes!</p>
      </div>

      <div class="ml-auto mr-3 flex flex-row items-center">
        <div class="flex flex-col font-bold text-black text-sm mb-6">
          <label for="message-name">Nome da mensagem</label>
          <input
            type="text"
            id="message-name"
            class="h-8 rounded-[0.3125rem] mt-2"
            #inputNameForMessage
          />
          <p class="text-red-700" *ngIf="alertInputNameIsEmpty">
            Nome da mensagem não pode ficar em branco
          </p>
        </div>

        <div class="flex flex-row justify-center ml-8 3xl:ml-32">
          <div class="mr-9">
            <app-mini-dropdown
              [items]="items"
              align="-ml-[5.5rem] 3xl:-ml-[5.7rem]"
            ></app-mini-dropdown>
          </div>

          <button
            type="button"
            (click)="createMessage()"
            class="bg-[#ff8a12] hover:bg-[#ec7211] dark:font-light font-bold text-black w-44 h-8 text-center rounded"
          >
            Criar mensagem
          </button>
        </div>
      </div>
    </div>
    <hr class="-ml-52 w-screen" />

    <div class="flex justify-center items-center mt-16 mb-5">
      <div class="flex flex-col mr-3">
        <p class="text-sm font-bold mb-1">Visualização:</p>
        <img
          class="rounded-xl w-[350px] h-[350px] border-2"
          [src]="
            previewImageMessage ||
            'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
          "
          alt=""
        />
      </div>
      <div class="flex flex-col">
        <p class="text-sm font-bold mb-1">Conteúdo da mensagem:</p>
        <textarea
          placeholder="digite o texto de sua mensagem"
          class="overflow-y-auto resize-none h-[200px] rounded-lg"
          name="text-for-message"
          id="message-for-text"
          cols="100"
          rows="25"
          [(ngModel)]="messageInTextArea"
        ></textarea>
      </div>

      <input
        (change)="changeFileForImage($event)"
        type="file"
        name="image"
        id="image-for-message"
        class="hidden"
        #inputFile
      />
    </div>
  </div>
</section>
