<section class="w-screen border pb-4">
  <div class="md:flex md:flex-col justify-center w-screen">
    <div class="md:ml-64 mb-8 md:mb-0">
      <div class="mt-4 md:ml-40">
        <h1 class="text-2xl font-bold">Cadastrar usuário</h1>
        <p class="mt-1">Gerencie os acessos de seus usuários com maestria!</p>
      </div>
    </div>
    <hr />
    <div class="md:mt-8">
      <form (ngSubmit)="createUser()" *ngIf="form" [formGroup]="form">
        <div class="flex flex-col justify-center items-center mb-8">
          <div class="flex flex-col">
            <label class="mb-2 dark:text-gray-300 text-sm font-bold" for="name"
              >Nome:</label
            >
            <input
              autocomplete="off"
              class="dark:bg-gray-700 w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC] focus:shadow-ligth-blue"
              type="text"
              name="name"
              id="name"
              formControlName="name"
            />
            @if (form.get('name')?.invalid && form.get('name')?.touched){
            <p
              class="text-red-800"
              *ngIf="form.get('name')?.errors?.['minlength']"
            >
              Nome muito curto
            </p>

            <p
              *ngIf="form.get('name')?.errors?.['required']"
              class="text-red-800"
            >
              Nome não pode ficar em branco
            </p>
            }
          </div>
        </div>

        <div class="flex flex-col justify-center items-center mb-7">
          <div class="flex flex-col">
            <label class="mb-2 dark:text-gray-300 text-sm font-bold" for="email"
              >Email:</label
            >
            <input
              autocomplete="off"
              class="dark:bg-gray-700 w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC] focus:shadow-ligth-blue"
              type="text"
              name="email"
              id="email"
              formControlName="email"
            />
            @if (form.get('email')?.invalid && form.get('email')?.touched){
            <p class="text-red-800">Email não pode ficar em branco</p>
            }
          </div>
        </div>

        <div class="flex flex-row justify-center items-center mb-4">
          <div class="">
            <input
              autocomplete="off"
              class="mr-3"
              type="checkbox"
              name="generate-password"
              id="generate-password"
              (change)="changePassword()"
              formControlName="generate_password"
            />
            <label
              class="dark:text-gray-300 text-sm font-bold"
              for="generate-password"
              >Gerar senha segura automaticamente</label
            >
          </div>
        </div>

        <div class="flex flex-col justify-center items-center mb-10">
          <div class="flex flex-col">
            <label
              class="mb-2 dark:text-gray-300 text-sm font-bold"
              for="userpassword"
              >Senha:</label
            >
            <input
              autocomplete="off"
              class="dark:bg-gray-700 w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC] focus:shadow-ligth-blue"
              type="password"
              name="userpassword"
              id="userpassword"
              formControlName="password"
            />
            @if (form.get('password')?.invalid &&
            form.get('password')?.touched){
            <p class="text-red-800">
              Marque a opção acima <br />
              para gerar uma senha aleatória
            </p>
            }
          </div>
        </div>

        <div
          class="flex flex-col justify-center text-center items-center mb-10"
        >
          <div class="w-64">
            <app-select placeholder="Política">
              <ul class="w-full">
                @for (policy of polices; track $index) {
                <li
                  class="border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
                  (click)="setRole(policy.permissions, policy.name)"
                >
                  {{ policy.name }}
                </li>
                } @empty {

                <li
                  class="border-b cursor-pointer hover:bg-[#F2F3F3] dark:hover:bg-gray-600"
                >
                  Nenhum grupo de regras existente
                </li>

                }
              </ul>
            </app-select>
          </div>
        </div>

        <div class="flex flex-row justify-center items-center mb-14">
          <div class="w-72">
            <input
              autocomplete="off"
              class="mr-3"
              type="checkbox"
              name="notification-for-email"
              id="notification-for-email"
              formControlName="notify_by_email"
            />
            <label
              class="dark:text-gray-300 text-sm font-bold"
              for="notification-for-email"
              >Notificar por email</label
            >
          </div>
        </div>

        <div class="flex flex-col justify-center items-center">
          @if (spinner) {
          <app-spinner></app-spinner>
          } @else {
          <button
            type="submit"
            class="bg-[#ff8a12] hover:bg-[#ec7211] dark:font-light font-bold text-black w-44 h-8 text-center rounded"
          >
            Criar usuário
          </button>
          }
        </div>
      </form>
    </div>
  </div>
</section>
