import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { UseTokenService } from '../../../core/services/auth/use-token.service';

export const isAuthenticatedInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(UseTokenService).getToken();
  const router = inject(Router);

  if (!token && req.url !== environment.users.auth.USERS_URL_LOGIN) {
    router.navigate(['/auth']);
  }

  if (req.body instanceof FormData) {
    const mediaRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next(mediaRequest);
  }

  const authRequest = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return next(authRequest);
};
