<section
  class="w-screen flex flex-col lg:ml-40 justify-center items-center mt-8"
>
  <div
    class="shadow-md sm:rounded-lg dark:bg-gray-800 relative xl:max-w-full xl:w-max"
  >
    <div class="">
      <p class="md:mr-4 font-normal text-3xl ml-8">Mensagens existentes</p>
    </div>

    <div class="w-min">
      <caption
        class="pt-1 pb-1 mb-0 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-[#F2F3F3] dark:text-white dark:bg-gray-800 flex flex-row"
      >
        <div class="w-3/4">
          <h1 class="text-2xl sm:text-3xl">Mensagens</h1>
          <p class="mt-1 text-sm font-normal text-[#16191F] dark:text-gray-400">
            Sinta-se livre! Gerencie suas mensagens, independentemente de modelo
            e tipo. Crie como quiser!
          </p>
        </div>
        <div
          class="lg:flex justify-end mr-2 3xl:ml-auto hidden relative mt-4 right-0 w-2/6"
        >
          <app-mini-dropdown
            [items]="actions"
            [align]="' lg:left-[-1.1rem]  3xl:left-[0.7rem]'"
          ></app-mini-dropdown>

          <button
            (click)="navigateToEditStoresOfMessages()"
            class="font-bold dark:font-light h-8 w-48 bg-[#ff8a12] text-center justify-center items-center inline-flex text-base hover:bg-[#ec7211] hover:text-black rounded-[2px] cursor-pointer"
          >
            Associar
          </button>
        </div>
      </caption>
      <table
        class="w-max text-sm text-left overflow-auto rtl:text-right text-[#16191F] dark:text-gray-400 xl:min-w-[50rem] 2xl-w-[65rem] 3xl:w-[70rem]"
      >
        <thead
          class="text-xs text-[#16191F] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-8 py-3">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox-all-search"
                  (change)="selectAllMessagesOfTable()"
                  #checkboxFather
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" class="px-8 py-3">
              <div class="flex justify-center">Nome</div>
            </th>
            <th scope="col" class="px-8 py-3">Associação</th>
            <th scope="col" class="px-8 py-3">Criado em</th>
            <th scope="col" class="px-8 py-3">Criado por</th>
          </tr>
        </thead>
        <tbody>
          @for(message of messages; track message.id) {
          <tr
            [ngClass]="{
              'bg-[#0073bb42] dark:bg-[#18345a] hover:bg-[#0073bb42] border-[#0073BB]':
                messagesIdsControllers.has(message.id),
              'hover:bg-[#cfdcdd] dark:bg-gray-800 dark:border-gray-700':
                !messagesIdsControllers.has(message.id)
            }"
            class="bg-white border-b border text-[#16191F] hover:transition-all hover:bg-[#cfdcdd]"
          >
            <td class="w-4 px-6">
              <div class="flex items-center justify-center">
                <input
                  id="checkbox"
                  (change)="selectMessage(message)"
                  #inputCheckbox
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox" class="sr-only">checkbox</label>
              </div>
            </td>
            <td
              scope="row"
              class="relative space-x-12 items-center group px-8 py-0.5 dark:text-white"
            >
              <input
                class="h-5 border-none bg-transparent"
                type="text"
                #messageInput
                [ngClass]="message.id === messageEdit ? 'block' : 'hidden'"
                [(ngModel)]="messageName"
                (blur)="sendNewNameOfMessage(messageInput.value, message)"
                (keyup.enter)="
                  sendNewNameOfMessage(messageInput.value, message)
                "
              />

              <p *ngIf="message.id !== messageEdit">{{ message.name || "" }}</p>

              <div
                *ngIf="message.id !== messageEdit"
                (click)="displayInputOfMessageName(message)"
              >
                <app-edit-icon
                  class="absolute right-0 bottom-0 hidden group-hover:block hover:border-gray-400 hover:border z-50 mr-0 p-0"
                ></app-edit-icon>
              </div>

            </td>
            <td
              class="mx-1 px-6 py-0.5 flex flex-row gap-1 text-[#28BD09]"
            > {{ message.name}}</td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              {{ message.created_at || "Não informado" }}
            </td>
            <td class="mx-1 px-6 py-0.5 dark:text-white hover:transition-all">
              <div class="flex flex-col items-center justify-center">
                {{ message.user_id || "Não informado" }}
              </div>
            </td>
          </tr>
          } @empty {
          <tr class="xl:w-14">
            <td
              colspan="5"
              class="px-6 py-4 text-center dark:text-white hover:transition-all"
            >
              Nenhum usuário existente
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <div
      class="dark:border-gray-600 z-50 fixed top-0 right-0 left-0"
      cdkDrag
      *ngIf="showModal"
    >
      <div class="mt-3">
        <!-- <app-modal-message [model]="model" (close)="closeModal()" (modal)="closeModal()"></app-modal-message> -->
      </div>
    </div>
  </div>
</section>
