import { CommonModule } from '@angular/common';
import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { GroupsService } from '../../../../../core/services/groups/groups.service';
import { PermissionsService } from '../../../../../core/services/permissions/permissions.service';
import { PostGroup } from '../../../../../core/types/groups.interface';
import { Data } from '../../../../../core/types/permissions.interface';

@Component({
  selector: 'app-new-rule',
  standalone: true,
  imports: [CommonModule, RouterLink, FormsModule],
  templateUrl: './new-rule.component.html',
  styleUrl: './new-rule.component.css',
})
export class NewRuleComponent {
  constructor(
    private dataService: DataService,
    private permission: PermissionsService,
    private group: GroupsService,
    private event: EventsService,
  ) {}

  @ViewChildren('checkbox') private checkbox!: QueryList<
    ElementRef<HTMLInputElement>
  >;

  public rulesIdsControllers = new Set<number>();

  public rules!: Data[];

  private rulesSubscription!: Subscription;

  public ruleName = '';

  public nameIsEmpty!: boolean;

  ngOnInit() {
    this.dataService.changeData(
      'Regras',
      'Cadastre regras e comande seus usuários facilmente.',
      true,
    );
    this.getRules();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public unsubscribe() {
    this.rulesSubscription.unsubscribe();
  }

  private getRules() {
    this.rulesSubscription = this.permission
      .getPermissions()
      .subscribe((permissions) => {
        this.rules = permissions;
      });
  }

  public createNewRuleGroup() {
    const rule: PostGroup = {
      name: this.ruleName,
      permissions: Array.from(this.rulesIdsControllers),
    };

    this.group.createRulesGroup(rule).subscribe((rule) => {
      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Grupo de regras criado com sucesso!',
        event: 'success',
      });
    });
  }

  public setNameIsEmpty() {
    this.nameIsEmpty = true;
  }

  public disableNameIsEmpty() {
    this.nameIsEmpty = false;
  }

  public selectRule(id: number) {
    if (this.rulesIdsControllers.has(id)) {
      this.rulesIdsControllers.delete(id);
      return;
    }

    this.rulesIdsControllers.add(id);
  }
}
