<article class="w-screen h-screen flex flex-col items-center justify-center">
  <div class="w-full max-w-sm">
    <div class="flex flex-col mb-6">
      <div class="bg-logo w-32 h-32 mb-3" id="logo"></div>
      <div class="flex flex-row">
        <h1
          class="ml-4 text-lg font-bold text-black dark:text-gray-300 text-center mb-4"
        >
          Nova Senha
        </h1>
      </div>
    </div>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="mb-4 flex flex-col">
        <label class="text-sm font-bold mb-2 dark:text-gray-300" for="password">
          Senha:
        </label>
        <input
          class="w-72 h-8 rounded-md dark:gray-300 border border-[#CCCCCC] focus:shadow-ligth-blue"
          formControlName="password"
          id="password"
          type="password"
        />
        <div
          *ngIf="
            resetPasswordForm.get('password')?.invalid &&
            resetPasswordForm.get('password')?.touched
          "
          class="text-red-500 text-xs mt-1"
        >
          <div
            class="text-[#f00] text-xs font-semibold tracking-widest"
            *ngIf="resetPasswordForm.get('password')?.errors?.['required']"
          >
            Senha não pode ficar em branco
          </div>
          <div
            class="text-[#f00] text-xs font-semibold tracking-widest"
            *ngIf="resetPasswordForm.get('password')?.errors?.['minlength']"
          >
            Senha muita curta
          </div>
          <div
            class="text-[#f00] text-xs font-semibold tracking-widest"
            *ngIf="resetPasswordForm.get('password')?.errors?.['pattern']"
          >
            Senha deve conter pelo menos uma letra maiúscula, uma letra
            minúscula, um número e um caractere especial
          </div>
        </div>
      </div>

      <div class="mb-6 flex flex-col">
        <label
          class="text-sm font-bold mb-2 dark:text-gray-300"
          for="confirmPassword"
        >
          Confirmar Senha:
        </label>
        <input
          class="w-72 h-8 rounded-md dark:text-gray-300 border border-[#CCCCCC] focus:shadow-ligth-blue"
          formControlName="confirmPassword"
          id="confirmPassword"
          type="password"
        />
        <div
          *ngIf="
            resetPasswordForm.get('confirmPassword')?.invalid &&
            resetPasswordForm.get('confirmPassword')?.touched
          "
          class="text-red-500 text-xs mt-1"
        >
          <div
            class="text-[#f00] text-xs font-semibold tracking-widest"
            *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']"
          >
            Senha não pode ficar em branco
          </div>
          <div
            class="text-[#f00] text-xs font-semibold tracking-widest"
            *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['mustMatch']"
          >
            Senhas não confere
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        @if (spinner) {
        <app-spinner></app-spinner>

        } @else {

        <button
          class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] w-72 h-8 text-white font-bold rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Salvar Senha
        </button>

        }
      </div>
    </form>
  </div>
  <footer class="mt-12">
    <hr class="w-[50rem]" />
    <div class="text-center text-black text-sm dark:text-gray-300">
      <p>Ofertas Automatizadas © 2023 - Todos os direitos reservados</p>
    </div>
  </footer>
</article>
