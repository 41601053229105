import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../../../core/services/card-output/data-service.service';

@Component({
  selector: 'app-agents',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule],
  templateUrl: './agents.component.html',
  styleUrl: './agents.component.css',
})
export class AgentsComponent implements OnInit {
  constructor(
    private data: DataService,
    private router: Router,
  ) {}

  private subscription$!: Subscription;

  public urlAgents = `${environment.core.url}/home/agentes`;

  public url = window.location.href;

  ngOnInit(): void {
    this.data.changeData(
      'Agentes',
      'Autentique seus agentes para usar todo o poder do Easy Ofertas',
      true,
    );

    this.subscription$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.url = window.location.href;
      });
  }
}
