<div class="mt-3 mb-6 border border-[#ccc] rounded-sm">
          <p class="text-sm font-bold mt-1 ml-1">Agendamento:</p>
          @if (schedulingNotImplemented) {
          <div class="mt-6 ml-8 mb-4 flex items-center justify-center">
            <app-banner></app-banner>
          </div>

          } @else {
          <div class="mt-6 ml-8">
            <div class="flex items-center justify-center w-min">
              <input
                type="checkbox"
                name=""
                id="recorrence"
                formControlName="recurrence"
                [disabled]="true"
                (change)="markRecurrence(recurrenceCheck.checked)"
                #recurrenceCheck
              />
              <label
                for="recorrence"
                class="ml-3"
                data-tooltip-target="tooltip-recurrence"
                >Recorrência</label
              >
              <div
                id="tooltip-recurrence"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Em desenvolvimento
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>

              <div class="flex flex-col ml-14 mb-6">
                <label for="" class="text-sm font-bold">Hora:</label>
                <input
                  type="time"
                  name="time-for-shoot"
                  class="h-9 disabled:border-gray-400 disabled:text-gray-400"
                  (change)="pickTime($event)"
                  id="time"
                />
              </div>
            </div>

            <div>
              <ul class="flex gap-2 3xl:gap-5" formArrayName="days">
                @for (day of weekDays; track $index) {

                <li class="flex items-center justify-center">
                  <input
                    type="checkbox"
                    name=""
                    [id]="weekDays[$index]"
                    (change)="setIfAnyValueInDayIsChecked()"
                    class="disabled:text-gray-400"
                    [disabled]="!isRecurrence"
                    #weekDaysCheck
                  />

                  <label
                    [for]="weekDays[$index]"
                    class="ml-2 2xl:ml-3"
                    [ngClass]="{
                      'text-gray-400': weekDaysCheck.disabled
                    }"
                    >{{ weekDays[$index] }}</label
                  >
                </li>

                }
                <li class="flex items-center justify-center">
                  <input
                    type="checkbox"
                    class="disabled:text-gray-400"
                    id="date-specific"
                    (change)="
                      markDateSpecificAtValue(checkboxDateSpecific.checked)
                    "
                    [disabled]="isRecurrence"
                    #checkboxDateSpecific
                  />
                  <label
                    for="date-specific"
                    class="ml-2 2xl:ml-3"
                    [ngClass]="{
                      'text-gray-400': checkboxDateSpecific.disabled
                    }"
                    >Data específica</label
                  >
                </li>
              </ul>
            </div>

            <div class="flex flex-col items-end mr-24">
              <div class="flex flex-col">
                <label
                  for=""
                  class="text-sm font-bold"
                  [ngClass]="{
                    'text-gray-400': !isSpecificDay
                  }"
                  >Data:</label
                >
                <input
                  type="date"
                  name=""
                  class="h-9 disabled:border-gray-400 disabled:text-gray-400"
                  id=""
                  [min]="date"
                  [disabled]="!isSpecificDay"
                  (change)="pickDate($event)"
                  #inputSpecificDay
                />
              </div>
            </div>

            <div class="mb-5">
              <div class="flex items-center">
                <input
                  type="checkbox"
                  disabled
                  name=""
                  id=""
                  class="disabled:border-gray-400 disabled:text-gray-400"
                />
                <label for="" class="ml-3 border-gray-400 text-gray-400"
                  >Salvar como rascunho</label
                >
              </div>
              <div class="flex items-center mt-3">
                <input
                  type="checkbox"
                  name=""
                  id="fired-immediate"
                  (change)="setFiredImmediate(firedImmediate.checked)"
                  [disabled]="!firedImmediateIsEnabled"
                  #firedImmediate
                />
                <label
                  for="fired-immediate"
                  class="ml-3"
                  [ngClass]="{
                    'text-gray-400': firedImmediate.disabled
                  }"
                  >Disparar imediatamente após a confirmação</label
                >
              </div>
            </div>
          </div>
          }
        </div>