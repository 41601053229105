import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AgentsService } from '../../../../../../../../core/services/agents/agents.service';
import { Whatsapp } from '../../../../../../../../core/types/agents.interface';

@Component({
  selector: 'app-started',
  standalone: true,
  imports: [],
  templateUrl: './started.component.html',
  styleUrl: './started.component.css',
})
export class StartedComponent implements OnInit {
  protected error?: string;
  @Output() connectionEnd = new EventEmitter<boolean>();
  protected integrationInfo?: Whatsapp;
  constructor(private agents: AgentsService) {}
  ngOnInit(): void {
    this.agents.getAgent().subscribe({
      next: (res) => {
        this.integrationInfo = res.integration.whatsapp;
      },
    });
  }
  public disconnect = () => {
    this.agents.disconnectIntegration('WA').subscribe({
      next: () => {
        this.connectionEnd.emit(true);
      },
      error: (err) => {
        console.warn(err);
      },
    });
  };
}
