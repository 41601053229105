import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-banner',
  standalone: true,
  imports: [CommonModule],
  template: `

    <div class="banner flex flex-row items-center px-6 bg-white border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col justify-center w-full container-test">
            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-[#FF8A00]">Em desenvolvimento</h5>
            <p>Essa funcionalidade esta no forno, aguarde por futuras atualizações.</p>
        </div>
        <div class="w-full flex items-center justify-end">
            <img src="/assets/img_banner.png" class="w-[18rem] h-[18rem]">
        </div>
        <div class="overlay"></div>
    </div>

  `,
  styles: `
    .banner {
        width: 65rem;
        height: 22rem;
        border-radius: 0.9375rem;
        background-image: url('/assets/img_banner.png');
        background-size: cover;
        position: relative;
        background: white;

        border: 4px solid transparent;
        border-image: linear-gradient(180deg, #FF8A00, #FEC400 50%, #FECB00 96%, #FDF300) 1;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    }
    .overlay {
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        pointer-events: none;
        mix-blend-mode: multiply;
        border-radius: 0.9375rem;

    }

  `,
})
export class BannerComponent {}

/*

<!-- <div role="status" class="space-y-3 animate-pulse max-w-xl">
          <div class="flex items-center w-full">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>

          <div class="flex items-center w-full">
            <h1 class="text-xl font-semibold" >Funcionalidade em desenvolvimento, estará disponível em atualizações futuras.</h1>
          </div>

          <div class="flex items-center w-full max-w-[480px]">
              <div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full max-w-[440px]">
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full max-w-[360px]">
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div> -->






        styles: `
    .triangle {
    --r:20px;  border radius
    width: 180px;
    aspect-ratio: 1/cos(30deg);
    --_g:calc(tan(60deg)*var(--r)) bottom var(--r),#000 98%,#0000 101%;
    -webkit-mask:
        conic-gradient(from -30deg at 50% calc(200% - 3*var(--r)/2),#000 60deg,#0000 0)
        0 100%/100% calc(100% - 3*var(--r)/2) no-repeat,
        radial-gradient(var(--r) at 50% calc(2*var(--r)),#000 98%,#0000 101%),
        radial-gradient(var(--r) at left  var(--_g)),
        radial-gradient(var(--r) at right var(--_g));
    clip-path: polygon(50% 0,100% 100%,0 100%);
    background: linear-gradient(45deg,#FA6900,#C02942);
}

  `



*/
