import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-down-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none" class="w-2.5 h-2.5 ms-2"
>
  <path d="M1.61604 0.995178L12.8744 0.995178L7.2452 10.7452L1.61604 0.995178Z" fill="#545B64" stroke="#545B64"/>
</svg>`,
})
export class DownIconComponent {}
