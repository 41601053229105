<section class="lg:ml-64 mt-14 lg:w-[46rem] xl:w-[50rem]">
  <h1 class="text-2xl ml-8 md:text-3xl md:ml-16">Adicionar uma loja</h1>
  <div class="border dark:border-gray-700">
    <div class="md:flex md:flex-col">
      <div
        class="text-black bg-[#f2f3f3] flex flex-col border border-[#ECEEEE] dark:border-gray-700 dark:bg-gray-800 dark:text-white"
      >
        <div class="mt-4 ml-6 md:ml-12">
          <h1 class="text-2xl md:text-3xl font-bold">Nome e Tags</h1>
        </div>
        <p class="ml-6 mr-3 md:ml-12">
          Identifique a loja com um nome personalizado. Use tags se desejar.
        </p>
      </div>
    </div>
    <form
      (ngSubmit)="addStore()"
      [formGroup]="storeFormGroup"
      *ngIf="storeFormGroup"
      class="mt-7 md:mt-12"
    >
      <div class="flex flex-row">
        <label for="input-loja" class="ml-6 md:ml-12 mr-5 text-lg font-bold"
          >Nome</label
        >
        <input
          #loja
          formControlName="loja"
          type="text"
          name="loja"
          id="input-loja"
          class="w-17 md:w-[22rem] lg:w-[34rem] border border-[#687078] dark:bg-gray-500 h-8"
        />
      </div>

      <div
        *ngIf="
          storeFormGroup.get('loja')?.invalid &&
          storeFormGroup.get('loja')?.touched
        "
      >
        <p
          *ngIf="storeFormGroup.get('loja')?.errors?.['required']"
          class="text-red-600 my-1 ml-6 md:ml-12"
        >
          Nome da loja não pode ficar em branco
        </p>
        <p
          *ngIf="storeFormGroup.get('loja')?.errors?.['minlength']"
          class="text-red-600 my-1 ml-6 md:ml-12"
        >
          Nome da loja deve ter no mínimo 5 letras
        </p>
      </div>

      <div class="mt-7 lg:mt-12">
        <label for="input-tags" class="ml-12 mr-5 text-xl text-[#0073C1]"
          >Adicionar tags</label
        >
        <div
          [formGroup]="tagsFormGroup"
          *ngIf="tagsFormGroup"
          class="mx-5 lg:ml-11 lg:w-[38rem] min-h-16 lg:max-h-36 border border-gray-500 flex content-start flex-wrap overflow-y-auto dark:bg-gray-800 dark:border-gray-700 dark:text-white"
        >
          <input
            #tags
            (keyup.enter)="addTagItem(); $event.preventDefault()"
            (keydown.enter)="$event.preventDefault()"
            [placeholder]="
              getTags().length === 5
                ? 'Limite de tags atingido'
                : 'Adicione uma Tag'
            "
            formControlName="tags"
            type="text"
            name="tags"
            id="input-tags"
            class="max-w-32 w-32 lg:w-40 h-8 text-center rounded-xl my-1 mx-2 border border-[#687078] dark:bg-gray-500 placeholder:text-xs"
          />
          <span
            class="w-32 text-center my-1 h-8 lg:p-1 lg:m-1 border border-[#0073c1] text-[#0073c1] bg-white cursor-pointer rounded-3xl px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            *ngFor="let item of getTags(); let i = index"
            (click)="removeTag(i)"
          >
            {{ item }}
          </span>
        </div>
      </div>
      <div class="flex justify-end lg:mr-4 mt-11 mb-3">
        @if(spinner){
        <div class="flex justify-center w-full">
          <app-spinner></app-spinner>
        </div>
        } @else {
        <button
          type="submit"
          class="bg-gradient-to-t from-[#146ac1] via-[#146ac1e6] to-[#146ac1] border border-[#0b4075] text-white rounded-lg p-2"
        >
          Cadastrar
        </button>
        }
      </div>
    </form>
  </div>
</section>

<!-- <section
  class="flex flex-col border border-white items-center lg:block lg:ml-60 w-[50rem] mt-[-20rem] lg:mt-0 dark:bg-gray-800  dark:text-white"
>
  <h2 class="text-2xl ml-0 font-normal lg:mx-4 lg:text-3xl">
    Adicionar uma Loja
  </h2>
  <div class="dark:bg-gray-800 border border-red-600">
    <div
      class="border border-[#ECEEEE] bg-[#F2F3F3] dark:bg-gray-800 dark:border-gray-700 dark:text-white"
    >
      <p
        class="pb-1 text-2xl font-semibold lg:text-3xl lg:ml-12 lg:pt-3 dark:bg-gray-800 dark:border-gray-700 dark:text-white text-[var(--name-shop-card-title)] lg:font-extrabold"
      >
        Nome e Tags
      </p>
      <p
        class="text-sm font-medium lg:ml-12 lg:mt-0"
        id="card-title-description"
      >
        Identifique a loja com um nome personalizado. Use tags se desejar.
      </p>
    </div>
    <div
      class="border border-[#ECEEEE] dark:bg-gray-800 dark:border-gray-700 dark:text-white"
    >
      <form (submit)="createNewStore($event, storeName.value)">
        <div class="mb-12">
          <label
            for="shop-name"
            class="font-bold lg:text-xl lg:ml-11 lg:mr-4"
            color="var(--name-shop-card-title)"
          >
            Nome
          </label>
          <input
            #storeName
            required
            type="text"
            class="border border-[#687078] bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:focus:border-gray-400 dark:placeholder:text-white lg:w-[38rem] h-7 lg:mt-12 leading-6 placeholder:text-center"
            id="shop-name"
            placeholder="Exemplo, Lojas Americanas."
          />
        </div>
        <div>
          <p class="lg:ml-11 text-[#0073c1] text-xl lg:mb-6">Adicionar tags</p>
        </div>
        <div
          class="lg:ml-11 lg:w-[43rem] lg:min-h-14 lg:max-h-32 border border-gray-500 flex content-start flex-wrap overflow-y-auto dark:bg-gray-800 dark:border-gray-700 dark:text-white"
        >
          <input
            [(ngModel)]="tagItem"
            [disabled]="enabledInput == 5"
            [placeholder]="
              enabledInput == 5 ? 'Limite de tags atingido' : 'Adicione uma Tag'
            "
            type="text"
            name="tagItem"
            class="text-center lg:p-1 lg:m-1 lg:w-28 border border-[#0073c1] text-[#0073c1] bg-white cursor-pointer rounded-3xl px-3 placeholder:text-xs focus:shadow-blue focus:border focus:border-[#0073c1] dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            id="tag-input"
            (keyup.enter)="addTagItem(); $event.preventDefault()"
            (keydown.enter)="$event.preventDefault()"
          />
          <span
            class="btn-tag text-center lg:p-1 lg:m-1 border border-[#0073c1] text-[#0073c1] bg-white cursor-pointer rounded-3xl px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            [class.active]="i === activeButton"
            *ngFor="let item of getTags(); let i = index"
            (click)="removeTag(i)"
          >
            {{ item }}
          </span>
        </div>
        <div class="lg:flex justify-end lg:mr-4 mt-11 mb-3">
          <app-spinner *ngIf="loading"></app-spinner>
          <button
            *ngIf="!loading"
            class="lg:w-24 h-8 border boder-[#0b4075] rounded-lg text-white active:bg-white cursor-pointer"
            style="
              background: linear-gradient(
                180deg,
                #146ac1 0%,
                rgba(20, 106, 193, 0.9) 50.52%,
                #146ac1 100%
              );
            "
            type="submit"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
</section> -->
