import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-false',
  standalone: true,
  imports: [CommonModule],
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
  <circle cx="12.5" cy="12.5" r="11" fill="white" stroke="#D13313" stroke-width="3"/>
    <line x1="7.70711" y1="7.5239" x2="17.6066" y2="17.4234" stroke="#D13313" stroke-width="2"/>
    <line x1="7.29289" y1="17.1924" x2="17.1924" y2="7.29289" stroke="#D13313" stroke-width="2"/>
  </svg>`,
})
export class VerifyFalseComponent {}
