import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { DownIconComponent } from '../../../../../shared/components/down-icon.component';
import { EditIconComponent } from '../../../../../shared/components/edit-icon.component';
import { MiniDropdownComponent } from '../../../../../shared/components/mini-dropdown.component';
import { dateTransform } from '../../../../../core/functions/dateTransform.function';
import { DataService } from '../../../../../core/services/card-output/data-service.service';
import { EventsService } from '../../../../../core/services/card-output/events.service';
import { ContactsService } from '../../../../../core/services/contacts/contacts.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { ContactResponse } from '../../../../../core/types/contacts.interface';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    CommonModule,
    EditIconComponent,
    RouterLink,
    DownIconComponent,
    MiniDropdownComponent,
    FormsModule,
  ],
  templateUrl: './contacts.component.html',
})
export class ContactsComponent {
  constructor(
    private contact: ContactsService,
    private dataService: DataService,
    private event: EventsService,
    private user: UserService,
    private router: Router,
  ) {}

  @ViewChild('contactInput', { static: false }) public reference!: ElementRef;
  @ViewChild('checkboxFather')
  public checkboxFather!: ElementRef<HTMLInputElement>;
  @ViewChildren('inputCheckbox') public inputCheckbox!: QueryList<ElementRef>;

  private subscription!: Subscription;

  public actions = [
    {
      name: 'Novo Contato',
      action: () => {
        this.router.navigate(['/home', 'new-contact']);
      },
      href: ['/home', 'new-contact'],
      color: '#000',
      darkColor: '#fff',
    },
    {
      name: 'Deletar',
      action: () => {
        this.disableContact();
      },
      href: ['/home', 'lojas-cadastradas'],
      color: '#D13313',
    },
    {
      name: 'Desabilitar',
      action: () => {
        this.deleteContact();
      },
      href: ['/home', 'lojas-cadastradas'],
      color: '#000',
      darkColor: '#fff',
    },
  ];

  public dropdownButtonAction = false;
  public dropdown = false;
  private statesOfContactRenamed = false;

  public contacts!: ContactResponse[];
  public currentPage = 1;

  public itemsPerPage = 0;
  public totalContacts = 0;

  public contactName!: string;
  public contactEdit = 0;

  public arrayOfPages!: number[];

  public controlItems = new Set<number>();
  public contactsIdControllers = new Set<number>();

  ngOnInit(): void {
    this.getContacts();

    this.dataService.changeData(
      'Lojas',
      'Descubra, cadastre e gerencie suas lojas nesta tela. Use esta tela para organizar suas lojas.',
      true,
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public getContacts() {
    this.subscription = this.contact.getContacts().subscribe((contact) => {
      this.contacts = contact.map((contact) => ({
        ...contact,
        created_at: dateTransform(contact.created_at),
      }));
    });
  }

  public toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  public toggleDrop() {
    this.dropdownButtonAction = !this.dropdownButtonAction;
  }

  public deleteContact(): void {
    if (this.controlItems.size === 0) return;
    const contacts = this.contacts.filter((contact) =>
      this.controlItems.has(contact.id),
    );
    const observables = contacts.map((contact) =>
      this.contact.deleteContact(contact.id),
    );

    forkJoin(observables).subscribe(() => {
      this.getContacts();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja(s) deletada(s) com sucesso!',
        event: 'success',
      });
    });
  }

  public disableContact() {
    // if (this.controlItems.size === 0) return;
    // const contacts = this.contacts.filter((contact) =>
    //   this.controlItems.has(contact.id),
    // );
    // const observables = contacts.map((contact) =>
    //   this.parseTypeDataToObservable({ ...contact, status: 0 }),
    // );
    // forkJoin(observables).subscribe(() => {
    //   this.getContacts();
    //   this.getTotalPages();
    //   this.event.emitEventForContactSuccess({
    //     visible: true,
    //     message: 'Loja(s) desativada(s) com sucesso!',
    //     event: 'success',
    //   });
    // });
  }

  public enableContact() {
    // if (this.controlItems.size === 0) return;
    // const contacts = this.contacts.filter((contact) =>
    //   this.controlItems.has(contact.id),
    // );
    // const observables = contacts.map((contact) =>
    //   this.parseTypeDataToObservable({ ...contact, status: 1 }),
    // );
    // forkJoin(observables).subscribe(() => {
    //   this.getContacts();
    //   this.getTotalPages();
    //   this.event.emitEventForContactSuccess({
    //     visible: true,
    //     message: 'Loja(s) ativada(s) com sucesso!',
    //     event: 'success',
    //   });
    // });
  }

  public displayInputOfContactName(contact: ContactResponse) {
    this.contactName = contact.name;
    this.contactEdit = contact.id;
    requestAnimationFrame(() => {
      this.reference.nativeElement.focus();
    });
  }

  public sendNewNameOfContact(name: string, contact: ContactResponse) {
    if (name === contact.name) {
      this.contactEdit = 0;
      return;
    }

    if (this.statesOfContactRenamed) return;

    this.statesOfContactRenamed = true;

    const contactRenamed = {
      ...contact,
      name,
    };

    this.contact.updateContact(contactRenamed).subscribe(() => {
      this.contactEdit = 0;
      this.statesOfContactRenamed = false;
      this.getContacts();

      this.event.emitEventForStoreSuccess({
        visible: true,
        message: 'Loja alterada com sucesso!',
        event: 'success',
      });
    });
  }

  public selectContact(id: number) {
    if (this.controlItems.has(id)) {
      this.controlItems.delete(id);
      this.contactsIdControllers.delete(id);
      if (this.controlItems.size !== this.contacts.length) {
        this.checkboxFather.nativeElement.checked = false;
      }
      return;
    }

    this.controlItems.add(id);
    this.contactsIdControllers.add(id);
    if (this.controlItems.size === this.contacts.length) {
      this.checkboxFather.nativeElement.checked = true;
    }
  }

  private markAllCheckboxHowChecked(): void {
    if (this.controlItems.size === this.contacts.length) {
      for (const input of this.inputCheckbox) {
        input.nativeElement.checked = true;
      }
      return;
    }

    for (const input of this.inputCheckbox) {
      input.nativeElement.checked = false;
    }
  }

  private clearControllers(): void {
    this.controlItems.clear();
    this.contactsIdControllers.clear();
    this.checkboxFather.nativeElement.checked = false;
  }

  public selectAllContactsOfTable() {
    if (this.controlItems.size === this.contacts.length) {
      this.clearControllers();
      this.markAllCheckboxHowChecked();
      return;
    }
    for (const contact of this.contacts) {
      if (this.controlItems.has(contact.id)) {
        continue;
      }
      this.selectContact(contact.id);
      this.markAllCheckboxHowChecked();
    }
  }
}
