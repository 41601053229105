<div
  class="w-screen pb-40 flex flex-col justify-center items-center text-lg"
>
  <p class="mt-6 text-lg">
    Escaneie o código QR para autenticar com o perfil de Whatsapp que deseja
    usar para seu bot.
  </p>
  <div class="flex flex-col mt-10">
   
  </div>
  <div class="flex flex-col items-center mt-4">
    @if(qrcode){

    <!-- <qrcode
      [qrdata]="qrcode"
      [width]="800"
      [errorCorrectionLevel]="'M'"
    ></qrcode> -->

    <img [src]="qrcode">
    } @else {
    <app-spinner></app-spinner>
    <p>carregando qrcode</p>
    }
  </div>
</div>
