import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appInputStyles]',
  standalone: true,
})
export class InputStylesDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {
    this.el.nativeElement.classList.value = `${this.el.nativeElement.classList.value} rounded-[0.3125rem] mt-2 h-8`
  }

}
