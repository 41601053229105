import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { TokenIsEmptyError } from '../../services/auth/error/TokenIsEmpty.error';
import { UseTokenService } from '../../services/auth/use-token.service';

export const tokenAuthenticationIsValidGuard: CanActivateFn = (
  route,
  state,
) => {
  const router = inject(Router);
  const tokenService = inject(UseTokenService);
  // url: https://dashboard.oloucoportech.com/auth/reset-password/:token
  const token: string = route.url[route.url.length - 1].path;
  if (!token) router.navigate(['/auth/login']);

  // return tokenService.verifyNewUserByToken(token).pipe(
  //   catchError((err) => {
  //     if (err instanceof TokenIsEmptyError) {
  //       router.navigate(['/auth/login']);
  //       return of(false);
  //     }
  //     return of(true);
  //   }),
  // );
  return true;
};
