@switch (type) { @case ('checkbox') {
<td class="w-4 px-6">
  <div class="flex items-center justify-center">
    <input
      (change)="checkboxClick()"
      type="checkbox"
      #inputCheckbox
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
    <label for="checkbox" class="sr-only">
      <ng-content></ng-content>
    </label>
  </div>
</td>

} @case ('empty') {
<tr class="xl:w-14">
  <td
    colspan="5"
    class="px-6 py-4 text-center dark:text-white hover:transition-all"
  >
    <ng-content></ng-content>
  </td>
</tr>
} @default {
<td
  [scope]="scope"
  class="flex group items-center justify-center w-52 py-0.5 dark:text-white"
>
  <ng-content></ng-content>
</td>
} }
