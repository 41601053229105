<div class="w-[100%]" *ngIf="!load">
  @switch (whatsappIsEnable) { @case ('disconnected') {

  <div>
    <app-disconnected (onqrcode)="toggleQrcode($event)"></app-disconnected>
  </div>

  } @case ("connecting") {

  <div>

    <app-starting [qrcode]="qrBase64"></app-starting>
  </div>

  } @case ("connected") {

  <div class=" block w-[100%] mt-2">
    <app-started (connectionEnd)="disconnect($event)"></app-started>
  </div>

  } }
</div>
<div *ngIf="load">
  <img class=" w-24 mt-4 rounded-full" src="https://i.imgur.com/VUjU03O.gif"
    alt="">
</div>