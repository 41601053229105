<section class="w-[calc(100vw)] ml-60 flex flex-col items-center overflow-y-scroll overflow-x-hidden">
  <nav
    class=" shadow-xl text-sm w-full font-medium flex justify-center text-center text-gray-500 border-b-2 border-[#0073C1] dark:text-gray-400 dark:border-gray-700 ">


    <ul class="flex justify-center items-center flex-grow">
      <li class="mx-[10%]">
        <a [routerLink]="['/home', 'agentes', 'whatsapp']" [routerLinkActive]="
            'text-[#0073C1] active dark:text-blue-500 dark:border-blue-500'
          " class="transition-all duration-300 hover:text-blue-600 text-xl inline-block p-4 dark:hover:text-gray-300">Whatsapp</a>
      </li>
      <li class="mx-[10%]">
        <a [routerLink]="['/home', 'agentes', 'telegram']" [routerLinkActive]="
            'text-[#0073C1] active dark:text-blue-500 dark:border-blue-500'
          " class="text-xl inline-block p-4 dark:hover:text-gray-300 transition-all duration-300 hover:text-blue-600">Telegram</a>
      </li>
      <li class="mx-[10%]">
        <a [routerLink]="['/home', 'agentes', 'instagram']" [routerLinkActive]="
            'text-[#0073C1] active dark:text-blue-500 dark:border-blue-500'
          " class="text-xl inline-block p-4 dark:hover:text-gray-300 transition-all duration-300 hover:text-blue-600">Instagram</a>
      </li>
    </ul>
  </nav>
  <div class="w-full flex text-center justify-center items-center" *ngIf="urlAgents === url">
    <p class="text-lg truncate w-2/3">Para utilizar um serviço de mensagem, autentique com ele usando os botões acima.
      Clique
      e siga
      as instruções.</p>
  </div>
  <div>
    <router-outlet></router-outlet>
  </div>
</section>
