import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { AuthComponent } from './pages/auth/login/auth.component';
import { HomeComponent } from './pages/home/home.component';
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [CommonModule, RouterOutlet, AuthComponent, HomeComponent],
})
export class AppComponent implements OnInit {
  title = 'Ofertas Automatizadas';
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
    localStorage.clear();
    localStorage.setItem('color-theme', 'light');
    // if (isPlatformBrowser(this.platformId)) {
    //   initFlowbite();
    //   const colorPreferenceTheme = window.matchMedia(
    //     '(prefers-color-scheme: dark)',
    //   );
    //   const colorTheme = localStorage.getItem('color-theme');
    //   switch (colorTheme) {
    //     case 'dark':
    //       document.documentElement.classList.add('dark');
    //       document.documentElement.classList.remove('light');
    //       localStorage.setItem('color-theme', 'dark');

    //       break;
    //     case 'light':
    //       document.documentElement.classList.remove('dark');
    //       document.documentElement.classList.add('light');
    //       localStorage.setItem('color-theme', 'light');

    //       break;
    //     default:
    //       if (colorPreferenceTheme.matches) {
    //         document.documentElement.classList.add('ligth');
    //         localStorage.setItem('color-theme', 'ligth');
    //       } else {
    //         document.documentElement.classList.remove('ligth');
    //         document.documentElement.classList.add('dark');
    //       }
    //   }

    //   colorPreferenceTheme.addEventListener('change', (event) => {
    //     if (event.matches) {
    //       document.documentElement.classList.add('dark');
    //     } else {
    //       document.documentElement.classList.remove('dark');
    //     }
    //   });
    // }
  }
}
