<div class="h-full px-3 pb-4 overflow-y-auto items-center">
  <ul class="space-y-2 font-medium flex flex-col h-full">
    <li>
      <a
        [routerLink]="['/home', 'lojas-cadastradas']"
        class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      >
        <ng-icon name="faSolidShop"></ng-icon>
        <span class="ms-3" [routerLinkActive]="'font-bold text-[#0073C1]'"
          >Lojas</span
        >
      </a>
    </li>
    <li>
      <a
        [routerLink]="['/home', 'messages']"
        class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      >
        <ng-icon name="faSolidEnvelope"></ng-icon>

        <span
          class="flex-1 ms-3 whitespace-nowrap"
          [routerLinkActive]="'font-bold text-[#0073C1]'"
          >Mensagens</span
        >
      </a>
    </li>
    <li>
      <a
        [routerLink]="['/home', 'message-triggering']"
        class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      >
        <ng-icon name="faSolidPaperPlane"></ng-icon>

        <span
          class="flex-1 ms-3 whitespace-nowrap"
          [routerLinkActive]="'font-bold text-[#0073C1]'"
          >Disparos</span
        >
      </a>
    </li>

    <li *ngIf="showHistory">
      <div>
        <button
          (click)="showAgentsHistory = !showAgentsHistory"
          class="cursor-pointer flex items-center p-2 ml-6 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <ng-icon name="faSolidListCheck"></ng-icon>
          <span
            class="flex-1 ms-3 whitespace-nowrap font-normal"
            [routerLinkActive]="'font-bold text-[#0073C1]'"
            >Histórico</span
          >
        </button>

        <div *ngIf="showAgentsHistory">
          <a
            (click)="openWhatsapp()"

            class="cursor-pointer flex items-center p-2 ml-12 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <ng-icon name="faBrandWhatsapp"></ng-icon>
            <span
              class="flex-1 ms-3 whitespace-nowrap font-normal"
              [routerLinkActive]="'font-bold text-[#0073C1]'"
              >Whatsapp</span
            >
          </a>
          <a
            [routerLink]="['/home', 'status-triggering']"
            [queryParams]="{ key: 'TEL', return: getReturn().path}"
            class="cursor-pointer flex items-center p-2 ml-12 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <ng-icon name="faBrandTelegram"></ng-icon>
            <span
              class="flex-1 ms-3 whitespace-nowrap font-normal"
              [routerLinkActive]="'font-bold text-[#0073C1]'"
              >Telegram</span
            >
          </a>
        </div>
      </div>
    </li>

    <li>
      <a
        [routerLink]="['/home', 'users']"
        class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      >
        <ng-icon name="faSolidUser"></ng-icon>
        <span
          class="flex-1 ms-3 whitespace-nowrap"
          [ngClass]="{
            'font-bold text-[#0073C1]': showRules
          }"
          [routerLinkActive]="''"
          >Usuários</span
        >
      </a>
    </li>
    <li *ngIf="showRules">
      <div>
        <a
          [routerLink]="['/home', 'rules']"
          class="cursor-pointer flex items-center p-2 ml-6 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <ng-icon name="faSolidBookOpenReader"></ng-icon>
          <span
            class="flex-1 ms-3 whitespace-nowrap font-normal"
            [routerLinkActive]="'font-bold text-[#0073C1]'"
            >Regras</span
          >
        </a>
      </div>
    </li>

    <!-- <li>
        <div>
          <a
            [routerLink]="['/home', 'contacts']"
            class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <ng-icon name="faSolidBookOpenReader"></ng-icon>
            <span
              class="flex-1 ms-3 whitespace-nowrap font-normal"
              [routerLinkActive]="'font-bold text-[#0073C1]'"
              >Contatos</span
            >
          </a>
        </div>
      </li> -->
    <div class="flex flex-col h-full justify-end">
      <li>
        <a
          [routerLink]="['/home', 'agentes']"
          class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <app-robot></app-robot>

          <span
            class="flex-1 ms-3 whitespace-nowrap"
            [routerLinkActive]="'font-bold text-[#0073C1]'"
            >Agentes</span
          >
        </a>
      </li>
      <li>
        <a
          href="https://docs.oloucoportech.com/ "
          class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <ng-icon name="faSolidBook"></ng-icon>

          <span
            class="flex-1 ms-3 whitespace-nowrap"
            [routerLinkActive]="'font-bold text-[#0073C1]'"
            >Documentação</span
          >
        </a>
      </li>
      <li>
        <a
          (click)="logout()"
          class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <ng-icon name="faSolidRightFromBracket" color="red"></ng-icon>

          <span class="flex-1 ms-3 whitespace-nowrap text-[#FE0808]">Sair</span>
        </a>
      </li>
    </div>
  </ul>
</div>
