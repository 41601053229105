<section class="w-screen flex flex-col items-center justify-center mt-4 mb-4">
  <form
    *ngIf="form"
    [formGroup]="form"
    (submit)="getContact()"
    class="w-3/4 ml-52"
  >
    <div class="w-full flex h-full">
      <div class="ml-8 h-full">
        <h1 class="text-2xl font-bold">Adicionar contato</h1>
        <p>Descubra contatos importante para os negócios.</p>
      </div>

      <div class="ml-auto mr-3 flex flex-row items-center">
        <div class="flex flex-col font-bold text-black text-sm mb-6">
          <label for="message-name">Nome do contato</label>
          <input
            type="text"
            id="message-name"
            class="h-8 rounded-[0.3125rem] mt-2"
            formControlName="contactName"
          />
          <div
            class="text-red-700"
            *ngIf="
              form.get('contactName')?.touched &&
              form.get('contactName')?.invalid
            "
          >
            <p *nfIf="form.get('contactName').errors?.['required']">
              Nome do contato não pode ficar em branco
            </p>
            <p *nfIf="form.get('contactName').errors?.['minlength']">
              Nome do contato muito curto
            </p>
            <p *nfIf="form.get('contactName').errors?.['maxlength']">
              Nome do contato muito longo
            </p>
          </div>
        </div>

        <div class="flex flex-row justify-center ml-8 3xl:ml-32">
          <button
            type="button"
            (click)="createContact()"
            class="bg-[#ff8a12] hover:bg-[#ec7211] dark:font-light font-bold text-black w-44 h-8 text-center rounded"
          >
            Salvar contato
          </button>
        </div>
      </div>
    </div>
    <hr class="-ml-52 w-screen" />

    <div class="flex flex-col items-center mt-16">
      <div class="flex justify-center w-full">
        <div class="flex flex-col justify-center mr-8">
          <label for="number-ddi" class="font-bold text-black text-sm">
            País:
          </label>
          <input
            type="number"
            min="0"
            name="ddi"
            class="w-24"
            placeholder="+55"
            id="number-ddi"
            appInputStyles
          />
          <div
            class="text-red-700"
            *ngIf="form.get('ddi')?.touched && form.get('ddi')?.invalid"
          >
            <p *nfIf="form.get('ddi').errors?.['required']">
              Código do país (DDI) não pode ficar em branco
            </p>
            <p *nfIf="form.get('ddi').errors?.['minlength']">
              Código do país (DDI) inválido
            </p>
            <p *nfIf="form.get('ddi').errors?.['maxlength']">
              Código do país (DDI) muito longo
            </p>
          </div>
        </div>

        <div class="flex flex-col justify-center mr-8">
          <label for="number-ddd" class="font-bold text-black text-sm">
            DDD:
          </label>
          <input
            type="number"
            min="0"
            name="ddd"
            class="w-24"
            placeholder="13"
            id="number-ddd"
            appInputStyles
          />
          <div
            class="text-red-700"
            *ngIf="form.get('ddd')?.touched && form.get('ddd')?.invalid"
          >
            <p *nfIf="form.get('ddd').errors?.['required']">
              Código de discagem direta à distância (DDD) não pode ficar em
              branco
            </p>
            <p *nfIf="form.get('ddd').errors?.['minlength']">
              Código de discagem direta à distância (DDD) inválido
            </p>
            <p *nfIf="form.get('ddd').errors?.['maxlength']">
              Código de discagem direta à distância (DDD) muito longo
            </p>
          </div>
        </div>

        <div class="flex flex-col justify-center mr-8">
          <label for="phone-number" class="font-bold text-black text-sm">
            Número de telefone:
          </label>
          <input
            type="number"
            min="0"
            name="phone"
            class="w-40"
            placeholder="9 12345678"
            id="phone-number"
            appInputStyles
          />
          <div
            class="text-red-700"
            *ngIf="form.get('phone')?.touched && form.get('phone')?.invalid"
          >
            <p *nfIf="form.get('phone').errors?.['required']">
              Número de telefone não pode ficar em branco
            </p>
            <p *nfIf="form.get('phone').errors?.['minlength']">
              Número de telefone inválido
            </p>
            <p *nfIf="form.get('phone').errors?.['maxlength']">
              Número de telefone muito longo
            </p>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center mt-14">
        <button
          type="submit"
          class="bg-[#ECEEEE] hover:bg-[#D2D2D2] border border-[#ccc] w-44 h-7"
        >
          Buscar
        </button>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center">
      <div class="flex flex-col justify-center">
        <label for="response-contact" class="font-bold text-black text-sm">
          Nome:
        </label>
        <input
          type="text"
          name="contact-name"
          id="response-contact"
          class="w-96"
          appInputStyles
          [value]="contact.name"
          readonly
          disable
        />
      </div>
      <div class="mt-8">
        <p class="font-bold text-black text-sm mb-3">Foto de perfil:</p>
        <div class="border border-[#ccc] w-96 h-60">
          <div *ngIf="contact.photo" class="flex items-center justify-center">
            <img
              [src]="contact.photo"
              class="w-full object-cover"
              alt="foto de perfil"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
