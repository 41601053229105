import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PostGroup } from '../../types/groups.interface';
import { Data, PermissionResponse } from '../../types/permissions.interface';
import { EventsService } from '../card-output/events.service';
import { GroupsService } from './../groups/groups.service';

@Injectable({
  providedIn: 'root',
})
export class RulesService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
    private groups: GroupsService,
  ) {}

  public getRules(): Observable<Data[]> {
    return this.http
      .get<PermissionResponse>(environment.permissions.BASE_URL)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((rule) => rule.data),
      );
  }

  public getGroups() {
    return this.groups.getRules();
  }

  public createGroup(group: PostGroup) {
    return this.groups.createRulesGroup(group);
  }

  public deleteGroup(id: number) {
    return this.groups.deleteGroups(id);
  }
}
